/**
 * @name 페이지네이션
 * @description
 */
import React from 'react'
import {CSS} from 'types'
import {Image, uuid} from 'lib'
import {COLOR} from 'styles'
import styled from 'styled-components'
import {VscChevronLeft, VscChevronRight} from 'assets/icon'

type Props = {
  info?: {
    page_num: number
    per: number
    total_count: number
    last_page_num: number
    sort_element: string
    sort_type: string
  } | null
  onChange?: (num: any) => void
  style?: CSS
}
export default function Pagination({style, info, onChange}: Props) {
  // if (!info) return null
  const page_num = 2
  const isJump = false
  const pagerCount = 4 // [1],[1,2],[1,2,3] 분기처리
  const pager = [page_num - 1, page_num, page_num + 1]
  //*--------------------------------------------------*
  // onClickHandler
  const onClickHandler = (num: number = 0, type?: string) => {
    let calc = num
    if (type === 'prev') calc = num - 1 <= 1 ? 1 : num - 1
    // if (type === 'next') calc = num >= info?.last_page_num ? info?.last_page_num : num + 1
    !!onChange && onChange(calc)
  }
  //*--------------------------------------------------*
  return (
    <Content style={style}>
      {/* 페이지네이션 */}
      <div className="wrap">
        {/* 이전버튼 */}
        <button className="arrow prev" onClick={() => onClickHandler(info?.page_num, 'prev')}>
          <VscChevronLeft />
          <label className="body4">이전</label>
        </button>
        {isJump && (
          <React.Fragment>
            <button
              className={`first pager`}
              onClick={() => {
                onClickHandler(1)
              }}>
              1
            </button>
            {/* <Image src={icon_more} style={{width: 19, height: 40}} /> */}
          </React.Fragment>
        )}
        {pager.map((item: any, index: number) => {
          if (item === 0) return <span className="pager" key={uuid()} />
          // if (item > info?.last_page_num) return <span className="pager" key={uuid()} />
          return (
            <button key={uuid()} onClick={() => onClickHandler(item)} className={`${Number(info?.page_num) === Number(item) ? 'active pager' : 'pager'}`}>
              {item}
            </button>
          )
        })}

        {isJump && (
          <React.Fragment>
            {/* <Image src={icon_more} style={{width: 19, height: 40}} /> */}
            <button className={`last pager`}>{info?.last_page_num}</button>
          </React.Fragment>
        )}
        {/* 다음버튼 */}
        <button className="arrow next">
          <label className="body4">다음</label>
          <VscChevronRight />
        </button>
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* list */
  .list {
    display: flex;
    flex-direction: 'row';
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
  }
  /* pager */
  button.pager,
  span.pager {
    width: 44px;
    height: 40px;
    border-radius: 8px;
    color: ${COLOR.GRAY1};
    &.active {
      color: ${COLOR.WHITE};
      background-color: ${COLOR.BLUE3};
    }
  }
  /* button */
  button.arrow {
    display: flex;
    width: 78px;
    height: 40px;
    justify-content: center;
    align-items: center;
    &:hover {
      color: ${COLOR.BLUE2};
    }
    &.prev {
      margin-right: 50px;
    }
    &.next {
      margin-left: 50px;
    }
  }
`

/********************************************************
[사용법]
  <Pagination
    info={admins?.meta?.paging}
    onChange={(num: number) => {
      alert(num)
    }}
  />
*********************************************************/
