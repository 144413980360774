/**
 * @name Pagination
 * @description
 */
import {arrow_next, arrow_prev} from 'assets/svg'
import {Icon, Loop, Text} from 'lib'
import {useState} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {CSS} from 'types'

type Props = {
  info?: any[]
  style?: CSS
}
export default function Pagination({style}: Props) {
  // const
  const _style = Object.assign({}, style)
  const [select, setSelect] = useState(1)
  //*--------------------------------------------------*
  return (
    <Element style={_style}>
      {/* 이전 */}
      <button className="prev control">
        <Icon src={arrow_prev} />
        <Text mode="Btn_M" label="이전" color={COLOR.gray30} />
      </button>
      {/* 페이지 */}
      <Loop
        info={[1, 2, 3, 4, 5]}
        renderItem={({item, index}) => {
          return (
            <button
              className={`number ${select === index ? 'on' : ''}`}
              onClick={() => {
                setSelect(index)
              }}>
              <Text mode="Btn_M" label={item} color={COLOR.gray30} />
            </button>
          )
        }}
      />
      {/* 다음 */}
      <button className="next control">
        <Text mode="Btn_M" label="다음" color={COLOR.gray30} />
        <Icon src={arrow_next} />
      </button>
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* 공통 */
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* 이전,다음 */
  button.control {
    width: 77px;
  }
  /* 숫자 */
  button.number {
    width: 40px;
    height: 40px;
    margin: 0 4px;
    border-radius: 8px;
    color: ${COLOR.gray30};
    &:hover,
    &.on {
      background-color: ${COLOR.blue30};
      span {
        color: ${COLOR.white};
      }
    }
  }
`
/********************************************************
[사용법]

*********************************************************/
