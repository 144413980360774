/**
 * @name 헤더
 * @description
 */
import {refresh_btn} from 'assets/images'
import {IconTextButton, Text} from 'lib'
import {useEffect} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <Text mode="Headline_MB" color={COLOR.gray30}>
        태양광 발전 정보
      </Text>
      {/* 유틸메뉴 */}
      <IconTextButton
        onClick={() => {
          alert('test')
        }}
        src={refresh_btn}
        label="알람 설정"
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  padding: 0 24px;
  border-bottom: 1px solid ${COLOR.gray90};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
