/**
 * @name Test
 * @description
 */
import styled from 'styled-components'
import {App} from 'template'
import {Panel} from 'components'
import {Header, Footer, Code1, Code2} from './contents'

export default function Index() {
  //*--------------------------------------------------*
  return (
    <App>
      <Panel header={<Header />} footer={<Footer />}>
        <Code1 />
        <Code2 />
      </Panel>
    </App>
  )
}

// https://codesandbox.io/s/react-lottie-play-on-hover-tutorial-il8nv?file=/src/App.js
