/**
 * @name 위젯
 * @description
 */
import {Flex, Text} from 'lib'
import {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import * as echarts from 'echarts'
import {TitleValueUnit} from 'pages/dashboard/common'
import {useEventListener} from 'usehooks-ts'

// contents
type Props = {
  info?: any
}
export default function Contents({info}: Props) {
  // hooks
  const [chartElement, setChartElement] = useState<echarts.ECharts>()
  const chartRef = useRef(null)
  const option = {
    tooltip: {
      trigger: 'axis',
    },
    // 색상추가
    color: [COLOR.blue30, COLOR.green30],
    legend: {
      bottom: 0,
      data: ['실시간', '예측'],
    },
    grid: {
      top: '10%',
      left: '7%',
      right: '0%',
      bottom: '20%',
      containLabel: true,
    },
  }
  const [options, setOptions] = useState(Object.assign({}, option, {})) as any
  const handleSize = () => {
    !!chartElement && chartElement?.resize()
  }
  useEventListener('resize', handleSize)
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current)
      setChartElement(chart)
      chart.setOption(options)
      const handleClick = (params: any) => {}
      chart.on('click', handleClick)
    }
    return () => {}
  }, [options, chartRef])
  useEffect(() => {
    setOptions(
      Object.assign({}, options, {
        xAxis: {
          type: 'category',
          name: '',
          boundaryGap: true,
          data: info?.chart?.x,
        },
        yAxis: {
          type: 'value',
          name: 'k',
          nameLocation: 'end',
          nameTextStyle: {
            color: '#373737',
            padding: [0, 40, 10, 0],
          },
          axisLine: {
            lineStyle: {
              color: '#373737',
            },
          },
        },
        series: info?.chart?.y,
      }),
    )
    return () => {}
  }, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <div
        ref={chartRef}
        style={{
          width: '96%',
          height: '165px',
          marginTop: 20,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <Flex style={{marginBottom: 15}} alignitems="center" justifycontent="center">
        {/* 적용전 */}
        <Flex alignitems="flex-start" flexdirection="column" style={{marginRight: 24}}>
          <TitleValueUnit title={info?.currentOutput?.name} value={info?.currentOutput?.data} unit={info?.currentOutput?.unit} style={{width: 150, textAlign: 'right'}} />
        </Flex>
        {/* 적용후 */}
        <Flex alignitems="flex-start" flexdirection="column">
          <TitleValueUnit title={info?.dailyPowerGeneration?.name} value={info?.dailyPowerGeneration?.data} unit={info?.dailyPowerGeneration?.unit} style={{width: 150, textAlign: 'right'}} />
        </Flex>
      </Flex>
      {/* <Text label="기준 데이터 : ACELO AI 적용" mode="Caption_LR" color={COLOR.gray60} style={{marginLeft: 24}} /> */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /*--------------*/
`
