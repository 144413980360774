/**
 * @name Object
 * @description object 관련유틸리티
 */

export const isJson = (obj: any) => {
  try {
    var json = JSON.parse(obj)
    return typeof json === 'object'
  } catch (e) {
    return false
  }
}
// 빈오브젝트체크
export const isEmptyObject = (obj: any): boolean => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}
// 오브젝트퀴리스트링화 {a:1, b:2} => a=1&b=2 형태로 변환
export const objectToQueryString = (obj: any): string => {
  let _payload = ''
  Object.entries(obj).forEach(([key, value]) => {
    _payload += `${key}=${value}&`
  })
  return _payload
}
