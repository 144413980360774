/**
 * @name 태양광발전정보
 * @description
 */

import {createSlice, PayloadAction} from '@reduxjs/toolkit'
export const sliceName = 'solar' //변경필요
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  reload_time: string
  power_unit: 'kWh' | 'Wh'
  date: {
    title: string
    type: 'day' | 'month' | 'year'
  }
  chart: {}
  data: {}
  [key: string]: any
}
//*--------------------------------------------------*
/** @initialState */
const initialState: StateType = {
  reload_time: '새로고침',
  power_unit: 'Wh',
  chart: {}, //태양광차트데이터조회
  data: {}, //태양광데이터조회
  date: {
    title: '일',
    type: 'day',
  },
}
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // reloadTime
    setReloadTime(state: StateType, action: PayloadAction<any>) {
      state.reload_time = action.payload
    },
    // setChart
    setChart(state: StateType, action: PayloadAction<any>) {
      state.chart = action.payload
    },
    // setData
    setData(state: StateType, action: PayloadAction<any>) {
      state.data = action.payload
    },
    // 날짜선택
    setDate(state: StateType, action: PayloadAction<any>) {
      state.date = action.payload
      state.power_unit = action.payload?.type === 'year' ? 'kWh' : 'Wh'
    },
  },
})
//*--------------------------------------------------*
// export
export const EVENT = {
  // submit
  SELECT_DATE: 'SELECT_DATE',
}
// 레이어팝업이많을경우분기처리
export const POPUP = {}
export default slice.reducer
export const nameSpace = sliceName
export const actions = slice.actions
