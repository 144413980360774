/**
 * @name ListDescription
 * @description dl>dt,dd 기반리스트
 */
import React from 'react'
import styled from 'styled-components'
import {debug} from 'lib'
import {CSS, RenderItem} from 'types'
import {COLOR} from 'styles'

type Props = {
  info: any[]
  row?: boolean // 가로정렬,세로정렬
  renderItem?: RenderItem
  style?: CSS
}
export default function ListDescription({style, row, renderItem, info}: Props) {
  // const
  const _row: React.CSSProperties = row ? {flexDirection: 'row'} : {flexDirection: 'column'}
  const _style = Object.assign({}, _row, style)
  //*--------------------------------------------------*
  return (
    <Element style={_style}>
      {/* renderItem없을경우디버그 */}
      {renderItem === undefined && debug(info)}
      {!!info &&
        info?.length > 0 &&
        info.map((item, index) => {
          return !!renderItem && renderItem({item: item, index: index})
        })}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.ul`
  width: 100%;
  dl {
    display: flex;
    flex-direction: row;
    margin-bottom:2px;
    /* common */
    .right {
      justify-content: flex-end;
      align-items: flex-end;
    }
    dt,
    dd {
      display: flex;
      flex: 1;
      /* border: 1px solid ${COLOR.GRAY3}; */
      /* margin-top: -1px; */
      /* margin-left: -1px; */
      padding-top:14px;
      padding-bottom:14px;
    }
    dt {
      padding-left:20px;
      justify-content: flex-start
      align-items: center;
      font-size: 14px;
      background-color: ${COLOR.BLUE8};
    }
    dd {
      flex: 2;
      padding-left:20px;
    }
  }
`
/********************************************************
[사용법]

<ListDescription
  info={info1}
  renderItem={({item, index}) => {
    return (
      <dl key={uuid()}>
        <dt>{item?.key}</dt>
        <dd>{item?.value}</dd>
      </dl>
    )
  }}
/>
*********************************************************/
