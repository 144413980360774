/**
 * @name 충전소매출랭킹리스트
 * @description
 */
import styled from 'styled-components'
import {COLOR} from 'styles'
import {H6, Row, addComma} from 'lib'
import {ListRenderItem} from 'types'

export default function Contents({item, index, onClick}: ListRenderItem) {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content
      className="space_between"
      onClick={() => {
        !!onClick && onClick(item)
      }}>
      <Row className="info">
        <H6 className="index">{addComma(index + 1)}</H6>
        <section className="station">
          <div>
            <H6 color={COLOR.BLACK3}>{item?.name}</H6>
          </div>
          <div>
            <H6 color={COLOR.BLACK3}>
              {item?.address}
              {/* {item?.address_detail} */}
              {/* {item?.calculated_station?.address_detail} */}
            </H6>
          </div>
        </section>
      </Row>
      <div className="count">
        <p className="row">
          <span className="score"> {addComma(item?.hourly_calculations[0]?.total_charge_amount || 0)} 원</span>
        </p>
        {/* <Text color={COLOR.GRAY3} className="caption2">
            {addComma(item?.total_charge_kw)}원 / {item?.currency_code}
          </Text> */}
      </div>
    </Content>
  )
}

//*--------------------------------------------------*
const Content = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  height: 56px;
  /* padding-left: 10px; */
  padding-right: 16px;
  &:hover {
    background-color: ${COLOR.GRAY6};
  }
  /* background-color: green; */
  .index {
    display: flex;
    width: 44px;
    justify-content: center;
    color: ${COLOR.GRAY2};
  }
  .score {
    margin-left: 5px;
    color: ${COLOR.BLUE4};
  }
  p.row {
    height: 12px;
  }
  .count {
    text-align: right;
  }
  .info {
    display: flex;
  }
  section.station {
    display: flex;
    /* background-color: red; */
    /* width: 100px; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`
/*******

      {!!rank &&
          rank.map((item: any, index: number) => {
            return <ListRanking item={item} index={index} />
          })}

 */
