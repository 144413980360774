/**
 * @name 위젯
 * @description
 */
import React from 'react'
import {COLOR} from 'styles'
import {ListRenderItem} from 'types'
import styled from 'styled-components'
import {Flex, Loop, Text} from 'lib'
// contents
import Symbol from './symbol'
import {Empty, PrefixValueUnit} from 'pages/dashboard/common'

type Props = {
  info?: any
  [key: string]: any
}
export default function Contents({info}: Props) {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <Loop
        info={info?.furnace}
        emptyItem={<Empty />}
        renderItem={({item, index}: ListRenderItem) => {
          return (
            <React.Fragment>
              <Flex alignitems="center" justifycontent="space-between" style={{width: '80%', height: 200}}>
                <Symbol info={item?.remainAmount} />
                <Flex flexdirection="column" className="status" alignitems="flex-end" justifycontent="center">
                  <Text mode="Headline_MB" color={COLOR.gray30} label={item?.name} />
                  <PrefixValueUnit
                    style={{width: 180, textAlign: 'center'}}
                    minWidth={'auto'}
                    prefix={item?.currentTemperature?.name}
                    value={item?.currentTemperature?.data}
                    unit={item?.currentTemperature?.unit}
                    point={0}
                  />
                  <PrefixValueUnit
                    style={{width: 180, textAlign: 'center'}}
                    minWidth={'auto'}
                    prefix={item?.settingTemperature?.name}
                    value={item?.settingTemperature?.data}
                    unit={item?.settingTemperature?.unit}
                    point={0}
                  />
                </Flex>
              </Flex>
              {index !== info?.furnace?.length - 1 && <div style={{height: 1, backgroundColor: COLOR.gray80}} />}
            </React.Fragment>
          )
        }}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* padding: 0 46px; */
  align-self: center;
  /*--------------*/
  .status {
    flex: 1;
  }
`
