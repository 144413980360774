/**
 * @name Content
 * @description
 */
import {useState} from 'react'
import styled from 'styled-components'

export default function Contents({contents}: any) {
  // hooks
  const [currentSlide, setCurrentSlide] = useState(0)

  const nextSlide = () => {
    setCurrentSlide(oldCurrentSlide => (oldCurrentSlide + 1) % contents.length)
  }

  const prevSlide = () => {
    setCurrentSlide(oldCurrentSlide => (oldCurrentSlide - 1 + contents.length) % contents.length)
  }

  const randomColor = () => {
    const colors = ['red', 'green', 'blue', 'yellow', 'orange', 'purple', 'pink']
    return colors[Math.floor(Math.random() * colors.length)]
  }
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="slider-container" style={{transform: `translateX(-${currentSlide * 300}px)`}}>
        {contents.map((content: any, index: number) => (
          <div className="slide" key={index} style={{backgroundColor: randomColor()}}>
            <h1>{currentSlide}</h1>
            {content}
          </div>
        ))}
      </div>
      <button onClick={prevSlide}>Previous</button>
      <button onClick={nextSlide}>Next</button>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  position: relative;
  /**-------본문--*/
  .slider-container {
    display: flex;
    overflow: hidden;
    width: 100%;
  }

  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: 300px; // 높이 설정은 원하는 대로 조절하세요.
    transition: 0.5s;
  }
  button {
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
  }

  button:first-of-type {
    left: 10px;
  }

  button:last-of-type {
    right: 10px;
  }
  /* <!--media query --> */
  @media all and (min-width: 0) and (max-width: 480px) {
    /* background-color: red; */
  }
  @media all and (min-width: 480px) and (max-width: 1000px) {
    /* background-color: green; */
  }
  @media all and (min-width: 1000px) {
    /* background-color: blue; */
  }
`
