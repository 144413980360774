/**
 * @name 헤더
 * @description
 */
import {Flex, Text, useContext} from 'lib'
import {logoImage} from 'pages/common'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents

export default function Contents() {
  // hooks
  const {userId, user, factoryName} = useContext()

  //*--------------------------------------------------*
  return (
    <Content>
      <Flex alignitems="center">
        {/* <Icon src={company} width="80px" height="80px" /> */}
        <img src={logoImage(userId)} alt={userId} />
        <div style={{marginLeft: 16}}>
          <div>
            {/* {JSON.stringify(user, null, 1) + ''} */}
            <Text mode="Title_LB" label={factoryName} color={COLOR.gray30} />
          </div>
          {/* <Text mode="Body_MM" label="관리자" color={COLOR.gray50} /> */}
        </div>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  margin-bottom: 48px;
`
