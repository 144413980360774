/**
 * @name TextButton
 * @description 버튼
 */
import React, {CSSProperties} from 'react'
import styled from 'styled-components'
import {FONT} from 'styles'

type Props = {
  label: string
  className?: string //-------------------------------------------------- 클래스네임
  style?: CSSProperties //----------------------------------------------- 스타일시트
  onClick?: (event: React.MouseEvent<HTMLElement>) => void //------------ onClick 이벤트
}
export default function TextButton({label = 'TEXT_BUTTON', className, style, onClick}: Props) {
  // hooks
  return (
    <Element
      style={style}
      className={className}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        onClick && onClick(event)
      }}>
      {label}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.button`
  cursor: pointer;
  font-family: ${FONT.MAIN};
  font-size: 16px;
  font-weight: normal;
`

/********************************************************
[사용법]

// hooks
async function onClick(event: React.MouseEvent<HTMLElement>) {
  console.log(event)
}

<TextButton className="text-button" label="TextButton" onClick={(event: React.MouseEvent<HTMLElement>) => onClick(event)} />
  
// 스타일
const Content = styled.div`
  .text-button {
    color: #111;
    &:hover {
      color: red;
      text-decoration: underline;
    }
  }
`
*********************************************************/
