/**
 * @name 로딩바
 * @description
 */
import {LoadingAnimation} from 'lib'
import {useEffect} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents

export default function Contents() {
  // hooks

  //*--------------------------------------------------*
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <LoadingAnimation />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
`
