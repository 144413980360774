/**
 * @name Content
 * @description
 */
import {useState} from 'react'
import styled from 'styled-components'
import {Button, Flex, Table, uuid} from 'lib'
import {COLOR} from 'styles'
import {ListRenderItem} from 'types'

export default function Contents() {
  // hooks
  const info = [
    {
      col_0: 1,
      col_1: '장비',
      col_2: '140,알럿 내용 텍스트, 알럿 내용 텍스트, 알럿 내용 텍스트, 알럿 내용 텍스트, 알럿 내용 텍스트, 알럿 내용 텍스트, 알럿 내용 텍스트 ',
      col_3: '2023.07.23 / 14:50',
    },
    {col_0: 1, col_1: '장비', col_2: '모터 01가 작동 중지되었습니다.', col_3: '2023.07.23 / 14:50'},
    {col_0: 1, col_1: '장비', col_2: '모터 02가 작동 중지되었습니다.', col_3: '2023.07.23 / 14:50'},
    {col_0: 1, col_1: '에너지효율', col_2: '모터 02가 작동 중지되었습니다.', col_3: '2023.07.23 / 14:50'},
  ]
  //*--------------------------------------------------*
  return (
    <Content>
      <Table
        info={info}
        renderHeader={() => {
          return (
            <tr>
              <th style={{width: 100}}>중요도</th>
              <th style={{width: 100}}>알림유형</th>
              <th style={{width: 'auto'}}>내용</th>
              <th style={{width: 200}}>Date/Time</th>
            </tr>
          )
        }}
        renderItem={({item}: ListRenderItem) => {
          return (
            <tr key={uuid()}>
              <td className="c">{item?.col_0}</td>
              <td className="">{item?.col_1}</td>
              <td>{item?.col_2}</td>
              <td>{item?.col_3}</td>
            </tr>
          )
        }}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /**-------본문--*/
`
