/**
 * @name randomColor
 * @description 랜덤색상출력
 */
// 랜덤 색상을 생성하는 함수입니다.
// 각 색상 채널(R, G, B)에 대해 0부터 200 사이의 랜덤 값을 선택합니다.
// 이렇게 하면 생성된 색상이 너무 밝아지지 않습니다.
export const randomColor = () => {
  // return `#${Math.floor(Math.random() * 16777215).toString(16)}` //#FFFFFF포함
  // 최대 색상 값
  const maxColorValue = 230 // 0 ~ 255
  // 랜덤 색상 값을 생성합니다.
  const red = Math.floor(Math.random() * maxColorValue)
  const green = Math.floor(Math.random() * maxColorValue)
  const blue = Math.floor(Math.random() * maxColorValue)
  // 16진수 문자열로 변환하고 앞에 0을 붙여 2자리를 만듭니다.
  const redHex = red.toString(16).padStart(2, '0')
  const greenHex = green.toString(16).padStart(2, '0')
  const blueHex = blue.toString(16).padStart(2, '0')
  // 최종 색상 코드를 반환합니다.
  return `#${redHex}${greenHex}${blueHex}`
}

/**
 * 주어진 범위 내에서 랜덤 정수를 생성합니다.
 *
 * @param {number} start - 랜덤 정수의 최소 값
 * @param {number} end - 랜덤 정수의 최대 값
 * @returns {number} - 생성된 랜덤 정수
 */
export const randomNumber = (start: number, end: number): number => {
  return Math.floor(Math.random() * (end - start + 1)) + start
}
