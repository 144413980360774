/**
 * @name 헤더
 * @description
 */
import {download, print, reload, setup_gray} from 'assets/svg'
import {Icon, Text} from 'lib'
import {useEffect} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents
import {Store} from '../../index'

export default function Contents() {
  // hooks
  const {reload_time} = Store()

  //*--------------------------------------------------*
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <Content>
      {/* <button className="btn">
        <Icon src={setup_gray} width="24px" height="24px" style={{marginRight: 8}} />
        <Text mode="Btn_M" label={'알림 설정'} color={COLOR.gray30} />
      </button> */}
      <button
        className="btn"
        onClick={() => {
          console.log('test')
        }}>
        <Icon src={reload} width="24px" height="24px" style={{marginRight: 8}} />
        <Text mode="Btn_M" label={`${reload_time}`} color={COLOR.gray30} />
      </button>
      {/* <button className="btn">
        <Icon src={download} width="24px" height="24px" style={{marginRight: 8}} />
        <Text mode="Btn_M" label="엑셀 다운로드" color={COLOR.gray30} />
      </button>
      <button
        className="btn"
        onClick={() => {
          window.print()
        }}>
        <Icon src={print} width="24px" height="24px" style={{marginRight: 8}} />
        <Text mode="Btn_M" label="프린트" color={COLOR.gray30} />
      </button> */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  margin-right: 31px;
  button.btn {
    display: flex;
    margin-left: 16px;
    padding: 0 4px;
    height: 40px;
    align-items: center;
    justify-content: center;
    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
`
