/**
 * @name Devide
 * @description
 */
import styled from 'styled-components'
import {COLOR} from 'styles'
import {CSS, ClassName} from 'types'

type Props = {
  color?: string
  className?: ClassName
  style?: CSS
  gap?: number
  height?: number
}

export default function Devide({gap, height, color}: Props) {
  // hooks
  const defaultGap = 8
  const styles = Object.assign(
    {},
    {
      marginLeft: gap ?? defaultGap,
      marginRight: gap ?? defaultGap,
      backgroundColor: color ?? COLOR.BLACK3,
      height: height ?? 16,
    },
  )
  //*--------------------------------------------------*
  return <Content style={styles} />
}
//*--------------------------------------------------*
const Content = styled.div`
  // styles
  width: 1px;
`

/********************************************************
[사용법]
  <Devide gap={20} color={COLOR.RED} />

*********************************************************/
