/**
 * @name 장비세팅
 * @description
 */

import {createSlice, PayloadAction} from '@reduxjs/toolkit'
export const sliceName = 'equipment_setting' //변경필요
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  info: any[]
  devices: any[]
  [key: string]: any
}
//*--------------------------------------------------*
/** @initialState */
const initialState: StateType = {
  info: [],
  devices: [],
}
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // 지원가능디바이스전체조회
    setDevices(state: StateType, action: PayloadAction<any>) {
      state.devices = action.payload
    },
    // setData
    setInfo(state: StateType, action: PayloadAction<any>) {
      state.info = action.payload
    },
  },
})
//*--------------------------------------------------*
// export
export const EVENT = {
  DEVICES_DELETE: 'DEVICES_DELETE',
  DEVICES_ADD: 'DEVICES_ADD',
  DEVICES_UPDATE: 'DEVICES_UPDATE',

  // //bank
  // BANK_ADD: 'BANK_ADD',
  // BANK_REMOVE: 'BANK_REMOVE',
  // //equipment
  // EQUIPMENT_ADD: 'EQUIPMENT_ADD',
  // EQUIPMENT_REMOVE: 'EQUIPMENT_REMOVE',
  // EQUIPMENT_EDIT: 'EQUIPMENT_EDIT',
  // //facility
  // FACILITY_ADD: 'FACILITY_ADD',
  // FACILITY_REMOVE: 'FACILITY_REMOVE',
  // FACILITY_EDIT: 'FACILITY_EDIT',
  // //module
  // MODULE_ADD: 'MODULE_ADD',
  // MODULE_EDIT: 'MODULE_EDIT',
}
// 레이어팝업이많을경우분기처리
export const POPUP = {
  MAP: 'MAP',
  NOTICE: 'NOTICE',
}
export default slice.reducer
export const nameSpace = sliceName
export const actions = slice.actions
