/**
 * @name 뱅크(Bank)에서설비추가
 * @description
 */
import {Button, DropDown, Events, Flex, Input, Table, Text, TextButton, useContext, uuid} from 'lib'
import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {ListRenderItem} from 'types'
import {Store, _event} from '../../index'
// contents
type Props = {
  item?: any
  modify?: boolean
  setIsAdd: (value: boolean) => void
}
export default function Contents({setIsAdd, modify, item}: Props) {
  // hooks
  const {user} = useContext()
  const factoryDeviceType = 'facility'
  const parentNo = item?.factoryDeviceNo
  const factoryId = user?.factoryId
  const sn = useRef('')
  const name = useRef('')
  const instLocation = useRef('') //위치
  const maximumCapacity = useRef('') //정격용량
  const maximumCapacityUnit = useRef('') //정격용량단위
  const ratedCapacity = useRef('') //정격용량
  const ratedCapacityUnit = useRef('') //정격용량단위
  const [state, setState] = React.useState<any>(item)

  //*--------------------------------------------------*
  const onChange = (e: React.ChangeEvent<HTMLInputElement>, target: React.MutableRefObject<string>) => {
    const value = e.target.value
    target.current = value
  }
  useEffect(() => {
    modify && setState(item)
    !modify && setState(null)
  }, [modify])
  //*--------------------------------------------------*
  return (
    <Content>
      <Table
        info={[0]}
        renderHeader={() => {
          return (
            <React.Fragment>
              <tr className="add-row">
                <th>설비명</th>
                <th>설치위치</th>
                <th>최대용량</th>
                <th style={{width: 150}}>최대용량 단위</th>
                <th>정격용량</th>
                <th style={{width: 150}}>정격용량 단위</th>
              </tr>
            </React.Fragment>
          )
        }}
        renderItem={({item}: ListRenderItem) => {
          return (
            <React.Fragment>
              <tr key={uuid()}>
                {/* 설비명 */}
                <td>
                  <Input className="input" defaultValue={state?.name} onChange={event => onChange(event, name)} />
                </td>
                {/* 부착위치 */}
                <td>
                  <Input className="input" defaultValue={state?.instLocation} onChange={event => onChange(event, instLocation)} />
                </td>
                {/* 최대용량 */}
                <td>
                  <Input className="input" defaultValue={state?.maximumCapacity} onChange={event => onChange(event, maximumCapacity)} />
                </td>
                {/* 최대용량 단위 */}
                <td>
                  <DropDown
                    layerStyle={{height: 160, overflow: 'auto'}}
                    info={[
                      {label: 'W', value: 'W'},
                      {label: 'kW', value: 'kW'},
                      {label: 'kVA', value: 'kVA'},
                      {label: 'kg', value: 'kg'},
                      {label: 'ton', value: 'ton'},
                      {label: 'A', value: 'A'},
                      {label: 'V', value: 'V'},
                      {label: 'mm', value: 'mm'},
                    ]}
                    onChange={item => {
                      maximumCapacityUnit.current = item.value
                    }}
                  />
                </td>
                {/* 정격용량 */}
                <td>
                  <Input className="input" defaultValue={state?.ratedCapacity} onChange={event => onChange(event, ratedCapacity)} />
                </td>
                {/* 정격용량 단위 */}
                <td>
                  <DropDown
                    layerStyle={{height: 160, overflow: 'auto'}}
                    info={[
                      {label: 'W', value: 'W'},
                      {label: 'kW', value: 'kW'},
                      {label: 'kVA', value: 'kVA'},
                      {label: 'kg', value: 'kg'},
                      {label: 'ton', value: 'ton'},
                      {label: 'A', value: 'A'},
                      {label: 'V', value: 'V'},
                      {label: 'mm', value: 'mm'},
                    ]}
                    onChange={item => {
                      ratedCapacityUnit.current = item.value
                    }}
                  />
                  {/* <Input className="input" defaultValue={state?.ratedCapacityUnit} onChange={event => onChange(event, ratedCapacityUnit)} /> */}
                </td>
              </tr>
              <tr key={uuid()}>
                <td colSpan={14} style={{padding: 0}}>
                  {/* 입력창 */}
                  <section className="wrap">
                    <Text mode="Body_MM" color={COLOR.gray50}>
                      ※ 설치위치는 계통단선도 기준으로 입력하세요.
                    </Text>
                    <Flex alignitems="center" justifycontent="flex-end">
                      <TextButton
                        label="취소"
                        style={{marginRight: 24}}
                        onClick={() => {
                          setIsAdd(false)
                        }}
                      />
                      <Button
                        label="저장하기"
                        onClick={() => {
                          const result = {
                            factoryId: factoryId,
                            factoryDeviceType: factoryDeviceType,
                            sn: sn.current,
                            name: name.current,
                            instLocation: instLocation.current,
                            maximumCapacity: maximumCapacity.current,
                            maximumCapacityUnit: maximumCapacityUnit.current,
                            ratedCapacity: ratedCapacity.current,
                            ratedCapacityUnit: ratedCapacityUnit.current,
                            parentNo: parentNo,
                          }
                          if (modify) Events.emit(_event.DEVICES_UPDATE, item)
                          if (!modify) Events.emit(_event.DEVICES_ADD, result)
                        }}
                      />
                    </Flex>
                  </section>
                </td>
              </tr>
            </React.Fragment>
          )
        }}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: block;
  .add-row > th:not(.cell-line) {
    background-color: ${COLOR.blue90};
  }
  section.wrap {
    margin-bottom: 40px;
  }
  tbody tr td {
    padding: 0 8px;
    .input {
      width: 100%;
    }
  }
  tbody tr:nth-last-child(1) {
    td {
      border-bottom: 0;
    }
  }
`
