/**
 * @name 위젯(카드형)
 * @description 3x3 이면 row 3개, col 3개 각각 형태
 */
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Masonry} from 'template'
import {ListRenderItem} from 'types'
import {API} from 'config'
import {useAppSelector} from 'model'
// contents
import {Card} from '../../common'
// Widget
import Furnace from './furnace'
import DemandVoltage from './demand_voltage'
import ElectricCurrent from './electric_current'
import DistortionRate from './distortion_rate'
import Solar from './solar'
import Solution from './solution'
import VoltageAccuracy from './voltage_accuracy'

type CardType = {
  title: string
  type: string
  [key: string]: any
}
export default function Contents() {
  // hooks
  const {card_all} = useAppSelector(state => state.dashboard)
  const [card, setCard] = useState<any[]>([])
  const info = [
    {api: `${API}/card/acelo-solution`, type: 'acelo_solution', widget: <Solution />, height: 340} /**ACELO Solution */,
    {api: `${API}/card/voltage-prediction`, type: 'voltage_prediction', widget: <VoltageAccuracy />, height: 550} /**전압 예측 정확도 */,
    {api: `${API}/card/furnace-status`, type: 'melting_status', widget: <Furnace />, height: 550} /**용해로 상태 */,
    {api: `${API}/card/current-voltage`, type: 'current_voltage', widget: <DemandVoltage />, height: 550} /**현재 수요/전압 */,
    {api: `${API}/card/solar-power-status`, type: 'solar_power_status', widget: <Solar />, height: 550} /**태양광 발전량/누적 발전량 */,
    {api: `${API}/card/distortion-rate`, type: 'distortion_rate', widget: <DistortionRate />, height: 340} /**고조파 왜형률 */,
    {api: `${API}/card/electric-current`, type: 'electric_current', widget: <ElectricCurrent />, height: 340} /**현재 전류 */,
  ]
  //*--------------------------------------------------*
  useEffect(() => {
    const card = card_all
      ?.map((list: CardType) => {
        const _info = info.find((item: any) => item.type === list.type)
        return _info ? Object.assign({}, list, _info) : null
      })
      .filter((item: any) => item !== null) // 이 부분이 추가되었습니다.
    setCard(card)
  }, [card_all])
  //*--------------------------------------------------*

  return <Content>{card.length > 0 && <Masonry className="masonry" columngap={24} columncount={3} info={card} renderItem={({item}: ListRenderItem) => <Card item={item} />} />}</Content>
}
//*--------------------------------------------------*
const Content = styled.div`
  display: block;
  box-sizing: border-box;
  padding: 0 24px;
  /* margin: 0 24px 0; */
  /*--------------*/
`
