/**
 * @name 헤더
 * @description
 */
import {Flex, Tab, Tabs, Text} from 'lib'
import {useEffect} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents
import Util from './util'

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex flexdirection="column" alignitems="flex-start">
        <Text mode="Title_LB" color={COLOR.gray30}>
          일 별 태양광 발전 정보
        </Text>
        <Text mode="Body_MM" color={COLOR.gray50}>
          그래프 위에 마우스를 올려 시간대별 발전량을 확인해보세요.
        </Text>
      </Flex>
      <Tab
        className={'tab'}
        info={['일', '월', '년']}
        onChange={({item, index}) => {
          console.log([item, index])
        }}
        renderItem={({item, index}) => <span>{item}</span>}
      />
    </Content>
  )
}

//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 264px;
    height: 48px;
    padding: 4px;
    border: 1px solid ${COLOR.blue100};
    border-radius: 8px;
    background: ${COLOR.blue100};
    > button {
      display: flex;
      width: 80px;
      height: 40px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      color: ${COLOR.gray30};
      font-size: 14px;
      &:first-child {
        border-left: 0;
      }
      &.active {
        color: ${COLOR.WHITE};
        background-color: ${COLOR.BLUE3};
      }
    }
  }
`
