/**
 * @name Table영역
 * @description
 */
import {Table, addComma, uuid} from 'lib'
import React from 'react'
import styled from 'styled-components'
import {ListRenderItem} from 'types'
import {Store} from '../../index'
// contents

export default function Contents() {
  // hooks
  const {data, power_unit} = Store()
  //*--------------------------------------------------*
  return (
    <Content>
      <Table
        info={data}
        renderHeader={() => {
          return (
            <React.Fragment>
              <tr>
                <th className="c">수집 시간</th>
                <th className="c">발전량({power_unit})</th>
                <th className="c">예상 발전량 ({power_unit})</th>
                <th className="c">누적 발전량({power_unit})</th>
              </tr>
            </React.Fragment>
          )
        }}
        renderItem={({item}: ListRenderItem) => {
          return (
            <tr key={uuid()}>
              {/* 수집시간 */}
              <td className="c" style={{paddingLeft: 15}}>
                {item?.time}
              </td>
              {/* 발전량 */}
              <td className="c">{item?.value ? addComma(item?.value) : '-'}</td>
              {/* 예상 발전량 */}
              <td className="c">{item?.predictedValue ? addComma(item?.predictedValue) : '-'}</td>
              {/* 누적 발전량 */}
              <td className="c">{addComma(item?.sum)}</td>
              {/* 일사량 */}
            </tr>
          )
        }}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /**-------본문--*/
  overflow-y: auto;
`
