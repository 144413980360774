/**
 * @name 캘린더
 * @description
 */
import {useRef, useState} from 'react'
import {COLOR} from 'styles'
import styled from 'styled-components'
import {useClickOutside} from 'lib'
import {Calendar} from 'components/element'

type Props = {
  style?: React.CSSProperties //---------------------- StyleSheet
  popupStyle?: React.CSSProperties //----------------- PopupStyleSheet
  onChange?: (value: boolean) => void
  disabled?: boolean //------------------------------- disabled
  value?: string
  [key: string]: any
}
export default function TextFiledCalendar({onChange, style, disabled, popupStyle, value}: Props) {
  // hooks
  const eleRef = useRef(null)
  const [select, setSelect] = useState(value ?? '') as any
  const [visible, setVisible] = useState(false)
  //*--------------------------------------------------*
  // outside click
  useClickOutside(eleRef, () => {
    setVisible(false)
  })
  // makeForm
  const setFormat = (data: {year: string; month: string; date: string}) => {
    if (typeof data === 'string') return data
    const {year, month, date} = data
    return `${year}-${month}-${date}`
  }
  //*--------------------------------------------------*
  return (
    <Content ref={eleRef} style={style}>
      <button
        className="select"
        onClick={() => {
          // 비활성화면 종료
          if (disabled) return
          setVisible(!visible)
        }}>
        <label className="selected">{setFormat(select) ?? ``}</label>
      </button>
      {/* 레이어팝업 */}
      {visible && (
        <section className="popup" style={popupStyle}>
          <Calendar
            onChange={(value: any) => {
              setSelect(value)
              setVisible(false)
              !!onChange && onChange(value)
            }}
          />
        </section>
      )}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  position: relative;
  width: 100%;
  /* min-width: 120px;
  max-width: 140px;
  */
  /* 레이어팝업 */
  button.select {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 0 16px;
    border: 1px solid ${COLOR.GRAY4};
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    label.selected {
      color: ${COLOR.BLACK1};
    }
  }
  /* 레이어팝업 */
  section.popup {
    position: absolute;
    bottom: 0;
    z-index: 10;
    background-color: ${COLOR.WHITE};
    width: 100%;
    max-width: 394px;
    height: 404px;
    padding: 16px 8px;
    box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
    border-radius: 8px;
  }
`
/********************************************************

<DropDownTimes
  value={payload?.station_open[index]?.open_time}
  onChange={(data: any) => {
    onChangeHandler({key: 'open_time', index, value: data?.value})
  }}
/>
*********************************************************/
