/**
 * @name Panel
 * @description 가이드페이지
 */
import styled from 'styled-components'
import {COLOR} from 'styles'
import {CSS} from 'types'

type Props = {
  style?: CSS //-------------------------------------- 스타일시트
  header?: React.ReactNode //------------------------- Header
  footer?: React.ReactNode //------------------------- Footer
  children?: React.ReactNode //----------------------- Children
  className?: string //------------------------------- 클래스네임
  padding?: string //--------------------------------- 여백
  border?: string //---------------------------------- 테두리
  borderRadius?: string //---------------------------- 곡률
  [key: string]: any
}
export default function Panel({children, header, footer, className, style, border, borderRadius, padding}: Props) {
  return (
    <Content style={style} border={border} padding={padding} borderRadius={borderRadius} className={className}>
      {/* 헤더영역 */}
      {!!header && <header>{header}</header>}
      {/* 본문영역 */}
      <main className="panel_main">{children}</main>
      {/* 푸터영역 */}
      {!!footer && <footer>{footer}</footer>}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.section<Props>`
  overflow: hidden;
  flex-direction: column;
  /* border: 1px solid ${COLOR.GRAY5}; */
  border: ${({border}) => border || '0'};
  border-radius: ${({borderRadius}) => borderRadius || '10px'};
  background: ${COLOR.WHITE};
  /* box-shadow: 0px 3px 10px 0px rgba(6, 25, 56, 0.08); */
  box-shadow: 0px 6px 10px 0px rgba(6, 25, 56, 0.07);
  /* 본문영역 */
  > main.panel_main {
    min-height: 100px;
    padding: ${({padding}) => padding || '16px'};
    height: calc(100vh - 250px);
    overflow-y: auto;
  }
`
/********************************************************
[사용법]
<Panel borderRadius={'0px'}
  header={
    <div>
      <h1>Panel 타이틀 TEST</h1>
    </div>
  }>
  내용들
</Panel>


*********************************************************/
