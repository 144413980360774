/**
 * @name 헤더
 * @description
 */
import {Flex, Text} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents

export default function Contents() {
  // hooks

  //*--------------------------------------------------*
  return (
    <Content>
      <Flex alignitems="center" flexdirection="row" justifycontent="flex-end">
        {/* <Tab
          className={'tab'}
          info={['일', '월', '년']}
          onChange={({item}) => {
            let _type
            if (item === '일') _type = 'day'
            if (item === '월') _type = 'month'
            if (item === '년') _type = 'year'
            Events.emit(_event.SELECT_DATE_TYPE, {title: item, type: _type})
          }}
          renderItem={({item, index}) => <span>{item}</span>}
        /> */}
        <Text mode="Body_MM" color={COLOR.gray50} label="그래프 위에 마우스를 올려 시간대 별 상세 데이터를 확인해 보세요." />
      </Flex>
    </Content>
  )
}

//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 264px;
    height: 48px;
    padding: 4px;
    border: 1px solid ${COLOR.blue100};
    border-radius: 8px;
    background: ${COLOR.blue100};
    > button {
      display: flex;
      width: 80px;
      height: 40px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      color: ${COLOR.gray30};
      font-size: 14px;
      &:first-child {
        border-left: 0;
      }
      &.active {
        color: ${COLOR.WHITE};
        background-color: ${COLOR.BLUE3};
      }
    }
  }
`
