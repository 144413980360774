/**
 * @name 설비(Facility)
 * @description
 */
import React, {useEffect, useState} from 'react'
import {arrow_down, arrow_next, del, edit, plus_yellow, round_green} from 'assets/svg'
import {Events, Flex, Icon, Image, ImageButton, Text, uuid} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents
import Add from './add'
import {Module} from '..'
import {EquipmentProps} from 'types'
import {_event} from '../../index'
import {status_error, status_on} from 'assets/images'

type Props = {
  item: EquipmentProps
  index: number
  total?: number | any
}
export default function Contents({total, item, index}: Props) {
  // hooks
  const [visible, setVisible] = useState(false) // 자식노드
  const [isAdd, setIsAdd] = useState(false) //등록유무
  const [isModify, setIsModify] = useState(false) //수정유무
  const isChildren = item.children?.length > 0 ? true : false
  const _total = item?.children?.length
  //*--------------------------------------------------*
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <React.Fragment>
      <Content key={uuid()} className="equipment-item">
        <td className={total - 1 === index && _total === 0 ? 'cell-end' : 'cell-start'}></td>
        <td>
          <button
            className="toggle"
            onClick={() => {
              setVisible(!visible)
            }}>
            {/* 자식있을때만 노드생성 */}
            {isChildren ? <Icon src={visible ? arrow_down : arrow_next} /> : <div style={{width: 24}} />}
            <Icon src={round_green} width="14px" height="14px" style={{marginRight: 5}} />
            <Text mode="Body_MM" color={COLOR.gray40}>
              장비
            </Text>
          </button>
        </td>
        {/* 장비명 */}
        <td className="">
          <Text mode="Body_MM_underline" color={COLOR.gray40} label={item?.name} />
        </td>
        {/* 장비상태 */}
        <td>
          {item?.status === 'OK' && <Image src={status_on} style={{width: 48, height: 32, verticalAlign: 'middle'}} />}
          {item?.status === 'ERROR' && <Image src={status_error} style={{width: 37, height: 32, verticalAlign: 'middle'}} />}
          {item?.status === 'UNKNOWN' && <span>-</span>}
        </td>
        {/* 모델명 */}
        <td>
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.name || '-'} />
        </td>
        {/* 제품번호 */}
        <td>
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.sn || '-'} />
        </td>
        {/* 부착위치 */}
        <td>
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.instLocation || '-'} />
        </td>
        {/* 최대용량 */}
        <td>
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.maximumCapacity || '-'} />
        </td>
        {/* 정격용량 */}
        <td>
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.ratedCapacity || '-'} />
        </td>
        {/* IP */}
        <td>
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.ipAddress} />
        </td>
        {/* Port */}
        <td>
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.port || '-'} />
        </td>
        {/* MAC */}
        <td>
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.macAddress || '-'} />
        </td>
        {/* <td>
          <Flex alignitems="center">
            <ImageButton
              src={edit}
              style={{marginRight: 20}}
              onClick={() => {
                setIsModify(true)
                setIsAdd(true)
              }}
            />
            <ImageButton
              src={del}
              onClick={() => {
                Events.emit(_event.DEVICES_DELETE, item)
              }}
            />
          </Flex>
        </td> */}
        {/* <td className="c">
          <ImageButton
            src={plus_yellow}
            onClick={() => {
              setIsModify(false)
              setIsAdd(true)
            }}
          />
        </td> */}
      </Content>
      {/* 등록화면 */}
      {isAdd && (
        <tr>
          <td colSpan={16} className="child-node">
            <Add setIsAdd={setIsAdd} modify={isModify} item={item} />
          </td>
        </tr>
      )}
      {visible && item?.children.length > 0 && (
        <tr>
          <td colSpan={16} className="child-node">
            {/* 장비내용 */}
            <Module item={item} />
          </td>
        </tr>
      )}
    </React.Fragment>
  )
}
//*--------------------------------------------------*
const Content = styled.tr``
