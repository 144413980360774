/**
 * @name 장비세팅
 * @description
 * !  Bank > 설비(Facility)> 장비(Equipment)> 모듈(Module)
 */

import {useAppSelector} from 'model'
import {actions, EVENT, POPUP} from 'model/modules/equipment_setting'
export {default} from './container'
//*--------------------------------------------------*
/**
 * @name config
 * @description Redux Action, Mode, Event, Popup
 */
export const Store = () => useAppSelector(state => state.equipmentSetting)
export const _action = actions
export const _event = EVENT
export const _popup = POPUP

/********************************************************

*********************************************************/
