/**
 * @name 열림/펼침
 */
import styled from 'styled-components'
import {Flex, Icon, ImageButton, Text, debug, useContext, useRoutes, uuid} from 'lib'
import {arrow_next, expand_off, expand_on, top_path} from 'assets/svg'
import {useAppDispatch, useAppSelector} from 'model'
import {gnbAction} from 'model/modules'
import {MENU} from 'config'
// contents

export default function GnbTop() {
  // hooks
  const {sitemap, navi, isExpand} = useAppSelector(state => state.gnb)
  const {navigate} = useRoutes()
  const {factoryType} = useContext()
  const dispatch = useAppDispatch()
  //*--------------------------------------------------*
  // 1depth이하예외케이스
  const setDepth1 = () => {
    const depth1 = navi?.depth1

    let label
    if (depth1 === 900) label = '장치 현황'
    else label = sitemap[navi?.depth1]?.name || '대시보드'
    if (label === '대시보드') {
      return <Text mode="Btn_M" label={label} />
    } else {
      return (
        <Flex>
          <button
            onClick={() => {
              navigate(MENU.DASHBOARD)
            }}>
            <Text mode="Btn_M" label={'대시보드'} />
          </button>
          <Icon key={uuid()} src={arrow_next} width={'24px'} height="24px" />
          <Text mode="Btn_M" label={label} />
        </Flex>
      )
    }
  }

  // 2depth이하메뉴들
  const setDepth2 = () => {
    const depth2 = navi?.depth2
    if (depth2 < 0) return null
    const icon = <Icon key={uuid()} src={arrow_next} width={'24px'} height="24px" />
    const ele = (
      <button key={uuid()}>
        <Text mode="Btn_M" label={factoryType() === 'solar' ? '변압기' : sitemap[navi?.depth1]?.children[navi?.depth2].name} />
      </button>
    )
    return [icon, ele]
  }
  //*--------------------------------------------------*
  return (
    <Content>
      {/* {debug(sitemap)} */}
      {/* 로고 */}
      <Flex width="100%" alignitems="center" justifycontent="space-between">
        <ImageButton
          style={{marginLeft: 4, marginRight: 8}}
          src={isExpand ? expand_on : expand_off}
          alt="aside_toggle"
          width={24}
          height={24}
          onClick={() => {
            dispatch(gnbAction.setIsExpand(!isExpand))
            let itv = 0
            setInterval(() => {
              itv += 1
              if (itv > 10) {
                clearInterval(itv)
                return
              }
              dispatchEvent(new Event('resize'))
            }, 500)
          }}
        />
        <Icon key={uuid()} src={top_path} width="44px" height="44px" />
        <Flex className="trace" alignitems="center" justifycontent="center">
          {/* 1depth메뉴들 */}
          <button key={uuid()}>{setDepth1()}</button>
          {/* 2depth이하메뉴들 */}
          {setDepth2()}
        </Flex>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.header`
  display: flex;
  position: relative;
  height: 40px;
  .trace {
    margin-left: 10px;
  }
`
