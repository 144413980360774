/**
 * @name Table영역
 * @description
 */
import {Pagination, Table, Text, uuid} from 'lib'
import React from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {ListRenderItem} from 'types'

export default function Contents() {
  // hooks
  const info = [
    {
      col_0: '부족',
      col_1: '용해로',
      col_2: '용해로 내용물의 소비가 많으므로 신속 충전이 필요합니다.',
      col_3: '2023.07.23 / 14:50',
    },
    {
      col_0: '충전',
      col_1: '용해로',
      col_2: '용해로 내용물의 소비 대비 충전이 필요합니다.',
      col_3: '2023.07.19 / 03:50',
    },
    {
      col_0: '여유',
      col_1: '용해로',
      col_2: '용해로 내용물의 소비와 충전이 적절한 상태입니다.',
      col_3: '2023.07.04 / 17:50',
    },
    {
      col_0: '넘침',
      col_1: '용해로',
      col_2: '용해로 내용물이 과다 충전되었습니다..',
      col_3: '2023.07.01 / 11:50',
    },
  ]
  const setType = (type: string) => {
    if (type === '부족') return <span className="red">{type}</span>
    if (type === '넘침') return <span className="red">{type}</span>
    if (type === '여유') return <span className="green">{type}</span>
    if (type === '재가동') return <span className="green">{type}</span>
    if (type === '충전') return <span className="yellow">{type}</span>
    return <span className="gray">{type}</span>
  }
  //*--------------------------------------------------*
  return (
    <Content>
      <Table
        info={info}
        renderHeader={() => {
          return (
            <React.Fragment>
              <tr>
                <th className="l" style={{width: 100}}>
                  알림 유형
                </th>
                <th className="l" style={{width: 100}}>
                  알림 종류
                </th>
                <th className="l" style={{width: 'auto'}}>
                  내용
                </th>
                <th className="l" style={{width: 200}}>
                  날짜 / 시간
                </th>
              </tr>
            </React.Fragment>
          )
        }}
        renderItem={({item}: ListRenderItem) => {
          return (
            <tr key={uuid()}>
              <td style={{paddingLeft: 15}}>
                <Text mode="Btn_S">{setType(item?.col_0)}</Text>
              </td>
              <td className="">{item?.col_1}</td>
              <td>{item?.col_2}</td>
              <td>{item?.col_3}</td>
            </tr>
          )
        }}
      />
      <div style={{width: 598, margin: '10px auto 0'}}>
        <Pagination />
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /**-------본문--*/
  td {
    span {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 6px;
      border-radius: 4px;
      color: ${COLOR.white};
      &.red {
        background-color: ${COLOR.red30};
      }
      &.green {
        background-color: ${COLOR.green30};
      }
      &.yellow {
        background-color: ${COLOR.yellow30};
      }
      &.gray {
        background-color: ${COLOR.gray30};
      }
    }
  }
`
