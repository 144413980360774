/**
 * @name Routes
 * @description 라우팅설정
 */

import * as To from 'pages'
import {gnb_dashboard, gnb_dashboard_hover, gnb_solar, gnb_solar_hover, gnb_use, gnb_use_hover} from 'assets/svg'
import {GnbMenuType, MenuRoutesType} from 'types'

/*===================================================================[메뉴] */
export const MENU = {
  /*---공통---------*/
  HOME: '/',
  TEST: '/test',
  DASHBOARD: '/dashboard',
  SETTING: '/setting',
  USE: '/use',
  SOLAR: '/solar',
  NOTIFICATION: '/notification',
  NOTIFICATION_SETUP: '/notification-setup',
  EQUIPMENT_SETTING: '/equipment_setting',
  /*---AUTH---------*/
  PROFILE: '/auth/profile',
  SIGN_IN: '/auth/sign-in',
  SIGN_OUT: '/auth/sign-out',
  //*--------------------------------------------------*
}
/*===================================================================[라우팅] */
export const MenuRoutes: MenuRoutesType[] = MENU && [
  /*---홈---------*/
  {path: MENU.HOME, element: <To.Home />},
  /*---페이지---------*/
  {path: MENU.DASHBOARD, element: <To.Dashboard />},
  {path: MENU.USE, element: <To.Use />},
  {path: MENU.SOLAR, element: <To.Solar />},
  {path: MENU.NOTIFICATION, element: <To.Notification />},
  {path: MENU.NOTIFICATION_SETUP, element: <To.NotificationSetup />},
  {path: MENU.EQUIPMENT_SETTING, element: <To.EquipmentSetting />},
  /*---Auth,로그인,로그아웃---------*/
  {path: MENU.PROFILE, element: <To.Profile />},
  {path: MENU.SIGN_IN, element: <To.SignIn />},
  {path: MENU.SIGN_OUT, element: <To.SignOut />},
  /*---etc---------*/
  {path: MENU.SETTING, element: <To.Setting />},
  {path: MENU.TEST, element: <To.Test />},
]
/*===================================================================[GNB] */
export const GNB_MENU: GnbMenuType[] = MENU && [
  {name: '대시보드', menu: 'HOME', path: MENU.DASHBOARD, icon: gnb_dashboard, iconhover: gnb_dashboard_hover},
  {
    name: '에너지 사용',
    menu: 'ENERGY_USE',
    path: MENU.USE,
    icon: gnb_use,
    iconhover: gnb_use_hover,
    children: [
      {name: '용해로 정보', menu: 'TR_STATUS', path: MENU.USE},
      // {name: '용해로 정보', menu: 'NOTI_SETTING', path: MENU.USE},
    ],
  },
  {name: '태양광 발전 정보', menu: 'SOLAR_POWER', path: MENU.SOLAR, icon: gnb_solar, iconhover: gnb_solar_hover},
  /*---이전메뉴---------*/
]
