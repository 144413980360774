/**---------------------------------- @name API */
export const API_DEV = 'https://api-dev.acelogrid.com'
export const API_STAGING = 'https://api-stage.acelogrid.com'
export const API_PROD = 'https://api.acelogrid.com'
export const API_CHECK = () => {
  const mode = process.env.REACT_APP_MODE
  if(mode==='STAGING') return API_STAGING //스테이징
  else if (mode === 'DEV') return API_DEV //개발
  else if(mode==='PROD') return API_PROD //운영
  else return API_PROD
}
export const API = API_CHECK() 
// export const API = 'https://api-dev.acelogrid.com'

/**---------------------------------- @name API_KEY */
export const GOOGLE_MAP_KEY = 'AIzaSyArzPXNGE4Xwx7mpmSkjIQNIbhHZv0yK_A'

/**---------------------------------- @name KAKAO_API_KEY */
export const KAKAO_REST_API = '9c97e8aaa10542d168be1260e03d063d'

/********************************************************
[사용법]

// develop 브랜치
https://hey.acelogrid.com 

// main 브랜치
https://acelogrid.com 


*********************************************************/
