/**
 * @name 위젯
 * @description
 */
import {useEffect, useRef, useState} from 'react'
import * as echarts from 'echarts'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {TitleValueUnit} from 'pages/dashboard/common'
import {useEventListener} from 'usehooks-ts'
import {Text} from 'lib'
// contents
type Props = {
  info?: any
}
export default function Contents({info}: Props) {
  // hooks
  function checkI_avg() {
    if (info?.I_avg?.unit.indexOf('k') !== -1) return info?.I_avg?.data * 1000
    else return info?.I_avg?.data
  }
  function checkMax() {
    if (info?.max?.unit.indexOf('k') !== -1) return info?.max?.data * 1000
    else return info?.max?.data
  }

  const [chartElement, setChartElement] = useState<echarts.ECharts>()
  const chartRef = useRef(null)
  const option = {
    grid: {
      top: '10%',
      left: '20%',
      right: '20%',
      bottom: '10%',
      containLabel: false,
    },
    series: [
      {
        name: '현재 전류',
        type: 'pie',
        radius: ['150%', '110%'],
        center: ['50%', '78%'],
        itemStyle: {
          normal: {
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
          },
        },
        // adjust the start angle
        startAngle: 180,
        color: [COLOR.blue30, COLOR.gray80, COLOR.yellow30],
        data: [
          {value: checkI_avg(), name: 'Search Engine'},
          {value: checkMax() - checkI_avg(), name: 'Direct'},
          {
            value: checkMax(),
            itemStyle: {
              color: 'none',
              decal: {
                symbol: 'none',
              },
            },
            label: {
              show: false,
            },
          },
        ],
      },
    ],
  } as any

  const handleSize = () => {
    !!chartElement && chartElement?.resize()
  }
  useEventListener('resize', handleSize)
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current)
      setChartElement(chart)
      chart.setOption(option)
      const handleClick = (params: any) => {}
      // Bind the event listener
      chart.on('click', handleClick)
    }
    return () => {}
  }, [chartRef])

  //*--------------------------------------------------*
  return (
    <Content>
      {/* {info?.I_avg?.data + ''}
      {info?.max?.data + ''} */}
      <div
        ref={chartRef}
        style={{
          width: '100%',
          height: '125px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <div className="layer">
        <TitleValueUnit style={{textAlign: 'center'}} value={info?.I_avg?.data} unit={info?.I_avg?.unit} />
      </div>
      <div className="total">
        <Text mode="Caption_LB" label={info?.max?.data + info?.max?.unit} color={COLOR.gray50} />
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  position: relative;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  .layer {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .total {
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 80px;
  }

  /*--------------*/
`
