/**
 * @name 대시보드
 * @description
 */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
// 모드변경 _mode
export const MODE = {}
// 팝업 _popup
export const POPUP = {}
// export
export const EVENT = {
  DASHBOARD_REFRESH: 'DASHBOARD_REFRESH',
}

//*--------------------------------------------------*
/** @defineType */
type StateType = {
  refresh_time: string
  card_all: any[]
  info: any[]
  rank: any[]
  [key: string]: any
}
//*--------------------------------------------------*
/** @initialState */
export const sliceName = 'dashboard' //변경필요
const initialState: StateType = {
  refresh_time: '',
  card_all: [],
  info: [],
  rank: [],
}
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // 대시보드 화면초기화
    setRefreshTime(state: StateType, action: PayloadAction<any>) {
      state.refresh_time = action.payload
    },
    // 전체카드데이터조회
    setCardAll(state: StateType, action: PayloadAction<any>) {
      state.card_all = action.payload
    },
    // 위젯업데이트
    setWidget(state: StateType, action: PayloadAction<any>) {
      state.widget = Object.assign(state.widget, action.payload)
    },
  },
})
//*--------------------------------------------------*
export default slice.reducer
export const nameSpace = sliceName
export const actions = slice.actions
