/**
 * @name Settings
 * @description
 */
import {useEffect} from 'react'
import {useScroll} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {Panel} from 'components'
// contents
import {Header, Footer} from './contents'

export default function Index() {
  // hooks
  const {isScrollEnd, scrollTop} = useScroll(50)

  useEffect(() => {
    if (isScrollEnd) {
      console.log('Reached end of scroll')
      console.log('스크롤:', scrollTop)
    }
    // console.log(scrollTop)
  }, [isScrollEnd, scrollTop])
  //*--------------------------------------------------*
  return (
    <App>
      <Content>
        <Panel header={<Header />} footer={<Footer />}>
          {/* 랜덤숫자생성 */}
          settings
        </Panel>
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  table.sample {
    tr {
    }
  }
`
