/**
 * @name 가이드
 * @description
 */

import {createSlice, PayloadAction} from '@reduxjs/toolkit'
export const sliceName = 'guide' //변경필요
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  info: any[]
  data: {}
  [key: string]: any
}
//*--------------------------------------------------*
/** @initialState */
const initialState: StateType = {
  info: [],
  data: {},
}
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // setData
    setData(state: StateType, action: PayloadAction<any>) {
      state.data = action.payload
    },
    // setFetch
    setFetch(state: StateType, action: PayloadAction<any>) {
      state.info = Object.assign(state.info, action.payload)
    },
  },
})
//*--------------------------------------------------*
// export
export const EVENT = {
  // submit
  SUBMIT: 'SUBMIT',
  UPDATE: 'UPDATE',
  // 레이어팝업
  POPUP_SHOW: 'POPUP_SHOW',
  POPUP_HIDE: 'POPUP_HIDE',
  POPUP_SET: 'POPUP_SET',
}
// 레이어팝업이많을경우분기처리
export const POPUP = {
  MAP: 'MAP',
  NOTICE: 'NOTICE',
}
export default slice.reducer
export const nameSpace = sliceName
export const actions = slice.actions
