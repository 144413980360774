/**
 * @name Echarts
 * @description
 * @description name값은필요하다.
 * @reference https://echarts.apache.org/handbook/en/concepts/dataset (데이타포멧및커스텀)
 */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import * as echarts from 'echarts'
import styled from 'styled-components'
import {CSS} from 'types'
import {useEventListener} from 'usehooks-ts'

type Props = {
  style?: CSS //----------------------------------------------- 스타일
  options?: echarts.EChartsOption //-------------------------- 차트옵션
  width?: number //-------------------------------------------- 차트넓이
  height?: number //------------------------------------------- 차트높이
  onClick?: () => void
}

export default function EchartsModule({options, style, width, height}: Props) {
  // hooks
  const chartRef = useRef(null)
  const [chartElement, setChartElement] = useState<echarts.ECharts>()
  const memoizedOptions = useMemo(() => options, [options]) as echarts.EChartsOption
  //*--------------------------------------------------*
  // handleClick
  const handleClick = useCallback((params: any) => {
    console.log(params)
  }, [])
  // 윈도우Resize 이벤트
  useEventListener('resize', () => {
    !!chartElement && chartElement?.resize()
  })
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current)
      setChartElement(chart)
      chart.setOption(memoizedOptions)
      chart.on('click', handleClick)
      // Cleanup function
      return () => {
        chart.off('click', handleClick) // Remove the click event listener
      }
    }
    return () => {}
  }, [memoizedOptions, handleClick])

  //*--------------------------------------------------*
  return <Content width={width} height={height} ref={chartRef} style={style} />
}
//*--------------------------------------------------*
const Content = styled.div<Props>`
  width: ${({width}) => (!!width ? `${width}px` : '100%')};
  height: ${({height}) => (!!height ? `${height}px` : '500px')};
`
/********************************************************
[사용법]

  <Echarts options={options} width={700} height={100} />

//
const [options, setOptions] = useState<echarts.EChartsOption>({
    tooltip: {
      trigger: 'axis',
      axisPointer: {type: 'cross'},
    },
    //범례
    legend: {
      orient: 'horizontal',
      icon: 'pin', //pin,rect
      textStyle: {},
      // right: 10,
      // top: 'center',
    },
    xAxis: {
      type: 'category',
      name: 'Day',
      data: ['일', '월', '화', '수', '목', '금', '토'],
      // x축 눈금선
      axisLine: {
        symbol: 'none',
        lineStyle: {
          type: 'dashed',
        },
      },
      axisTick: {
        show: true,
        alignWithLabel: true, //! 눈금선과 라벨의 정렬
        lineStyle: {
          type: 'dashed',
          width: 1,
        },
      },
      axisLabel: {
        formatter: '{value}요일',
        align: 'center',
        rotate: 45,
        margin: 40,
      },
    },
    yAxis: [
      {
        type: 'value',
        name: 'Y축Name',
        position: 'left',
        axisTick: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      {
        type: 'value',
        name: 'Temperature',
        position: 'right',
        axisLabel: {
          formatter: '{value} °C',
        },
      },
    ],
    series: [
      {
        name: 'Temperature',
        data: [224, 218, 135, 147, 260, 115, 310],
        type: 'line', // line, bar
        areaStyle: {
          color: 'red',
          opacity: 0.3,
        },
      },
      {
        name: 'Amount',
        data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
        type: 'bar', // line, bar
        yAxisIndex: 1,
        smooth: true,
        areaStyle: {
          color: 'blue',
          opacity: 0.3,
        },
      },
    ],
  })
*********************************************************/
