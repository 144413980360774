/**
 * @name Const
 * @description
 */

// 세션스토리지
export const SESSION_STORAGE = {
  TOKEN: 'ACELO_GRID_TOKEN',
  USER_ID: 'ACELO_GRID_USER_ID',
  PASSWORD: 'ACELO_GRID_PASSWORD',
  FACTORY_ID: 'ACELO_GRID_FACTORY_ID', //------------------비지니스시퀀스
}

/********************************************************


*********************************************************/
