/**
 * @name HOME
 * @description 로그인되어있는지 체크이후 대시보드로 이동
 */
import React, {useEffect} from 'react'
import {useRoutes} from 'lib'
import {MENU} from 'config'

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  //*--------------------------------------------------*
  // 라우팅
  async function onRoutes() {
    navigate(MENU.DASHBOARD)
    // navigate(MENU.SIGN_IN)
    return true
  }
  //*--------------------------------------------------*
  useEffect(() => {
    async function onInit() {
      await onRoutes()
    }
    onInit()
    return () => {}
  }, [])

  return <React.Fragment />
}
/*
1그룹 : Bank
2그룹 : 설비
3그룹 : 장비
4그룹 : 모듈
 */
