/**
 * @name Header
 * @description
 */
import styled from 'styled-components'
import {acelo_logo} from 'assets/images'
import {COLOR} from 'styles'

export default function Contents() {
  //*--------------------------------------------------*
  return (
    <Content>
      {process.env.REACT_APP_MODE === 'STAGING' && (
        <div className="dev">
          <h1>HEY</h1>
        </div>
      )}
      <img src={acelo_logo} style={{width: 168, height: 40}} alt="아셀로로고" />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  position: relative;
  margin-bottom: 24px;
  /*---개발모드-------------*/
  .dev {
    position: absolute;
    left: -34px;
    top: 0px;
    rotate: -58deg;
    font-size: 24px;
    font-weight: bold;
    color: ${COLOR.blue20};
  }
`
