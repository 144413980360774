/**
 * @name 값(단위)
 * @description
 */
import {CountUp, Flex, Text, randomNumber} from 'lib'
import {COLOR} from 'styles'
import styled from 'styled-components'

type Props = {
  title?: string
  style?: React.CSSProperties
  value?: string
  unit?: string
  color?: string
  colorTitle?: string
  colorUnit?: string
}
export default function Contents({value, title, style, unit, color, colorTitle, colorUnit}: Props) {
  // hooks

  //*--------------------------------------------------*
  return (
    <Content>
      <Flex alignitems="flex-start" flexdirection="column" style={style}>
        {title && <Text mode="Title_MB" color={colorTitle || COLOR.gray50} label={title} />}
        <Flex alignitems="flex-end">
          <Text mode="Headline_LB" color={color || COLOR.gray10} style={{marginRight: 4}}>
            <CountUp from={Number(value) - randomNumber(100, 200)} to={Number(value)} />
          </Text>
          <Text mode="Caption_LB" color={colorUnit || COLOR.gray50} label={unit || 'kVA'} />
        </Flex>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /*--------------*/
  /* min-width: 150px; */
`
