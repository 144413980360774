/**
 * @name 에너지사용
 * @description
 */
import {CountUp, Flex, Icon, Text, randomNumber} from 'lib'
import styled from 'styled-components'
import {Store} from '../../index'
import {COLOR} from 'styles'
import {use_diagram1} from 'assets/svg'
import {furnace_type_1, furnace_type_2, furnace_type_3, furnace_type_4} from 'assets/svg'

export default function Contents() {
  // hooks
  const {info} = Store()
  const data = Math.floor(Number(info[1]?.data?.remainAmount))
  const setCheck = () => {
    if (data >= 75) return {furnace_type: furnace_type_1, y: 20, color: COLOR.white} //넘침
    else if (data >= 50) return {furnace_type: furnace_type_2, y: 15, color: COLOR.white} //여유
    else if (data >= 25) return {furnace_type: furnace_type_3, y: 35, color: COLOR.yellow30} //충전
    else return {furnace_type: furnace_type_4, y: 30, color: COLOR.red30} //부족
  }
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 변압기01 */}
      <div className="parent">
        {/* 타이틀 */}
        <div className="title">
          <Text mode="Title_LB" label={info[0]?.name + ''} />
        </div>
      </div>
      {/* 용해로 */}
      <div className="child">
        <Flex flexdirection="column" alignitems="flex-start" style={{marginBottom: 5}}>
          <Text mode="Title_LB" label={info[1]?.name} color={'#5F5F5F'} />
          <Text mode="Body_MM" label={info[1]?.instLocation} color={COLOR.GRAY2} />
        </Flex>
        <Flex alignitems="center" flexdirection="row" justifycontent="space-between">
          <div>
            <Icon className="d" src={setCheck().furnace_type} width="114px" height="116px" />
            <div className="txt" style={{bottom: setCheck().y}}>
              <Text mode="Headline_LB" color={setCheck().color} style={{marginRight: 4}}>
                <CountUp point={0} from={Number(data) - randomNumber(10, 20)} to={Number(data)} />
                <span>%</span>
              </Text>
            </div>
          </div>
          <Flex flexdirection="column" className="val" alignitems="flex-end" justifycontent="flex-end">
            <Text mode="Body_SM" color={COLOR.gray50}>
              잔탕량 <Text mode="Body_LM" label={info[1]?.data?.remainAmount} color={COLOR.gray10} /> %
            </Text>
            <Text mode="Body_SM" color={COLOR.gray50}>
              현재 온도 <Text mode="Body_LM" label={info[1]?.data?.currentTemperature} color={COLOR.gray10} />℃
            </Text>
            <Text mode="Body_SM" color={COLOR.gray50}>
              설정 온도 <Text mode="Body_LM" label={info[1]?.data?.settingTemperature} color={COLOR.gray10} />℃
            </Text>
            {/* <Text mode="Body_SM" color={COLOR.gray50}>
              전압
              <Text mode="Body_LM" label="7" color={COLOR.gray10} />V
            </Text> */}
          </Flex>
        </Flex>
      </div>
      {/* {debug(info)} */}
      {/* <Image src={use_img} width={229} height={396} /> */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /**-------본문--*/
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 60px 0;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.gray80};
  /**-------변압기--*/
  .parent {
    width: 338px;
    height: 340px;
    margin: 0 auto;
    background-image: ${() => `url(${use_diagram1})`};
    .title {
      margin-top: 22px;
      margin-left: 170px;
    }
  }
  /**-------용해로--*/
  .child {
    position: absolute;
    top: 200px;
    left: 50%;
    width: 280px;
    height: 200px;
    padding: 16px;
    border-radius: 16px;
    border: 4px solid ${COLOR.red30};
    transform: translateX(-110px);
    background-color: ${COLOR.white};
  }
  .val {
    span span {
      display: inline-block;
      margin: 0 5px;
    }
  }
  /*--------------*/
  .txt {
    position: absolute;
    left: 40px;
    bottom: 10px;
    color: #fff;
  }
`
