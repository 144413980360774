/**
 * @name useUtil
 * @description
 */

export const useUtil = () => {
  // hooks
  const log = (message: string | number | {} | [], style?: string) => {
    if (process.env.NODE_ENV !== 'development') return
    // 호출 스택 정보 가져오기
    const callerStack = new Error().stack
    const _styles = {
      base: ['color: #999', 'font-family:D2coing', 'font-size:14px', 'padding: 0 4px'],
      string: ['padding: 2px 4px', 'color: #111', 'background-color: orange'],
      object: ['padding: 0', 'font-weight:normal', 'color:tomato'],
      number: ['background-color: green'],
    }
    let styles = _styles.base.join(';') + ';'
    console.info(callerStack)
    // console.log(`%c${new Error().stack}`, (styles += _styles.number.join(';') + ';'))
    // 문자열
    if (typeof message === 'string') console.log(`%c${message}`, (styles += _styles.string.join(';') + ';'))
    else if (typeof message === 'number') console.log(`%c${message}`, (styles += _styles.number.join(';') + ';'))
    else if (typeof message === 'object') console.log(`%c${JSON.stringify(message, null, 2)}`, (styles += _styles.object.join(';') + ';'))
    else console.log(`%c${JSON.stringify(message, null, 2)}`, (styles += _styles.base.join(';') + ';'))
  }
  log.red = (message: string) => {
    log(message, 'color: red;')
  }
  log.green = (message: string) => {
    log(message, 'color: green;')
  }
  log.blue = (message: string) => {
    log(message, 'color: blue;')
  }

  const env = process.env.NODE_ENV === 'development' ? 'dev' : 'prod'

  // *-------------------------------------------------------------------*
  return {log, env}
}
/********************************************************
[사용법]



*********************************************************/
