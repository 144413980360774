/**
 * @name 설비(Facility)
 * @description
 */
import React, {useEffect, useState} from 'react'
import {arrow_down, arrow_next, del, edit, plus_green, round_blue} from 'assets/svg'
import {Events, Flex, Icon, Image, ImageButton, Text, uuid} from 'lib'
import {COLOR} from 'styles'
import {FacilityProps} from 'types'
// contents
import Add from './add'
import {Equipment} from '..'
import {_event} from 'pages/equipment_setting'
import {status_error, status_on} from 'assets/images'

type Props = {
  item: FacilityProps
  [key: string]: any
}
export default function Contents({item}: Props) {
  // hooks
  const [visible, setVisible] = useState(false)
  const [isAdd, setIsAdd] = useState(false) //등록유무
  const [isModify, setIsModify] = useState(false) //수정유무
  //*--------------------------------------------------*
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <React.Fragment key={uuid()}>
      <tr key={uuid()} className="facility-item">
        <td>
          <button
            className="toggle"
            onClick={() => {
              setVisible(!visible)
            }}>
            <Icon src={visible ? arrow_down : arrow_next} />
            <Icon src={round_blue} width="14px" height="14px" style={{marginRight: 5}} />
            <Text mode="Body_MM" color={COLOR.gray40}>
              설비
            </Text>
          </button>
        </td>
        {/* 설비명 */}
        <td className="">
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.name} />
        </td>
        {/* 설비상태 */}
        <td>
          {item?.status === 'OK' && <Image src={status_on} style={{width: 48, height: 32, verticalAlign: 'middle'}} />}
          {item?.status === 'ERROR' && <Image src={status_error} style={{width: 37, height: 32, verticalAlign: 'middle'}} />}
          {item?.status === 'UNKNOWN' && <span>-</span>}
          {/* {item?.ok} */}
        </td>
        {/* 설비ID */}
        <td>
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.factoryDeviceId || '-'} />
        </td>
        {/* 설비위치 */}
        <td>
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.instLocation || '-'} />
        </td>
        {/* 최대용량 */}
        <td>
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.maximumCapacity || '-'} />
        </td>
        {/* 정격용량 */}
        <td>
          <Text mode="Body_MM" color={COLOR.gray40} label={item?.ratedCapacity || '-'} />
        </td>
        {/* <td>
          <Flex alignitems="center">
            <ImageButton
              src={edit}
              style={{marginRight: 20}}
              onClick={() => {
                setIsModify(true)
                setIsAdd(true)
              }}
            />
            <ImageButton
              src={del}
              onClick={() => {
                Events.emit(_event.DEVICES_DELETE, item)
              }}
            />
          </Flex>
        </td> */}
        {/* <td className="c">
          <ImageButton
            src={plus_green}
            onClick={() => {
              setIsModify(false)
              setIsAdd(true)
            }}
          />
        </td> */}
      </tr>
      {/* 등록화면 */}
      {isAdd && (
        <tr>
          <td colSpan={16} className="child-node">
            <Add setIsAdd={setIsAdd} modify={isModify} item={item} />
          </td>
        </tr>
      )}
      {visible && item?.children.length > 0 && (
        <tr key={uuid()}>
          <td colSpan={10} className="child-node">
            {/* 장비내용 */}
            <Equipment item={item} />
          </td>
        </tr>
      )}
    </React.Fragment>
  )
}
