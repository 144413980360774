/**
 * @name index.tsx
 */
import ReactDOM from 'react-dom/client'
// redux,utils
import {Provider} from 'react-redux'
import reportWebVitals from './reportWebVitals'
import store from './model'
// styles
import './styles/scss/root.scss'
import './styles/scss/init.scss'
import './styles/scss/font.scss'
import './styles/scss/text.scss'
import './styles/scss/common.scss'
import './styles/scss/ui.scss'
// contents
import App from './pages/app'
import React from 'react'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </Provider>,
)
reportWebVitals()

/*========================================================

  // console.log(); 2번씩 실행방지위하여
  <React.StrictMode>
    <App />
  </React.StrictMode>
=======================================================*/
