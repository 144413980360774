/**
 * @name Location
 */
import styled from 'styled-components'
import {ImageButton} from 'lib'
import {MENU} from 'config'
import {acelo_logo} from 'assets/images'
// contents

export default function GnbTop() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 로고 */}
      <ImageButton
        src={acelo_logo}
        width={84}
        height={20}
        onClick={() => {
          window.location.href = MENU.HOME
        }}
        className="logo_button"
      />
      {/* <Link to={MENU.HOME} className="logo_button">
        <Image src={acelo_logo} width={84} height={20} />
      </Link> */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.header`
  display: flex;
  position: relative;
  margin-left: 26px;
  margin-top: 20px;
  margin-bottom: 30px;
`
