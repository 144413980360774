/**
 * @name Context
 * @description
 */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
/** @initialState */
export const sliceName = 'context' //변경필요
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  user: any
  [key: string]: any
}
//*--------------------------------------------------*
/** @initialState */
const initialState: StateType = {
  user: {},
}
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // 유저조회
    setUser(state: StateType, action: PayloadAction<any>) {
      state.user = Object.assign(state.user, action.payload)
    },
  },
})
//*--------------------------------------------------*
// export
export const EVENT = {
  SUBMIT: 'SUBMIT',
  UPDATE: 'UPDATE',
}
export default slice.reducer
export const nameSpace = sliceName
export const actions = slice.actions
