/**
 * @name Table영역
 * @description
 */
import {Table, uuid} from 'lib'
import React from 'react'
import styled from 'styled-components'
import {ListRenderItem} from 'types'

export default function Contents() {
  // hooks
  const info = [
    {
      col_0: '부족',
      col_1: '용해로',
      col_2: '용해로 내용물의 소비가 많으므로 신속 충전이 필요합니다.',
      col_3: '2023.07.23 / 14:50',
    },
    {
      col_0: '부족',
      col_1: '용해로',
      col_2: '용해로 내용물의 소비가 많으므로 신속 충전이 필요합니다.',
      col_3: '2023.07.23 / 14:50',
    },
    {
      col_0: '부족',
      col_1: '용해로',
      col_2: '용해로 내용물의 소비가 많으므로 신속 충전이 필요합니다.',
      col_3: '2023.07.23 / 14:50',
    },
    {
      col_0: '부족',
      col_1: '용해로',
      col_2: '용해로 내용물의 소비가 많으므로 신속 충전이 필요합니다.',
      col_3: '2023.07.23 / 14:50',
    },
    {
      col_0: '부족',
      col_1: '용해로',
      col_2: '용해로 내용물의 소비가 많으므로 신속 충전이 필요합니다.',
      col_3: '2023.07.23 / 14:50',
    },
    {
      col_0: '부족',
      col_1: '용해로',
      col_2: '용해로 내용물의 소비가 많으므로 신속 충전이 필요합니다.',
      col_3: '2023.07.23 / 14:50',
    },
    {
      col_0: '부족',
      col_1: '용해로',
      col_2: '용해로 내용물의 소비가 많으므로 신속 충전이 필요합니다.',
      col_3: '2023.07.23 / 14:50',
    },
  ]
  //*--------------------------------------------------*
  return (
    <Content className="panel-scroll-height">
      <Table
        info={info}
        renderHeader={() => {
          return (
            <React.Fragment>
              <tr>
                <th className="l" style={{width: 100}}>
                  중요도
                </th>
                <th className="l" style={{width: 100}}>
                  알림유형
                </th>
                <th className="l" style={{width: 'auto'}}>
                  내용
                </th>
                <th className="l" style={{width: 200}}>
                  Date/Time
                </th>
              </tr>
            </React.Fragment>
          )
        }}
        renderItem={({item}: ListRenderItem) => {
          return (
            <tr key={uuid()}>
              <td className="">{item?.col_0}</td>
              <td className="">{item?.col_1}</td>
              <td>{item?.col_2}</td>
              <td>{item?.col_3}</td>
            </tr>
          )
        }}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /**-------본문--*/
  overflow-y: auto;
`
