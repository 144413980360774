/**
 * @name Chart영역
 * @description
 */
import {Table, uuid} from 'lib'
import React from 'react'
import styled from 'styled-components'

export default function Contents() {
  //*--------------------------------------------------*
  return (
    <Content>
      <p>컨텐츠내용</p>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div``
