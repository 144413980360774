/**
 * @name BackButton
 * @mode 라벨설정, 뒤로가기
 */
import styled from 'styled-components'
import {H3, Image, Text, useRoutes} from 'lib'
import {COLOR} from 'styles'

type Props = {
  label?: string //------------------------------ 텍스트라벨링
  desc?: string //------------------------------- 디스크립션
  style?: React.CSSProperties //----------------- StyleSheet
  onChange?: () => void
  [key: string]: any
}
export default function BackButton({onChange, label, desc, style}: Props) {
  // hooks
  const {navigate} = useRoutes()
  //*--------------------------------------------------*
  return (
    <Content
      onClick={() => {
        !!onChange && onChange()
        navigate(-1)
      }}
      style={style}>
      {label && <H3 color={COLOR.BLACK3}>{label}</H3>}
      {desc && (
        <Text className={'body6'} color={COLOR.BLACK3} style={{marginLeft: 5}}>
          {desc}
        </Text>
      )}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.button`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 0;
`
/********************************************************
사용법

<BackButton label={view?.data?.name} />

*********************************************************/
