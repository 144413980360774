/**
 * @name 공통가이드
 * @description
 */
import styled from 'styled-components'
import {App} from 'template'
import {Panel} from 'components'
import {LoadingAnimation} from 'lib'
// contents
import {Top, Header} from './common'
import {Table, Chart} from './contents'

type Props = {
  isChart: boolean
  isData: boolean
}
export default function Presenter({isChart, isData}: Props) {
  return (
    <App>
      <Content className="container-height">
        <Panel padding="24px" header={<Header />}>
          {/* Top영역 */}
          <Top />
          {/* 차트영역 */}
          {isChart ? (
            <Chart />
          ) : (
            <div className="loading">
              <LoadingAnimation />
            </div>
          )}
          {/* 테이블영역 */}
          {isData ? (
            <Table />
          ) : (
            <div className="loading">
              <LoadingAnimation />
            </div>
          )}
        </Panel>
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /*--------------*/
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 480px;
  }
`
