/**
 * @name Bank추가
 * @description
 */
import {Events, Icon, Text, useContext} from 'lib'
import {useEffect} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents
import {plus_white} from 'assets/svg'
import {_event} from 'pages/equipment_setting'

export default function Contents() {
  // hooks
  const {factoryId} = useContext()

  //*--------------------------------------------------*
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <Content>
      {/* <button
        className="add"
        onClick={() => {
          const result = {
            factoryId: factoryId,
            factoryDeviceType: 'bank',
          }
          Events.emit(_event.DEVICES_ADD, result)
        }}>
        <Icon src={plus_white} />
        <Text mode="Btn_M" label="Bank 추가" color={COLOR.gray30} style={{marginLeft: 8}} />
      </button> */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  button.add {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      text-decoration: underline;
    }
  }
`
