/**
 * @name Container
 */
import {useEffect} from 'react'
import {useRoutes} from 'lib'
import {MENU} from 'config'
import {signOut} from 'pages/common'
// contents
import Presenter from './presenter'

export default function Container() {
  // hooks
  const {navigate} = useRoutes()
  //*--------------------------------------------------*
  // 로그아웃,초기화
  async function onLogout() {
    // ! 2번씩 호출되는 문제
    await signOut()
    navigate(MENU.SIGN_IN)
  }
  //*--------------------------------------------------*
  useEffect(() => {
    onLogout()
  }, [])
  return <Presenter />
}
