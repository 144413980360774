/**
 * @name Input
 * @description 입력창
 */
import React from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {ClassName} from 'types'

type Props = {
  name?: string //-------------------------------- Element이름
  className?: ClassName //------------------------ ClassName
  style?: React.CSSProperties //------------------ StyleSheet
  placeholder?: string //------------------------- placeholder
  width?: string //------------------------------- width
  height?: string //------------------------------ height
  padding?: string //----------------------------- Padding
  border?: string //------------------------------ border
  defaultValue?: string //------------------------ 초기값세팅 이후랜더링안됨
  value?: string //------------------------------- 랜더링됨
  disabled?: boolean //--------------------------- disabled
  type?: string //-------------------------------- type
  borderradius?: string
  onSubmit?: (str: string) => void //------------- onSubmit
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void //------------- onChange
  onUpdate?: ({event, name, value}: {event?: React.ChangeEvent<HTMLInputElement>; name: string; value: string}) => any
}

export default function Input({name, className, style, width, height, type, border, padding, value, defaultValue, disabled, borderradius, placeholder, onChange, onUpdate, onSubmit}: Props) {
  return (
    <Element
      name={name}
      type={type}
      className={className}
      style={style}
      placeholder={placeholder}
      width={width}
      height={height}
      border={border}
      padding={padding}
      disabled={disabled}
      borderradius={borderradius}
      defaultValue={defaultValue}
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value
        !!onChange && onChange(event)
        !!onUpdate && onUpdate({event, name, value})
      }}
      onKeyUp={(event: any) => {
        // ENTER키적용
        if (event.key === 'Enter') {
          !!onSubmit && onSubmit(event.currentTarget.value)
        }
      }}
    />
  )
}
//*--------------------------------------------------*
const Element = styled.input<Props>`
  width: ${({width}) => width || '100%'};
  height: ${({height}) => height || '40px'};
  padding: ${({padding}) => padding || '0 16px'};
  border: ${({border}) => border || '0 none'};
  border-radius: ${({borderradius}) => borderradius || '8px'};
  outline: 0;
  /* 마우스호버 */
  &:hover {
    border-color: ${COLOR.GRAY1};
  }
  /* 포커스인 */
  &:focus {
    border-color: ${COLOR.BLUE3};
    outline: 0;
    outline-color: ${COLOR.BLUE3};
  }
  /* 비활성화 */
  &:disabled {
    color: ${COLOR.GRAY3};
    background-color: ${COLOR.GRAY6};
  }
  /* 유효성검사 */
  &.invalid {
    border-color: ${COLOR.RED1} !important;
  }
  /* 플레이스홀더 */
  &::placeholder {
    color: ${COLOR.GRAY2};
  }

  /* input클래스적용시 기본스타일작동 */
  &.input {
    display: flex;
    border: 1px solid ${COLOR.GRAY4};
    outline: 0;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.1px;
  }
`
/********************************************************
[사용법]
  const [str, setStr] = useState('origin')
 <Input
    value={str ||''}
    name="name"
    className="my-input"
    style={{marginTop: 20}}
    border={`1px solid ${COLOR.GRAY4}`}
    borderradius={'8px'}
    width={'100%'}
    height={'40px'}
    placeholder="이름을 입력해주세요."
    onSubmit={str => {
      setStr(str)
    }}
    onChange={event => {
      console.log(event)
    }}
    onUpdate={({event, name, value}) => {
      console.log([event, name, value])
    }}
  />
  {debug(str)}
  <Button
    style={{marginTop: 20}}
    full={true}
    label="input 값 change"
    onClick={() => {
      setStr('change')
    }}
  />

//! value에 undefined가 나오지않게 ||'' 처리
*********************************************************/
