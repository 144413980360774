/**
 * @name ACELO_Solution
 * @description
 */
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents
import {TitleValueUnit} from '../../../common'

type Props = {
  info?: any
}
export default function Contents({info}: Props) {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 적용전 */}
      <TitleValueUnit
        title="부하 측"
        value={info?.load?.data}
        unit={info?.load?.unit}
        style={{width: 150, textAlign: 'right'}}
        color={COLOR.blue30}
        colorTitle={COLOR.blue50}
        colorUnit={COLOR.blue50}
      />
      <div style={{width: 24}} />
      <TitleValueUnit title="전원 측" value={info?.source?.data} unit={info?.source?.unit} style={{width: 150, textAlign: 'right'}} />
      {/* 적용후 */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /*--------------*/
`
