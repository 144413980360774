/**
 * @name 푸터
 * @description
 */
import {useState} from 'react'
import {Events, Flex, Icon, Image, ImageButton, Text} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {Store, _event} from 'pages/dashboard'
import {dashboard_tooltip, exclamation_gray, exclamation_on} from 'assets/svg'
// contents

export default function Contents() {
  // hooks
  const {refresh_time} = Store()
  const [isHovered, setIsHovered] = useState(false) //---- hover 여부 상태 추가

  //*--------------------------------------------------*
  return (
    <Content>
      <Flex className="control" alignitems="center" justifycontent="flex-end">
        <button
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => {
            Events.emit(_event.DASHBOARD_REFRESH)
          }}>
          <Flex alignitems="center">
            <Icon src={isHovered ? exclamation_on : exclamation_gray} width={'22px'} height={'22px'} style={{marginRight: 4}} />
            <Text mode="Title_MB" label={`${refresh_time} 기준`} color={COLOR.gray60} />
          </Flex>
          {isHovered && (
            <aside className="description">
              <Image src={dashboard_tooltip} />
            </aside>
          )}
        </button>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: block;
  position: absolute;
  z-index: 1;
  right: 24px;
  bottom: 24px;
  /* margin-right: 24px; */
  /*--------------*/
  .description {
    position: absolute;
    background-color: ${COLOR.gray90};
    /* top: 71px; */
    left: -431px;
    top: -35px;
  }
  button {
    &:hover span {
      cursor: pointer;
      color: ${COLOR.gray50};
      /* text-decoration: underline; */
    }
  }
`
