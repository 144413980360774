/**
 * @name useContext
 * @description 글로벌공통으로 사용할수있는 변수,상수,함수를 관리합니다.
 */

import {useAppDispatch, useAppSelector} from 'model'
import {useNavigate} from 'react-router-dom'
import {useLocation, useParams} from 'react-router'
import queryString from 'query-string'

export const useContext = () => {
  // hooks
  const {user} = useAppSelector(state => state.context)
  const {login} = useAppSelector(state => state.auth)
  const {location, sitemap, navi, menu, isExpand} = useAppSelector(state => state.gnb)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {state} = useLocation() as any // navigate(MENU.TEST, {state: payload}) 사용
  const params = useParams() as any // 라우팅할때 '/:id' 이런식으로 사용
  const factoryId = user?.factoryId
  const factoryType = () => {
    if (!user?.factoryType) return ''
    const temp = user?.factoryType.toString()
    if (temp.includes('solar')) return 'solar'
    else if (temp.includes('furnace')) return 'furnace'
    return ''
  } // solar,furnace
  const factoryName = user?.factoryName
  const userId = user?.userId

  const availableMenu = user?.properties?.availableMenu || []
  const query = queryString.parse(window.location.search) // http://192.168.1.45:3000/?intive=code&type=email
  // const
  const env = process.env.NODE_ENV === 'development' ? 'dev' : 'prod'
  // *-------------------------------------------------------------------*
  return {query, userId, isExpand, availableMenu, menu, user, factoryName, factoryType, factoryId, login, env, navi, navigate, dispatch, state, params, sitemap, location}
}
/********************************************************
[사용법]
const {lang}=useContext()
const {state} = useContext()
const {params} = useContext()
const {query} = useContext() // queryString


*********************************************************/
