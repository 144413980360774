/**
 * @name 공통가이드
 * @description
 */
import styled from 'styled-components'
import {App} from 'template'
import {Panel} from 'components'
// contents
import {Header} from './common'
import {Chart, Table} from './contents'

export default function Presenter() {
  return (
    <App>
      <Content className="container-height">
        <Panel padding="0 24px 0" header={<Header />}>
          {/* 차트영역 */}
          <Chart />
          {/* 테이블영역 */}
          <Table />
          {/* <Widget /> */}
        </Panel>
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /*--------------*/
`
