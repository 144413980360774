/**
 * @name 현재수집되는데이터가없습니다.
 * @description
 */
import {Image} from 'lib'
import styled from 'styled-components'
import {dashboard_empty} from 'assets/images'

export default function Contents() {
  //*--------------------------------------------------*
  return (
    <Content>
      <Image src={dashboard_empty} width={203} height={88} />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /*--------------*/
  margin-top: 100px;
`
