/**
 * @name 위젯
 * @description
 */
import {Flex, Loop, Text} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents
import {PrefixValueUnit} from 'pages/dashboard/common'

type Props = {
  info?: any
}
export default function Contents({info}: Props) {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      {/* {debug(info)} */}
      {/* 전압 */}
      <Flex className="wrap" alignitems="center" flexdirection="column" justifycontent="center">
        {/* 타이틀 */}
        <Flex alignitems="center" justifycontent="flex-end">
          <Text mode="Title_LB" color={COLOR.gray50} label="전압" />
          <Text mode="Title_MB" color={COLOR.gray50} style={{marginLeft: 4}}>
            (<span style={{fontSize: 20}}>V</span>THD)
          </Text>
        </Flex>
        {/* 전압 */}
        <Loop
          info={info?.V_THD}
          renderItem={({item}) => {
            return <PrefixValueUnit minWidth={70} prefix={item?.name} unit={item?.unit} value={item?.data} />
          }}
        />
      </Flex>
      {/* 전류 */}
      <Flex className="wrap" alignitems="center" flexdirection="column" justifycontent="center">
        {/* 타이틀 */}
        <Flex alignitems="center" justifycontent="flex-end">
          <Text mode="Title_LB" color={COLOR.gray50} label="전류" />
          <Text mode="Title_MB" color={COLOR.gray50} style={{marginLeft: 4}}>
            (<span style={{fontSize: 20}}>I</span>TDD)
          </Text>
        </Flex>
        {/* 전류 */}
        <Loop
          info={info?.I_THD}
          renderItem={({item}) => {
            return <PrefixValueUnit minWidth={70} prefix={item?.name} unit={item?.unit} value={item?.data} />
          }}
        />
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /*--------------*/
  .wrap {
    margin: 10px 20px 0;
  }
`
