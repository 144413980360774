/**
 * @name Util
 */
import {gnb_util_logout} from 'assets/images'
import {gnb_equipment_setting, gnb_equipment_setting_on} from 'assets/svg'
import {MENU} from 'config'
import {Icon, useContext, useRoutes} from 'lib'
import {useAppDispatch, useAppSelector} from 'model'
import {gnbAction} from 'model/modules'
import styled from 'styled-components'
import {COLOR} from 'styles'

export default function UtilMenu() {
  // hooks
  const {navi} = useAppSelector(state => state.gnb)
  const {availableMenu} = useContext()
  const {navigate} = useRoutes()
  const dispatch = useAppDispatch()

  // 장비세팅메뉴보여짐체크
  const filter = (!!availableMenu && availableMenu?.filter((item: any) => item.menu === 'EQUIPMENT_SETTING')[0]) || null
  //*--------------------------------------------------*
  return (
    <Content>
      {/* {debug(navi)} */}
      {/* 로고 */}
      {!!filter && filter?.available === true && (
        <button
          className={`btn ${navi?.depth1 === 900 && 'active'}`}
          onClick={() => {
            dispatch(gnbAction.setNavi({depth1: 900, depth2: -1}))
            navigate(MENU.EQUIPMENT_SETTING)
          }}>
          <Icon width={'40px'} height={'40px'} src={navi?.depth1 === 900 ? gnb_equipment_setting_on : gnb_equipment_setting} />
          <span>장치 현황</span>
        </button>
      )}
      <button
        className="btn"
        onClick={() => {
          navigate(MENU.SIGN_OUT)
        }}>
        <Icon width={'40px'} height={'40px'} src={gnb_util_logout} style={{marginLeft: 4}} />
        <span>로그아웃</span>
      </button>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 18px;
  /* 버튼 */
  button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    /* margin-right: 18px; */

    text-align: left;
    color: ${COLOR.gray40};
    font-size: 14px;
    padding-right: 15px;
    border-radius: 8px;
    &:hover {
      color: ${COLOR.gray10};
    }
    span {
      display: inline-block;
      margin-left: 8px;
    }

    /* 활성화 */
    &.active {
      background-color: ${COLOR.blue90};
      figure {
        /* background-image: ${({iconhover}) => `url(${iconhover})`}; */
      }
      label {
        color: ${COLOR.blue30};
      }
    }
  }
`
