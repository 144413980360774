/**
 * @name Container
 */
import {useEffect} from 'react'
import {Dayjs, Events, useAxios} from 'lib'
import {useAppDispatch} from 'model'
import {_action, _event} from '.'
import {API} from 'config'
// contents
import Presenter from './presenter'
import {gnbAction} from 'model/modules'

export default function Container() {
  // hooks
  const dispatch = useAppDispatch()
  const {get} = useAxios()
  //*--------------------------------------------------*
  // onInit
  async function onInit() {
    const res = await get(`${API}/card/all`)
    if (res?.success) {
      dispatch(_action.setCardAll(res?.data))
      onRefresh()
      dispatch(gnbAction.setNavi({depth1: 0, depth2: -1}))
    } else {
      !!res?.error?.message && alert(res?.error?.message)
      console.log(`[오류] ${API}/card/all`)
    }
  }
  // onRefresh
  async function onRefresh() {
    dispatch(_action.setRefreshTime(Dayjs().format('YYYY.MM.DD HH:mm')))
  }
  //*--------------------------------------------------*
  useEffect(() => {
    onInit()
    // addListener
    Events.addListener(_event.DASHBOARD_REFRESH, onRefresh)
    return () => {
      // removeListener
      Events.removeListener(_event.DASHBOARD_REFRESH, onRefresh)
    }
  }, [])
  //*--------------------------------------------------*
  return <Presenter />
}
