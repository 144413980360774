/**
 * @name 장비(Equipment)
 * @description
 */
import {Table, uuid} from 'lib'
import React, {useEffect} from 'react'
import styled from 'styled-components'
import {EquipmentProps, ListRenderItem} from 'types'
// contents
import Item from './item'

type Props = {
  item: EquipmentProps
}
export default function Contents({item}: Props) {
  const total = item?.children?.length || 0
  //*--------------------------------------------------*
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <Table
        info={item?.children}
        renderHeader={() => {
          return (
            <tr key={uuid()}>
              <th className="cell-line" />
              <th className="cell-line" />
              <th>구분</th>
              <th>장비명</th>
              <th>장비 상태</th>
              <th>모델명</th>
              <th>제품번호 (S/N)</th>
              <th>부착위치</th>
              <th>최대용량</th>
              <th>정격용량</th>
              {/* <th>IP</th>
              <th>Port</th>
              <th>MAC</th>
              <th>삭제</th> */}
            </tr>
          )
        }}
        renderItem={({item, index}: ListRenderItem) => <Item total={total} item={item} index={index} />}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: block;
  table {
    tr:nth-last-child() {
      background-color: red;
      td {
        border-bottom: 0;
      }
    }
  }
`
