/**
 * @name 위젯
 * @description
 */
import {Flex, Text} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents
import ChartDemand from './chart-demand'
import ChartVoltage from './chart-voltage'
import {PrefixValueUnit, TitleValueUnit} from 'pages/dashboard/common'

type Props = {
  info?: any
}
export default function Contents({info}: Props) {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 수요 */}
      <Flex flexdirection="column" className="wrap" alignitems="flex-end">
        <div className="in_wrap">
          <Text mode="Title_MB" color={COLOR.gray50} label="수요" />
          <TitleValueUnit value={info?.pa?.['S total']?.data} unit={info?.pa?.['S total']?.unit} style={{width: 150, textAlign: 'right'}} />
          <PrefixValueUnit value={info?.pa?.['PF avg']?.data} prefix={info?.pa?.['PF avg']?.name} unit={info?.pa?.['PF avg']?.unit} />
        </div>
        {/* 차트 */}
        <div className="chart">
          <ChartDemand chart={info?.pa?.chart} />
        </div>
      </Flex>
      {/* 전압 */}
      <Flex flexdirection="column" className="wrap" alignitems="flex-end">
        <div className="in_wrap">
          <Text mode="Title_MB" color={COLOR.gray50} label="전압" />
          <TitleValueUnit value={info?.vol?.['Vll avg']?.data} unit={info?.vol?.['Vll avg']?.unit} style={{textAlign: 'right'}} />
          <PrefixValueUnit value={info?.vol?.['min']?.data} prefix={'최소'} unit={info?.vol?.['min']?.unit} />
          <PrefixValueUnit value={info?.vol?.['max']?.data} prefix={'최대'} unit={info?.vol?.['max']?.unit} />
        </div>
        {/* 차트 */}
        <div className="chart">
          <ChartVoltage chart={info?.vol?.chart} />
        </div>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 20px 0;
  align-items: flex-start;
  justify-content: space-between;

  /*--------------*/
  > .wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    /* background-color: red; */
    .in_wrap {
      display: flex;
      flex-direction: column;
      height: 120px;
      margin-left: auto;
      margin-right: auto;
    }
    .chart {
      display: flex;
      flex: 1;
      width: 90%;
      height: 150px;
      margin: 50px auto 0;
    }
  }
`
