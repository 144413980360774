/**
 * @name 전압,전류
 * @description
 */
import {CountUp, Text, randomNumber} from 'lib'
import {COLOR} from 'styles'
import styled from 'styled-components'

type Props = {
  prefix?: string
  value?: string
  unit?: string
  style?: React.CSSProperties
  color?: string
  point?: number
  minWidth?: number | 'auto'
}
export default function Contents({value, prefix, style, unit, minWidth, color, point}: Props) {
  // hooks

  //*--------------------------------------------------*
  return (
    <Content style={style}>
      <Text mode="Title_MB" color={COLOR.gray50}>
        {prefix}
        <Text style={{marginLeft: 4, marginRight: 4}} mode="Headline_MB" color={color || COLOR.gray10}>
          <CountUp point={point} style={{minWidth: minWidth || 88}} from={Number(value) - randomNumber(-5, 5)} to={Number(value)} />
        </Text>
        {unit}
      </Text>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /*--------------*/
  /* min-width: 140px; */
  .value {
    display: inline-block;
  }
`
