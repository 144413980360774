/**
 * @name TextFiledSearch
 * @description
 */
import {useEffect, useState} from 'react'
import {ImageButton, Input} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {CSS} from 'types'

type Props = {
  containerStyle?: CSS
  style?: CSS
  placeholder?: string
  className?: string
  defaultValue?: string
  disabled?: boolean
  onSubmit?: (str: string) => void
  onChange?: (str: string) => void
  onClick?: () => void
}
export default function TextFiledSearch({className, disabled, defaultValue, containerStyle, style, placeholder, onSubmit, onChange, onClick}: Props) {
  // hooks
  const [search, setSearch] = useState(defaultValue ?? '')
  //*--------------------------------------------------*
  useEffect(() => {
    !!defaultValue && setSearch(defaultValue)
  }, [defaultValue])
  //*--------------------------------------------------*
  return (
    <Content
      style={containerStyle}
      className={className}
      onClick={() => {
        !!onClick && onClick()
      }}>
      <Input
        disabled={disabled}
        defaultValue={search}
        style={style}
        placeholder={placeholder ?? '검색어를 입력 해주세요.'}
        onChange={str => {
          // !!onChange && onChange(str)
        }}
        onSubmit={() => {
          !!onSubmit && onSubmit(search)
        }}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  position: relative;
  /* 검색버튼 */
  .button {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  /* input */
  input {
    display: flex;
    flex: 1;
    width: 100%;
    min-width: 300px;
    padding: 0 40px 0 12px;
    height: 40px;
    font-family: 'Spoqa Han Sans Neo';
    border: 1px solid ${COLOR.GRAY4};
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: ${COLOR.BLACK1};
    background-color: ${COLOR.WHITE};
    outline: none;
    overflow: hidden;
    /* 마우스호버 */
    &:hover {
      border-color: ${COLOR.GRAY1};
    }
    /* 포커스인 */
    &:focus {
      outline: 0;
      border-color: ${COLOR.BLUE3};
      outline-color: ${COLOR.BLUE3};
    }
    /* 비활성화 */
    &:disabled {
      border: 0;
      color: ${COLOR.GRAY3};
      background-color: ${COLOR.GRAY6};
    }
    /* 유효성검사 */
    &.invalid {
      border-color: ${COLOR.RED1} !important;
    }
    /* 플레이스홀더 */
    &::placeholder {
      color: ${COLOR.GRAY2};
    }
  }
`
/********************************************************
[사용법]
 
*********************************************************/
