/**
 * @name Footer
 * @description
 */
import styled from 'styled-components'

export default function Contents() {
  //*--------------------------------------------------*
  return (
    <Content>
      <p>로그인에 문제가 있다면 sam2kim@crocusenergy.com 로 문의 주세요.</p>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  margin-bottom: 24px;
  p {
    color: #595959;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.47px;
  }
`
