/**
 * @name Container
 */
import {useEffect} from 'react'
import {Events, useAxios} from 'lib'
import {API} from 'config'
import {useAppDispatch} from 'model'
// contents
import {_action, _event} from '.'
import Presenter from './presenter'

export default function Container() {
  // hooks
  const {get, post, put, del} = useAxios()
  const dispatch = useAppDispatch()
  //*--------------------------------------------------*
  async function onInit() {
    // 지원가능 디바이스 전체조회
    const devices = await get(`${API}/devices`)
    if (devices?.success) {
      dispatch(_action.setDevices(devices?.data))
    }
    getDevices()
  }
  // [GET] 디바이스조회
  async function getDevices() {
    const res = await get(`${API}/factory/devices`)
    if (res?.success) {
      dispatch(_action.setInfo(res?.data))
    }
  }
  // [POST] 디바이스입력
  async function onPost(payload: any) {
    console.log(payload)
    const res = await post(`${API}/factory/devices`, payload)
    console.log(res)
    if (res?.success) {
      alert('등록 되었습니다.')
      onInit()
    }
  }
  // [PUT] 디바이스업데이트
  async function onPut(payload: any) {
    const factoryDeviceNo = payload?.factoryDeviceNo
    console.log(factoryDeviceNo)
    const res = await put(`${API}/factory/devices/${factoryDeviceNo}`, payload)
    console.log(res)
    if (res?.success) {
      alert('업데이트 되었습니다.')
      onInit()
    }
  }
  // [DEL] 디바이스삭제
  async function onDelete(data: any) {
    const factoryDeviceNo = data?.factoryDeviceNo
    if (!factoryDeviceNo) {
      console.log('onDelete Error')
      return
    }
    const res = await del(`${API}/factory/devices/${factoryDeviceNo}`)
    if (res?.success === true) {
      alert('삭제 되었습니다.')
      onInit()
    } else {
      alert('삭제 실패했습니다.\n설비,장비,모듈 확인해 주세요.')
    }
  }
  //*--------------------------------------------------*
  useEffect(() => {
    onInit()
    // addListener
    Events.addListener(_event.DEVICES_UPDATE, onPut)
    Events.addListener(_event.DEVICES_DELETE, onDelete)
    Events.addListener(_event.DEVICES_ADD, onPost)
    return () => {
      // removeListener
      Events.removeListener(_event.DEVICES_UPDATE, onPut)
      Events.removeListener(_event.DEVICES_DELETE, onDelete)
      Events.removeListener(_event.DEVICES_ADD, onPost)
    }
  }, [])
  return <Presenter />
}
