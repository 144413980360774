/**
 * @name SignOut
 * @description
 */
import React from 'react'
import styled from 'styled-components'
// contents

export default function Presenter() {
  // hooks

  //*--------------------------------------------------*
  return <Content>ㄹㅇㄴㄹㅇㄴㄹㅇㄴ</Content>
}
//*--------------------------------------------------*
const Content = styled.div``
