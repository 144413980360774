/**
 * @name 헤더
 * @description
 */
import {useEffect, useState} from 'react'
import {Events} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {_event} from 'pages/dashboard'
// contents

export default function Contents() {
  // hooks
  const reloadTime = 60 // 60초
  const [barWidth, setBarWidth] = useState(0)
  //*--------------------------------------------------*
  // Refresh
  const onRefresh = () => {
    setBarWidth(0) // 완료된 후 barWidth를 0으로 초기화
  }
  //*--------------------------------------------------*
  useEffect(() => {
    const increment = 100 / reloadTime // 매 초마다 증가하는 비율
    const interval = setInterval(() => {
      setBarWidth(prevWidth => {
        if (prevWidth >= 100) {
          Events.emit(_event.DASHBOARD_REFRESH)
          return 0
        }
        if (prevWidth + increment > 100) {
          return 100
        }
        return prevWidth + increment
      })
    }, 1000)
    Events.addListener(_event.DASHBOARD_REFRESH, onRefresh)
    return () => {
      clearInterval(interval)
      Events.removeListener(_event.DASHBOARD_REFRESH, onRefresh)
    }
  }, [])
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 상태리로드 */}
      <header>
        <div className="bar" style={{width: `${barWidth}%`}} />
      </header>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 100%;
  height: 28px;
  /*--------------*/
  header {
    display: block;
    width: 100%;
    height: 4px;
    background-color: ${COLOR.blue90};
    .bar {
      display: block;
      width: 0%; // 초기값을 0%로 설정
      height: 4px;
      background-color: ${COLOR.blue80};
      transition: width 0.7s ease-in-out; // transition 추가
    }
  }
  /*--------------*/
  .control {
    height: 68px;
    margin-right: 24px;
  }
  /* margin-bottom: 24px; */
`
