/**
 * @name useRoutes
 * @description hooks기반 라우팅정보
 */

// import {useRouter} from 'next/router'
import {useNavigate} from 'react-router-dom'

export const useRoutes = () => {
  // hooks
  const navigate = useNavigate()
  // *-------------------------------------------------------------------*
  return {navigate}
}
/********************************************************

navigate(path, {state: {title: 'test', num: 0}})
navigate(-1);

*********************************************************/
