/**
 * @name 위젯
 * @description
 */
import {useEffect, useRef, useState} from 'react'
import * as echarts from 'echarts'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {PrefixValueUnit} from 'pages/dashboard/common'
import {useEventListener} from 'usehooks-ts'
import {Flex} from 'lib'

// contents
type Props = {
  info?: any
}
export default function Contents({info}: Props) {
  // hooks
  const [chartElement, setChartElement] = useState<echarts.ECharts>()
  const chartRef = useRef(null)
  const option = {
    tooltip: {
      trigger: 'axis',
    },
    // 색상추가
    color: [COLOR.blue30, COLOR.green30],
    legend: {
      bottom: 0,
      data: [info?.chart?.y[0].name, info?.chart?.y[1].name],
    },
    grid: {
      top: '10px',
      left: '20px',
      right: '20px',
      bottom: '30px',
      containLabel: true,
    },
  }
  const [options, setOptions] = useState(Object.assign({}, option, {})) as any
  const handleSize = () => {
    !!chartElement && chartElement?.resize()
  }
  useEventListener('resize', handleSize)

  //*--------------------------------------------------*
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current)
      setChartElement(chart)
      chart.setOption(options)
      const handleClick = (params: any) => {}
      chart.on('click', handleClick)
    }
    return () => {}
  }, [options, chartRef, info])
  useEffect(() => {
    setOptions(
      Object.assign({}, options, {
        xAxis: {
          type: 'category',
          name: '',
          boundaryGap: true,
          data: info?.chart?.x,
        },
        yAxis: {
          splitNumber: 2,
          scale: true,
          type: 'value',
          name: 'k',
          nameLocation: 'end',
          nameTextStyle: {
            color: '#373737',
            padding: [0, 40, 10, 0],
          },
          axisLine: {
            lineStyle: {
              color: '#373737',
            },
          },
        },
        series: info?.chart?.y,
      }),
    )
    return () => {}
  }, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex alignitems="center" justifycontent="space-evenly">
        <PrefixValueUnit style={{textAlign: 'center'}} value={info?.predictionAccuracy?.data} minWidth={60} prefix={info?.predictionAccuracy?.name} unit={info?.predictionAccuracy?.unit} />
        <div
          ref={chartRef}
          style={{
            width: '100%',
            height: '140px',
            marginTop: 20,
            // marginLeft: 'auto',
            // marginRight: 'auto',
          }}
        />
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 100%;
  padding: 0 20px;
  /*--------------*/
`
