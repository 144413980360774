/**
 * @name Container
 */
import {useEffect} from 'react'
import {useAppDispatch} from 'model'
import {Events, useRoutes} from 'lib'
import {MENU} from 'config'
import {_action, _event} from '.'
// contents
import Presenter from './presenter'
import {signIn} from 'pages/common'

export default function Container() {
  // hooks
  const dispatch = useAppDispatch()
  const {navigate} = useRoutes()
  //*--------------------------------------------------*
  // 로그인
  async function onSignIn(payload: any) {
    if (payload?.userId === '' || payload?.password === '') {
      alert('아이디와 패스워드를 입력해주세요.')
      return
    }
    const res = await signIn(Object.assign({}, {data: payload, dispatch: dispatch, action: _action}))
    if (res?.success) {
      navigate(MENU.DASHBOARD)
    } else {
      dispatch(_action.setSubmitError(true))
    }
  }
  //*--------------------------------------------------*
  useEffect(() => {
    // 초기화
    Events.addListener(_event.LOGIN_SUBMIT, onSignIn)
    return () => {
      Events.removeListener(_event.LOGIN_SUBMIT, onSignIn)
    }
  }, [])

  return <Presenter />
}
