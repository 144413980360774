/**
 * @name API관련
 * @description
 */
import {DSME, KCPR, KHCT, KOEM, KRPO, RMKR, SEAM, SSNQ} from 'assets/images'
import {API} from 'config'
import {SESSION_STORAGE, ajax, log} from 'lib'
import {contextAction} from 'model/modules'

/**
 * @name 로그인실행(모듈화)
 * @description
 */
export function logoImage(type: string) {
  if (type === 'DSME') return DSME
  if (type === 'KCPR') return KCPR
  if (type === 'KHCT') return KHCT
  if (type === 'KOEM') return KOEM
  if (type === 'KOEM1') return KOEM
  if (type === 'KOEM2') return KOEM
  if (type === 'KRPO') return KRPO
  if (type === 'RMKR') return RMKR
  if (type === 'SEAM') return SEAM
  if (type === 'SSNQ') return SSNQ
  return null
}

/**
 * @name 로그인실행(모듈화)
 * @description
 */
export async function signIn(payload: any) {
  const login = await ajax(`${API}/auth/login`, {method: 'POST', data: payload?.data})
  if (!login?.success || login === undefined) {
    alert(JSON.stringify(login?.error?.message))
    await signOut()
    log(JSON.stringify(login, null, 1), `font-size:12px;color:orange;`)
    return {status: 'fail', message: login?.message || '로그인실패'}
  }
  await signInComplete(Object.assign({}, {...payload?.data, ...login?.data}))
  log(`/auth/login`)
  log(login?.data?.accessToken, `font-size:12px;color:Teal;`)
  const me = await ajax(`${API}/users/me`, {method: 'GET'})
  if (me?.success) {
    log(`/users/me`)
    payload.dispatch(contextAction.setUser(me?.data))
  } else {
    return {status: 'fail', message: me?.message || '유저정보실패'}
  }
  log(`*---------------------------*`, `font-size:12px;color:SlateBlue;`)
  return login
}

/**
 * @name 로그인성공이후로직
 * @description 세션스토리지에 로그인정보를 저장한다.
 */
export async function signInComplete(payload: any) {
  console.log(`%csessionStorage settings`, `display:block;width:100%;padding:2px 5px;font-size:12px;background-color:tomato;color:black;`)
  // 세션스토리지설정
  await sessionStorage.setItem(SESSION_STORAGE.USER_ID, payload?.userId)
  await sessionStorage.setItem(SESSION_STORAGE.PASSWORD, payload?.password)
  await sessionStorage.setItem(SESSION_STORAGE.FACTORY_ID, payload?.factoryId)
  !!payload?.accessToken && (await sessionStorage.setItem(SESSION_STORAGE.TOKEN, payload?.accessToken))
  return true
}
/**
 * @name 로그아웃성공이후로직
 * @description 세션스토리지에 초기화한다.
 */
export async function signOut(payload?: any) {
  console.log(`%csessionStorage clear`, `display:block;width:100%;padding:2px 5px;font-size:12px;background-color:tomato;color:black;`)
  await sessionStorage.clear()
  console.clear()
  return true
}
