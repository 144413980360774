/**
 * @name 캘린더
 * @description
 */
import {DropDown, makeArray, useCalendar, uuid} from 'lib'
import {COLOR} from 'styles'
import styled from 'styled-components'
import {GrFormNext, GrFormPrevious} from 'assets/icon'
import React from 'react'

type Props = {
  onChange?: (value: any) => void
}
export default function Calendar({onChange}: Props) {
  // hooks
  const year = makeArray(20, {label: 'name', value: new Date().getFullYear()}).map((item: any, index) => {
    const _val = item?.value - index
    return {label: _val + '', value: _val}
  })
  const week = ['일', '월', '화', '수', '목', '금', '토']
  const {calendar, setSelectYear, selectYear, selectMonth, prevMonth, nextMonth} = useCalendar()
  //*--------------------------------------------------*
  // 오늘표시
  const checkToday = (item: any) => {
    if (selectYear === new Date().getFullYear() && selectMonth === new Date().getMonth() + 1 && item === new Date().getDate()) {
      return 'today'
    } else {
      return ''
    }
  }
  //renderCalendar
  const renderCalendar = () => {
    return calendar.map((list: any, index: number) => {
      return (
        <dl key={uuid()}>
          {list.map((item: any) => {
            const isDisable = item === '' ? 'disabled' : ''
            return (
              <React.Fragment key={uuid()}>
                {isDisable ? (
                  <span className="disabled"></span>
                ) : (
                  <button
                    // 오늘표시
                    className={`${checkToday(item)} ${isDisable}`}
                    onClick={() => {
                      const _month = selectMonth < 10 ? '0' + selectMonth : selectMonth
                      const _date = item < 10 ? '0' + item : item
                      const payload = {
                        year: selectYear,
                        month: _month,
                        date: _date,
                        value: `${selectYear}${_month}${_date}`,
                        format: `${selectYear}.${_month}.${_date}`,
                      }
                      !!onChange && onChange(payload)
                    }}>
                    <label>{item}</label>
                  </button>
                )}
              </React.Fragment>
            )
          })}
        </dl>
      )
    })
  }
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="wrap">
        {/* 헤더 */}
        <header>
          <DropDown
            onChange={(item: any) => {
              setSelectYear(item?.value)
            }}
            info={year}
            style={{width: 150}}
            layerStyle={{width: 150, height: 200, overflowY: 'scroll'}}
          />
          <button className="control" onClick={prevMonth}>
            <GrFormPrevious />
          </button>
          <h1 className="month">{selectMonth}월</h1>
          <button className="control" onClick={nextMonth}>
            <GrFormNext />
          </button>
        </header>
        <main>
          <dl className="week" key={uuid()}>
            {week.map(list => {
              return <dt key={uuid()}>{list}</dt>
            })}
          </dl>
          {renderCalendar()}
        </main>
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  .wrap {
    width: 100%;
    /* height: 150px; */
  }
  /* <!--헤더--> */
  header {
    display: flex;
    /* width: 50%; */
    margin: 5px 10px;
    align-items: center;
    justify-content: space-between;
    .month {
      display: inline-block;
      width: 50px;
      color: ${COLOR.BLACK3};
      font-size: 17px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
    }
    /* 이전,다음 */
    .control {
      width: 24px;
      height: 24px;
      background: ${COLOR.GRAY6};
      &:hover {
        color: red;
      }
    }
  }
  /* 본문 */
  main {
    dl {
      display: flex;
      justify-content: center;
      justify-content: space-between;
      //common
      dt,
      button {
        display: flex;
        /* flex: 1; */
        width: 100%;
        /* width: 54px; */
        width: 44px;
        height: 44px;
        align-items: center;
        justify-content: center;
        /* 일요일 */
        &:nth-child(1) {
          color: ${COLOR.RED1};
        }
        /* 토요일 */
        &:nth-child(7) {
          color: ${COLOR.YELLOW3};
        }
      }
    }
    dt {
      font-size: 14px;
      color: ${COLOR.GRAY3};
    }
    button {
      display: block;
      margin: 5px;
      border-radius: 50%;
      font-size: 13px;
      line-height: 20px;
      //오늘표시
      &.today {
        color: ${COLOR.WHITE};
        border-radius: 50%;
        background: ${COLOR.BLUE2};
      }
      // 기간설정
      &.range {
        background: ${COLOR.BLUE5};
      }
      &:hover:not(.disabled) {
        background: ${COLOR.BLUE8};
      }
    }
    /* disabled */
    span.disabled {
      margin: 5px;
      width: 44px;
      height: 44px;
    }
  }
`
/********************************************************
[사용법]
https://day.js.org/en/


*********************************************************/
