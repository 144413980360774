/**
 * @name
 * @description 3x3 이면 row 3개, col 3개 각각 형태
 */
import {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import * as echarts from 'echarts'
import {COLOR} from 'styles'
import {Flex, Text, addComma} from 'lib'
import {Store} from '../../'
import {useEventListener} from 'usehooks-ts'

// contents

export default function Contents() {
  // hooks
  const chartRef = useRef<any>(null)
  const [chartElement, setChartElement] = useState<echarts.ECharts>()
  const {data, chart, power_unit} = Store()
  const option = {
    tooltip: {
      trigger: 'axis',
    },
    // 색상추가
    color: [COLOR.blue30, COLOR.green30, COLOR.yellow30],
    legend: {
      bottom: 0,
      data: ['발전량', '예상 발전량', '누적 발전량'],
    },
    grid: {
      top: '10%',
      left: '0%',
      right: '0%',
      bottom: '10%',
      containLabel: true,
    },
  }
  const [options, setOptions] = useState(Object.assign({}, option, {})) as any
  // resize
  const handleSize = () => {
    !!chartElement && chartElement?.resize()
  }
  useEventListener('resize', handleSize)
  //*--------------------------------------------------*
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current)
      setChartElement(chart)
      chart.setOption(options)
      // Event listener callback
      const handleClick = (params: any) => {
        console.log(params)
      }
      // Bind the event listener
      chart.on('click', handleClick)
    }
    return () => {}
  }, [options, chartRef])

  useEffect(() => {
    setOptions(
      Object.assign({}, options, {
        xAxis: {
          type: 'category',
          name: '',
          boundaryGap: true,
          data: chart?.x,
        },
        yAxis: {
          type: 'value',
          name: power_unit || 'k',
          nameLocation: 'end',
          nameTextStyle: {
            color: '#373737',
            padding: [0, 40, 10, 0],
          },
          axisLine: {
            lineStyle: {
              color: '#373737',
            },
          },
        },
        series: chart.y,
      }),
    )
    return () => {}
  }, [chart])
  //*--------------------------------------------------*
  return (
    <Content>
      <div
        ref={chartRef}
        style={{
          width: '96%',
          height: '478px',
          marginBottom: 30,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      {/* <Flex width="100%" alignitems="flex-end" justifycontent="flex-end">
        <Text mode="Body_MM" color={COLOR.gray50} label={`전체 누적량 ${addComma(data?.[0]?.F_TWH)}kWh`} />
      </Flex> */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 100%;
  margin-bottom: 16px;
`
