/**
 * @name 장비(Equipment)
 * @description
 */
import {Table} from 'lib'
import React, {useEffect} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {FacilityProps, ListRenderItem} from 'types'
// contents
import Item from './item'

type Props = {
  item: FacilityProps
}
export default function Contents({item}: Props) {
  const total = item?.children.length
  //*--------------------------------------------------*
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <Table
        info={item?.children}
        renderHeader={() => {
          return (
            <React.Fragment>
              <tr>
                <th className="cell-line" />
                <th>구분</th>
                <th>장비명</th>
                <th>장비 상태</th>
                <th>모델명</th>
                <th>제품번호 (S/N)</th>
                <th>부착위치</th>
                <th>최대용량</th>
                <th>정격용량</th>
                <th>IP</th>
                <th>Port</th>
                <th>MAC</th>
                {/* <th>삭제</th>
                <th style={{width: 96}}>모듈 추가</th> */}
              </tr>
            </React.Fragment>
          )
        }}
        renderItem={({item, index}: ListRenderItem) => <Item total={total} item={item} index={index} />}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: block;
  width: 100%;
  table {
    width: 100%;
    /*----토글 추가----------*/
    button.toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    th {
      height: 48px;
      border-bottom: 0;
      text-align: left;
      background-color: ${COLOR.gray90};
    }
    td {
      height: 80px;
    }
  }
`
