/**
 * @name Bank
 * @description
 */
import {useState} from 'react'
import {Events, Flex, Icon, Text, uuid} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {arrow_down, arrow_next, del, plus_white, round_blue} from 'assets/svg'
// contents
import Add from './add'
import {Facility} from '..'
import {_event} from 'pages/equipment_setting'

type Props = {
  item: any
}
type ContentProps = {
  visible: boolean | string
}
export default function Bank({item}: Props) {
  // hooks
  const [visible, setVisible] = useState(false)
  const [isAdd, setIsAdd] = useState(false) //등록유무

  //*--------------------------------------------------*
  return (
    <Content visible={visible.toString()}>
      <Flex className="row" width="100%" alignitems="center" justifycontent="space-between">
        <button
          className="toggle"
          onClick={() => {
            setVisible(!visible)
          }}>
          <Icon src={visible ? arrow_down : arrow_next} />
          <Text style={{marginLeft: 8}} mode="Title_MB" color={COLOR.gray30} label={item?.name || 'BANK'} />
        </button>
        {/* 유틸메뉴 */}
        <Flex>
          {/* 설비추가 */}
          {/* <button
            className="util"
            onClick={() => {
              setIsAdd(true)
            }}>
            <Icon src={plus_white} />
            <Icon src={round_blue} style={{width: 14, height: 14, marginLeft: 8, marginRight: 8}} />
            <Text mode="Btn_M" label="설비 추가" color={COLOR.gray30} />
          </button> */}
          {/* Bank삭제 */}
          {/* <button
            className="util"
            onClick={() => {
              Events.emit(_event.DEVICES_DELETE, item)
            }}>
            <Icon src={del} style={{width: 24, height: 24, marginRight: 8}} />
            <Text mode="Btn_M" label="Bank 삭제" color={COLOR.gray30} />
          </button> */}
        </Flex>
      </Flex>
      {/* 설비추가 */}
      {isAdd && visible && <Add setIsAdd={setIsAdd} item={item} />}
      {/* 설비내용 */}
      {visible && <Facility key={uuid()} item={item} />}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80px;
  height: ${({visible}) => (visible === 'true' ? 'auto' : '80px')};
  overflow: hidden;
  padding: 0 24px;
  border-bottom: 1px solid ${COLOR.gray90};
  /*----타이틀----------*/
  .row {
    height: 80px;
  }
  /*----설비 추가----------*/
  button.util {
    display: flex;
    height: 40px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`
