/**
 * @name Container
 */
import {useEffect} from 'react'
import {useAppDispatch} from 'model'
import Presenter from './presenter'
import {Events, log, objectToQueryString, useAxios, useContext} from 'lib'
import {_action, _event} from '.'
import {API} from 'config'
import dayjs from 'dayjs'

export default function Container() {
  // hooks

  //*--------------------------------------------------*
  async function onInit() {
    // 한달전
    // const today = dayjs().add(-30, 'day').format('YYYYMMDD')
  }
  // 통계유형,시작날짜,종료날짜,충전소선택

  //*--------------------------------------------------*
  useEffect(() => {
    onInit()
    // addListener
    return () => {
      // removeListener
    }
  }, [])

  return <Presenter />
}
