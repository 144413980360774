/**
 * @name Top
 */
import styled from 'styled-components'
// contents
import Path from './path'
import Status from './status'

export default function Top() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 패스 */}
      <Path />
      {/* 상태내용 */}
      <Status />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  width: 100%;
  padding-right: 20px;
  align-items: center;
  justify-content: space-between;
  /*-- 사이트맵 --*/
`
