/**
 * @name 헤더
 * @description
 */
import {Flex, Text} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents

export default function Contents() {
  return (
    <Content>
      <Flex alignitems="center" style={{height: 80}}>
        <Text mode="Headline_MB" color={COLOR.gray30} label="프로필 설정" style={{marginLeft: 16}} />
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 100%;
  height: 80px;
  padding-left: 24px;
  border-bottom: 1px solid ${COLOR.gray90};
  button:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
