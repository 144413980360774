/**
 * @name useScroll
 * @description
 */

import {useEffect, useState, useRef} from 'react'

// Throttle function
const throttle = (callback: Function, limit: number) => {
  let lastCall = 0
  return function () {
    const now = Date.now()
    if (now - lastCall < limit) {
      return
    }
    lastCall = now
    return callback.apply(null, arguments)
  }
}

export const useScroll = (gap: number = 0) => {
  // hooks
  const [isScrollEnd, setIsScrollEnd] = useState<boolean>(false)
  const [scrollTop, setScrollTop] = useState<number>(0)
  const lastScrollTop = useRef<number>(0) // Ref to track the last scroll position
  //*--------------------------------------------------*
  const handleScroll = throttle(() => {
    const {scrollTop, clientHeight, scrollHeight} = document.documentElement
    if (scrollTop > lastScrollTop.current) {
      // Only update when scroll down
      const isScrollEnd = scrollTop + clientHeight + gap >= scrollHeight
      setIsScrollEnd(isScrollEnd)
      setScrollTop(scrollTop)
    }
    lastScrollTop.current = scrollTop // Update the last scroll position
  }, 50) // 빈도수체크

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return {isScrollEnd, scrollTop}
}

/********************************************************
[사용법]

const {isScrollEnd, scrollTop} = useScroll(50)  // 50은 gap값

useEffect(() => {
  if (isScrollEnd) {
    console.log('스크롤:', scrollTop)
  }
  console.log(scrollTop)
}, [isScrollEnd, scrollTop])

*********************************************************/
