/**
 * @name CountUp
 * @description
 */
import React, {useEffect, useRef, useState} from 'react'
import {animate, AnimatePresence, motion} from 'framer-motion'
import styled from 'styled-components'
import {COLOR} from 'styles'

type Props = {
  children?: React.ReactNode //-------------------Children
  visible?: boolean | any
}
export default function ToastPopup({children, visible}: Props) {
  // hooks
  const ref = useRef(null)
  //*--------------------------------------------------*
  const createElement = () => {
    return (
      <div className="item">
        <div>1개의 파일을 삭제 했습니다.</div>
      </div>
    )
  }
  //*--------------------------------------------------*
  return (
    <Element>
      <AnimatePresence initial={false}>
        {visible && (
          <motion.div ref={ref} key="modal" initial={{y: 100, opacity: 0}} animate={{y: 0, opacity: 1}} exit={{opacity: 0}}>
            {children ?? createElement()}
          </motion.div>
        )}
      </AnimatePresence>
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.div`
  position: fixed;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
  /* 기본아이템 */
  .item {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    width: 230px;
    height: 24px;
    padding: 16px 24px;
    font-size: 15px;
    line-height: 24px;
  }
`

/********************************************************
[사용법]

<Button
  label="토스트팝업"
  onClick={() => {
    setVisible(val => !val)
    if (!visible) {
      setTimeout(() => {
        setVisible(false)
      }, 3000)
    }
  }}
/>

<Toast visible={visible}></Toast>
*********************************************************/
