/**
 * @name Button
 * @description 버튼
 */
import {COLOR} from 'styles/color'
import React from 'react'
import styled from 'styled-components'
import {CSS} from 'types'
import Icon from '../image/icon'

type Props = {
  params?: {}
  src?: any // 이미지 아이콘
  className?: string //-------------------------- 클래스네임
  width?: string
  height?: string
  label?: string
  style?: CSS //--------------------------------- 스타일시트
  labelStyle?: CSS //--------------------------------- 스타일시트
  onClick?: (event: React.MouseEvent<HTMLElement>) => void //------------------------ onClick 이벤트
}
export default function IconTextButton({params, width, src, className, label, labelStyle, onClick}: Props) {
  // hooks

  //*--------------------------------------------------*
  return (
    <Element
      className={className}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        !!onClick && onClick(Object.assign({}, params, event))
      }}>
      {!!src && <Icon src={src} width={width || '24px'} height={width || '24px'} />}
      {!!label && (
        <span className="label" style={labelStyle}>
          {label}
        </span>
      )}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.button`
  /* display: inline-block; */
  display: flex;
  height: 40px;
  padding: 8px 12px 6px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${COLOR.blue30};
  transition: all 0.2s cubic-bezier(0.8, 0.07, 0.31, 1);
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
    /* background-color: ${COLOR.BLUE2}; */
  }
  &:active {
    filter: brightness(0.9);
    /* filter: contrast(150%); */
    /* background-color: ${COLOR.BLUE0}; */
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${COLOR.BLUE_GRAY8};
  }
  span {
    display: inline-block;
    margin-left: 8px;
    margin-right: 4px;
    color: ${COLOR.blue30};
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 150% */
  }
`
/********************************************************
[사용법]

  <IconTextButton src={refresh_btn} label="알람 설정" labelStyle={{color: 'red'}} />

*********************************************************/
