/**
 * @name Code1
 * @description 가이드페이지
 */

import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {Button, Flex, Input, Loop, debug, useContext, useUtil} from 'lib'
import {stringify} from 'querystring'

export default function Contents() {
  // hooks
  // const {navi, state, user} = useContext()
  const {log} = useUtil()
  const [keyword, setKeyword] = useState<string[]>(['키워드', '갤럭시', '야간라이딩'])
  //*--------------------------------------------------*
  useEffect(() => {
    return () => {}
  }, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <div>
        {/* 레이아웃시작 */}
        <Flex className="wrapper" alignitems="center">
          <Loop
            info={keyword}
            renderItem={({item}) => (
              <span className="item">
                <label>{item}</label>
                <button
                  onClick={() => {
                    const temp = keyword.filter(i => i !== item)
                    setKeyword(temp)
                  }}>
                  X
                </button>
              </span>
            )}
          />
          {/* 값입력 */}
          <Input
            style={{width: 200}}
            className={'input'}
            onSubmit={(str: string) => {
              const temp = keyword.concat(str)
              setKeyword(temp)
            }}
          />
        </Flex>
        {/* 레이아웃종료 */}
      </div>

      {/* 헤더 */}
      {/* {debug(navi)}
      {debug(state)}
      {debug(user)} */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /*--------------*/
  .item {
    border: 1px solid #000;
    padding: 4px 10px;
    margin-right: 10px;
  }
`
/********************************************************
[사용법]


*********************************************************/
