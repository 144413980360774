/**
 * @name H2
 * @description H2
 */

import styled from 'styled-components'
import {TextProps} from 'types'

export default function H2({children, style, label, ...props}: TextProps) {
  return (
    <Element className="essential-text" {...props}>
      {!!label && label}
      {children}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.h2`
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.3px;
`
