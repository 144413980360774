/**
 * @name Loop
 * @description
 */
import React from 'react'
import {debug} from 'lib'

type Props = {
  info: any[] | undefined
  emptyItem?: React.ReactNode // 빈값일경우 렌더링할 컴포넌트
  renderItem?: (props: {item: any; index: number}) => React.ReactNode // 렌더링할 컴포넌트(리턴값)
}
export default function Loop({renderItem, info, emptyItem}: Props) {
  // const
  return (
    <React.Fragment>
      {/* renderItem없을경우디버그 */}
      {/* {renderItem === undefined && debug(info)} */}
      {!!info && info?.length > 0
        ? info.map((item, index) => {
            return <React.Fragment key={`${item}_${index}`}>{!!renderItem && renderItem({item: item, index: index})}</React.Fragment>
          })
        : emptyItem || null}
    </React.Fragment>
  )
}

/********************************************************
[사용법]
  <Loop
    info={widgets}
    renderItem={({item, index}) => (
      <RoundPanel style={{background: randomColor()}}>
        {index + 1} _ {item.title}
      </RoundPanel>
    )}
  />
*********************************************************/
