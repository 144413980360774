/**
 * @name TextFiledGroup
 * @description 라벨및 필드여백
 */

import styled from 'styled-components'
import {CSS} from 'types'
import {H5, Text} from 'lib'
import {COLOR} from 'styles'

type Props = {
  label?: string
  required?: boolean
  style?: CSS
  desc?: string
  disabled?: boolean
  component?: React.ReactNode //------------------ children
}
export default function TextFiledGroup({label, required, style, disabled, component, desc}: Props) {
  return (
    <Element style={style}>
      <dt>
        <H5>{label ?? '텍스트라벨명'}</H5>
        {required && (
          <H5 className={'required'} style={{color: 'red'}}>
            *
          </H5>
        )}
      </dt>
      {!!component && <dd className="component">{component}</dd>}
      {!!desc && !disabled && (
        <dd className="desc">
          <Text color={COLOR.GRAY3} className="caption1">
            {desc}
          </Text>
        </dd>
      )}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.dl`
  margin-bottom: 10px;
  dt {
    display: flex;
    margin-bottom: 4px;
    flex-direction: row;
    align-items: flex-start;
    color: ${COLOR.GRAY1};
    //required
    .required {
      color: ${COLOR.RED3};
    }
  }
  dd.desc {
    display: flex;
    margin-left: 4px;
    margin-top: 4px;
  }
`
/********************************************************
[사용법]
      <TextFiledGroup
        label="충전소명"
        required
        desc="충전소 명은 25자 내의 한글, 영문, 숫자, -,_ 만 입력 가능합니다."
        component={
          <Div className="gap">
            <TextInput disabled={disabled} style={{width: '77%'}} placeholder="충전소 명을 입력해 주세요." defaultValue={''} name="name" onChange={onChangeHandler} />
            <Button label="중복확인" disabled={disabled} />
          </Div>
        }
      />
*********************************************************/
