/**
 * @name Presenter
 * @description
 * @name Bank>설비>장비>모듈
 */
import styled from 'styled-components'
import {App} from 'template'
import {Panel} from 'components'
import {Flex, LoadingAnimation, Loop} from 'lib'
import {COLOR} from 'styles'
import {cell_end, cell_line, cell_start} from 'assets/svg'
import {Store} from '.'
// contents
import {Header} from './common'
import {Bank} from './contents'

export default function Presenter() {
  const {info} = Store()
  //*--------------------------------------------------*
  return (
    <App>
      <Content className="container-height">
        <Panel padding="0 0 50px" header={<Header />}>
          {/* 테이블영역 */}
          {info?.length === 0 ? (
            <Flex alignitems="center" justifycontent="center" style={{marginTop: 100}}>
              <LoadingAnimation />
            </Flex>
          ) : (
            <Loop info={info} renderItem={({item}) => <Bank item={item} />} />
          )}
        </Panel>
      </Content>
    </App>
  )
}
//*--------------------------------------------------*

const Content = styled.div`
  /*----common----------*/
  table {
    width: 100%;
    .cell-empty,
    .cell-line,
    .cell-end,
    .cell-start {
      width: 48px;
      border-bottom: 0;
    }
    .cell-empty {
      background-color: ${COLOR.white};
    }
    .cell-start {
      background: url(${cell_start}) no-repeat center center;
    }
    .cell-line {
      background: url(${cell_line}) no-repeat center center;
    }
    .cell-end {
      background: url(${cell_end}) no-repeat center center;
    }
    // 마지막 행
    .facility-item:nth-last-child(1) > td {
      border-bottom: 0 !important;
    }
    .equipment-item:nth-last-child(1) > td {
      border-bottom: 0 !important;
    }
    .module-item:nth-last-child(1) > td {
      border-bottom: 0 !important;
    }
    .child-node {
      padding-left: 0 !important;
      border-bottom: 0 !important;
    }
    th {
      height: 48px !important;
      border-bottom: 0;
      text-align: left;
      background-color: ${COLOR.gray90};
      /* font-size: 10px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.2px; */
    }
    /* td {
      font-size: 10px !important;
      font-style: normal !important;
      font-weight: 700;
      letter-spacing: -0.2px;
      span {
        font-size: 10px !important;
      }
    } */
    tr {
      border: 0;
      th,
      td {
        border-bottom: 1px solid ${COLOR.gray80};
      }
    }
  }
  /*----토글 추가----------*/
  button.toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`
