/**
 * @name 설비에서장비추가
 * @description
 */
import {Button, DropDown, Events, Flex, Input, Table, Text, TextButton, debug, useContext, uuid} from 'lib'
import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {ListRenderItem} from 'types'
import {Store, _event} from '../../index'
// contents
type Props = {
  item?: any
  modify?: boolean
  setIsAdd: (value: boolean) => void
}
export default function Contents({setIsAdd, modify, item}: Props) {
  // hooks
  const {user} = useContext()
  const {devices} = Store()
  const factoryDeviceType = 'equipment'
  const parentNo = item?.factoryDeviceNo
  const factoryId = user?.factoryId
  const sn = useRef('')
  const name = useRef('')
  const type = useRef('')
  const instLocation = useRef('') //위치
  const factoryDeviceId = useRef('') //공장장치ID
  const maximumCapacity = useRef('') //정격용량
  const maximumCapacityUnit = useRef('') //정격용량단위
  const ratedCapacity = useRef('') //정격용량
  const ratedCapacityUnit = useRef('') //정격용량단위
  const ipAddress = useRef('')
  const port = useRef('')
  const macAddress = useRef('')
  const [state, setState] = React.useState<any>(item)

  //*--------------------------------------------------*
  const onChange = (e: React.ChangeEvent<HTMLInputElement>, target: React.MutableRefObject<string>) => {
    const value = e.target.value
    target.current = value
  }
  useEffect(() => {
    modify && setState(item)
    !modify && setState(null)
  }, [modify])
  //*--------------------------------------------------*
  return (
    <Content>
      <Table
        info={[0]}
        renderHeader={() => {
          return (
            <React.Fragment>
              <tr className="add-row">
                <th className="cell-line" />
                <th className="c" style={{width: 100}}>
                  종류
                </th>
                <th className="c">모듈명</th>
                <th className="c">모델명</th>
                <th className="c">제품번호 (S/N)</th>
                <th className="c">부착위치</th>
                <th className="c">최대용량</th>
                <th className="c">최대용량 단위</th>
                <th className="c">정격용량</th>
                <th className="c">정격용량 단위</th>
                <th className="c">IP</th>
                <th className="c">Port</th>
                <th className="c">MAC</th>
              </tr>
            </React.Fragment>
          )
        }}
        renderItem={({item}: ListRenderItem) => {
          return (
            <React.Fragment>
              <tr key={uuid()}>
                <td className="cell-start" />
                {/* 종류 */}
                <td className="c">
                  <DropDown
                    info={devices}
                    label="name"
                    onChange={item => {
                      console.log(item.type)
                      type.current = item.type
                    }}
                  />
                </td>
                {/* 모듈명 */}
                <td className="">
                  <Input className="input" defaultValue={state?.factoryDeviceId} onChange={event => onChange(event, factoryDeviceId)} />
                </td>
                {/* 모델명 */}
                <td>
                  <Input className="input" defaultValue={state?.name} onChange={event => onChange(event, name)} />
                </td>
                {/* 제품번호 */}
                <td>
                  <Input className="input" defaultValue={state?.sn} onChange={event => onChange(event, sn)} />
                </td>
                {/* 부착위치 */}
                <td>
                  <Input className="input" defaultValue={state?.instLocation} onChange={event => onChange(event, instLocation)} />
                </td>
                {/* 최대용량 */}
                <td>
                  <Input className="input" defaultValue={state?.maximumCapacity} onChange={event => onChange(event, maximumCapacity)} />
                </td>
                {/* 최대용량 단위 */}
                <td>
                  <DropDown
                    layerStyle={{height: 160, overflow: 'auto'}}
                    info={[
                      {label: 'Hz', value: 'Hz'},
                      {label: 'kHz', value: 'kHz'},
                      {label: 'MHz', value: 'MHz'},
                      {label: '%', value: '%'},
                      {label: 'ch', value: 'ch'},
                      {label: 'P', value: 'P'},
                      {label: '℃', value: '℃'},
                      {label: 'Ω', value: 'Ω'},
                      {label: '㏀', value: '㏀'},
                      {label: 'Ø', value: 'Ø'},
                      {label: '㎃', value: '㎃'},
                      {label: 'A', value: 'A'},
                      {label: 'Vin', value: 'Vin'},
                      {label: 'Vout', value: 'Vout'},
                      {label: 'V', value: 'V'},
                      {label: 'Vac', value: 'Vac'},
                      {label: 'Vdc', value: 'Vdc'},
                    ]}
                    onChange={item => {
                      maximumCapacityUnit.current = item.value
                    }}
                  />
                  {/* <Input className="input" defaultValue={state?.maximumCapacityUnit} onChange={event => onChange(event, maximumCapacityUnit)} /> */}
                </td>
                {/* 정격용량 */}
                <td>
                  <Input className="input" defaultValue={state?.ratedCapacity} onChange={event => onChange(event, ratedCapacity)} />
                </td>
                {/* 정격용량 단위 */}
                <td>
                  <DropDown
                    layerStyle={{height: 160, overflow: 'auto'}}
                    info={[
                      {label: 'Hz', value: 'Hz'},
                      {label: 'kHz', value: 'kHz'},
                      {label: 'MHz', value: 'MHz'},
                      {label: '%', value: '%'},
                      {label: 'ch', value: 'ch'},
                      {label: 'P', value: 'P'},
                      {label: '℃', value: '℃'},
                      {label: 'Ω', value: 'Ω'},
                      {label: '㏀', value: '㏀'},
                      {label: 'Ø', value: 'Ø'},
                      {label: '㎃', value: '㎃'},
                      {label: 'A', value: 'A'},
                      {label: 'Vin', value: 'Vin'},
                      {label: 'Vout', value: 'Vout'},
                      {label: 'V', value: 'V'},
                      {label: 'Vac', value: 'Vac'},
                      {label: 'Vdc', value: 'Vdc'},
                    ]}
                    onChange={item => {
                      ratedCapacityUnit.current = item.value
                    }}
                  />
                  {/* <Input className="input" defaultValue={state?.ratedCapacityUnit} onChange={event => onChange(event, ratedCapacityUnit)} /> */}
                </td>
                {/* IP */}
                <td>
                  <Input className="input" defaultValue={state?.ipAddress} onChange={event => onChange(event, ipAddress)} />
                </td>
                {/* Port */}
                <td>
                  <Input className="input" name="port" defaultValue={state?.port} onChange={event => onChange(event, port)} />
                </td>
                {/* MAC */}
                <td>
                  <Input className="input" name="macAddress" defaultValue={state?.macAddress} onChange={event => onChange(event, macAddress)} />
                </td>
              </tr>
              <tr key={uuid()}>
                <td className="cell-line" />
                <td colSpan={14} style={{padding: 0}}>
                  {/* 입력창 */}
                  <section className="wrap">
                    <Text mode="Body_MM" color={COLOR.gray50}>
                      ※ 설치위치는 계통단선도 기준으로 입력하세요.
                    </Text>
                    <Flex alignitems="center" justifycontent="flex-end">
                      <TextButton
                        label="취소"
                        style={{marginRight: 24}}
                        onClick={() => {
                          setIsAdd(false)
                        }}
                      />
                      <Button
                        label="저장하기"
                        onClick={() => {
                          const result = {
                            factoryId: factoryId,
                            factoryDeviceType: factoryDeviceType,
                            sn: sn.current,
                            name: name.current,
                            type: type.current,
                            instLocation: instLocation.current,
                            factoryDeviceId: factoryDeviceId.current,
                            maximumCapacity: maximumCapacity.current,
                            maximumCapacityUnit: maximumCapacityUnit.current,
                            ratedCapacity: ratedCapacity.current,
                            ratedCapacityUnit: ratedCapacityUnit.current,
                            port: port.current,
                            macAddress: macAddress.current,
                            ipAddress: ipAddress.current,
                            parentNo: parentNo,
                          }
                          if (modify) Events.emit(_event.DEVICES_UPDATE, item)
                          if (!modify) Events.emit(_event.DEVICES_ADD, result)
                        }}
                      />
                    </Flex>
                  </section>
                </td>
              </tr>
            </React.Fragment>
          )
        }}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.tr`
  display: block;
  .add-row > th:not(.cell-line) {
    background-color: ${COLOR.blue90};
  }
  section.wrap {
    margin-bottom: 40px;
  }
  tbody tr td {
    padding: 0 8px;
    .input {
      width: 100%;
    }
  }
  tbody tr:nth-last-child(1) {
    td {
      border-bottom: 0;
    }
  }
`
