/**
 * @name Content
 * @description
 */
import {useState} from 'react'
import styled from 'styled-components'
import {BsFillExclamationCircleFill} from 'react-icons/bs'
import {Dayjs, Flex, Text, useInterval, useWindowSize} from 'lib'
import {COLOR} from 'styles'

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  const {width, height} = useWindowSize()
  const [nowTime, setNowTIme] = useState<string>()
  //*--------------------------------------------------*
  useInterval(() => {
    const day = Dayjs().format('YYYY.MM.DD HH:mm:ss')
    setNowTIme(day)
  }, 1000 * 1)
  return (
    <Content>
      <Flex flexdirection="row" justifycontent="space-between" style={{borderTop: `8px solid ${COLOR.GRAY3}`, padding: 14, borderBottom: `1px dashed ${COLOR.GRAY4}`}}>
        <Flex flexdirection="column">
          <Text size="24px" weight={100} color={COLOR.GRAY1} label={`width : ${width} , height : ${height}`} style={{marginBottom: 10}} />
          <Text size="14px" weight={300} color={COLOR.GRAY1} label="그래프 위에 진단 유형을 클릭하여, 개별 진단을 확인해 보세요." />
        </Flex>
        <Flex alignitems="center">
          <Text size="14px" weight={100} color={COLOR.GRAY3} label={nowTime} style={{width: 130, marginRight: 5}} />
          <BsFillExclamationCircleFill size={16} color={COLOR.GRAY3} />
        </Flex>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /**-------본문--*/
`
