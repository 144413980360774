/**
 * @name 에너지사용
 * @description
 */
import {Flex, Image, Text, addComma} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {use_tr_icon} from 'assets/svg'
import {Store} from 'pages/use'

export default function Contents() {
  // hooks
  const {info} = Store()
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 변압기01 */}
      <div className="parent">
        <Flex alignitems="center" style={{marginBottom: 16}}>
          <Image src={use_tr_icon} />
          <Flex flexdirection="column" style={{marginLeft: 8}}>
            <Text mode="Title_LB" label={info[0]?.name} color={'#5F5F5F'} />
            <Text mode="Body_MM" label={info[0]?.equipmentName || '계측기 이름'} color={'#8A8A8A'} />
          </Flex>
        </Flex>
        {/* 타이틀 */}
        <Flex alignitems="center" justifycontent="space-between">
          <span>
            <Text mode="Body_LM" label={info[0]?.data?.voltage} color={'#000'} />
            <Text mode="Body_SM" label={'V'} color={'#8A8A8A'} style={{marginLeft: 11}} />
          </span>
          <span>
            <Text mode="Body_LM" label={info[0]?.data?.current} color={'#000'} />
            <Text mode="Body_SM" label={'A'} color={'#8A8A8A'} style={{marginLeft: 11}} />
          </span>
        </Flex>
      </div>
      {/* 용해로 */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /**-------본문--*/
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 60px 0;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.gray80};
  /**-------변압기--*/
  .parent {
    min-width: 201px;
    height: 120px;
    padding: 16px;
    border-radius: 16px;
    background-color: ${COLOR.white};
    margin: 0 auto;
  }
`
