/**
 * @name 에너지사용량
 * @description
 */

import {createSlice, PayloadAction} from '@reduxjs/toolkit'
export const sliceName = 'use' //변경필요
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  info: any[] // 에너지사용량정보
  chart: {} | null
  [key: string]: any
}
//*--------------------------------------------------*
/** @initialState */
const initialState: StateType = {
  info: [],
  chart: null,
}
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // reloadTime
    setReloadTime(state: StateType, action: PayloadAction<any>) {
      state.reload_time = action.payload
    },
    // info
    setInfo(state: StateType, action: PayloadAction<any>) {
      state.info = action.payload
    },
    // setChart
    setChart(state: StateType, action: PayloadAction<any>) {
      state.chart = action.payload
    },
  },
})
//*--------------------------------------------------*
// export
export const EVENT = {
  // submit
  SELECT_DATE_TYPE: 'SELECT_DATE_TYPE',
}
// 레이어팝업이많을경우분기처리
export const POPUP = {}
export default slice.reducer
export const nameSpace = sliceName
export const actions = slice.actions
