/**
 * @name 헤더
 * @description
 */
import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {exclamation_gray, exclamation_on, setup_gray} from 'assets/svg'
import {MENU} from 'config'
import {DropDown, Flex, Icon, ImageButton, Text, useRoutes} from 'lib'
// contents

export default function Contents() {
  // hooks
  const {navigate} = useRoutes()
  const [visible, setVisible] = useState(false)
  //*--------------------------------------------------*
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex alignitems="center">
        <Text mode="Headline_MB" color={COLOR.gray30} style={{marginRight: 16}}>
          알림 히스토리
        </Text>
        <ImageButton
          src={exclamation_gray}
          hover_src={exclamation_on}
          style={{marginRight: 10}}
          width={40}
          height={40}
          onMouseEnter={() => {
            setVisible(true)
          }}
          onMouseLeave={() => {
            setVisible(false)
          }}
        />
        <DropDown
          style={{width: 120}}
          onChange={(item: any) => {
            console.log(item)
          }}
          info={[
            {label: '전체 ', value: 'all'},
            {label: '장치', value: 'a'},
            {label: '용해로', value: 'b'},
          ]}
          layerStyle={{width: '100%'}}
        />
        {/* 툴팁이미지 */}
        {visible && <aside className="tooltip">용해로 알림 설정에서 설정하신 내용으로 알림을 받게 됩니다. </aside>}
      </Flex>
      <button
        className="link"
        onClick={() => {
          navigate(MENU.NOTIFICATION_SETUP)
        }}>
        <Flex alignitems="center">
          <Icon src={setup_gray} width="24" height="24" style={{marginRight: 8}} />
          <Text mode="Btn_M" label="용해로 알림 설정" />
        </Flex>
      </button>

      {/* 유틸메뉴 */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 80px;
  padding: 0 24px;
  border-bottom: 1px solid ${COLOR.gray90};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /*----common----------*/
  .tooltip {
    position: absolute;
    height: 32px;
    padding: 5px 16px;
    left: 220px;
    top: 25px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    color: ${COLOR.white};
    background-color: ${COLOR.blue30};
    &::after {
      position: absolute;
      top: 8px;
      left: -3px;
      width: 14px;
      height: 14px;
      transform: rotate(45deg);
      background-color: ${COLOR.blue30};
      z-index: 1;
      content: '';
    }
  }
  /*----용해로알림설정----------*/
  button.link {
    &:hover {
      text-decoration: underline;
    }
  }
`
