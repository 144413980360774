/**
 * @name Content
 * @description
 */
import {useState} from 'react'
import styled from 'styled-components'
import {Button, Flex} from 'lib'
import {COLOR} from 'styles'

export default function Contents() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex justifycontent="center" alignitems="center">
        <Button
          backgroundcolor={COLOR.RED1}
          style={{marginRight: 10}}
          label="CANCEL"
          onClick={async () => {
            console.log('CANCEL')
          }}
        />
        <Button
          style={{marginLeft: 10}}
          label="SUBMIT"
          onClick={async () => {
            console.log('SUBMIT')
          }}
        />
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /**-------본문--*/
  padding: 20px;
  /* border-top: 1px dashed ${COLOR.GRAY4}; */
`
