/**
 * @name 대시보드
 * @description
 */
import styled from 'styled-components'
import {App} from 'template'
import {Panel} from 'components'
// contents
import {Header} from './common'
import {Form, Status} from './contents'

export default function Presenter() {
  return (
    <App>
      <Content className="container-height">
        <Panel header={<Header />}>
          {/* 컨텐츠영역 */}
          <div className="wrap">
            <Status />
            <Form />
          </div>
        </Panel>
      </Content>
    </App>
  )
}
//*---------------- ----------------------------------*
const Content = styled.div`
  .wrap {
    width: 440px;
    margin: 0 auto;
    padding: 24px;
  }
`
