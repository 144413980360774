/**
 * @name LoadingBar
 * @description
 */
import {loading_round} from 'assets/lottie'
import Lottie from 'lottie-react'

export default function LoadingBar() {
  // hooks

  return (
    <div style={{width: 60, height: 60}}>
      <Lottie animationData={loading_round} loop={true} />
    </div>
  )
}
