/**
 * @name Image
 * @description 레티나,디스플레이대응 2배수이미지
 */
import styled from 'styled-components'

type Props = {
  width?: number
  height?: number
  [key: string]: any
}
export default function Image({...props}: Props) {
  return <Element style={{height: 'auto', verticalAlign: 'top'}} {...props} />
}
//*--------------------------------------------------*
const Element = styled.img``

/********************************************************
[사용법]

<Image src={common_logo} />
<Image src={auth_logo} width={30} />

*********************************************************/
