/**
 * @name Hyphen
 * @description (-) 하이픈
 */
import {Text} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'

type Props = {
  style?: React.CSSProperties //----------------- StyleSheet
}
export default function Hyphen({style}: Props) {
  //*--------------------------------------------------*
  return (
    <Content style={style}>
      <Text label="-" className="center" color={COLOR.BLACK3} />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.button`
  margin-left: 10px;
  margin-right: 10px;
`
/********************************************************

<Hyphen />

*********************************************************/
