/**
 * @name index
 * @description
 */
import {useAppSelector} from 'model'
import {actions, EVENT, MODE, POPUP} from 'model/modules/auth'

export {default} from './container'
//*--------------------------------------------------*
/**
 * @name config
 */
export const Store = () => useAppSelector(state => state.auth)
export const _action = actions
export const _mode = MODE
export const _event = EVENT
export const _popup = POPUP

/********************************************************
[사용법]

*********************************************************/
