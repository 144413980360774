/**
 * @name GNB
 */
import {COLOR, FONT} from 'styles'
import {gnbAction} from 'model/modules'
import {GnbMenuType} from 'types'
import {Image, List, Row, useContext, useRoutes} from 'lib'
import styled from 'styled-components'
import {motion} from 'framer-motion'
import {useAppDispatch, useAppSelector} from 'model'
import {gnb_sub} from 'assets/svg'
// contents

type Props = {
  item: GnbMenuType
  depth1?: number
  index: number
}
export default function GnbDepth2Menu({item, depth1, index}: Props) {
  // hooks
  const dispatch = useAppDispatch()
  const {navigate} = useRoutes()
  const {navi} = useAppSelector(state => state.gnb)
  const {factoryType} = useContext()

  //*--------------------------------------------------*
  return (
    <Content>
      <motion.div
        className="children"
        exit={{height: 0}}
        animate={{height: 'auto'}}
        // 같은1depth메뉴에서 2depth메뉴로 이동시 애니메이션 없애기
        transition={{duration: 0.18, ease: 'easeInOut'}}>
        <List
          info={item?.children}
          renderItem={({item, index}) => {
            const {name, path} = item
            return (
              <button
                className={`menu ${navi?.depth1 === depth1 && 'active'}`}
                onClick={() => {
                  dispatch(gnbAction.setNavi({depth1: depth1, depth2: 0}))
                  navigate(path)
                }}>
                <Row className="wrap">
                  <Image src={gnb_sub} width={24} height={24} />
                  <label>{factoryType() === 'solar' ? '변압기' : name}</label>
                </Row>
              </button>
            )
          }}
        />
      </motion.div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.section`
  /* 1depth,2depth메뉴 */
  button {
    display: flex;
    width: 100%;
    height: 40px;
    padding-left: 20px;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
  }
  /* children */
  .children {
    overflow: hidden;
    /* 서브메뉴 */
    .menu {
      font-family: ${FONT.MAIN};
      font-size: 14px;
      color: ${COLOR.BLUE_GRAY2};
      &:hover {
        color: ${COLOR.blue30};
        background-color: ${COLOR.blue90};
      }
      /* 활성화 */
      &.active {
        color: ${COLOR.blue30};
        background-color: ${COLOR.blue90};
      }
      .wrap {
        /* padding-left: 20px; */
      }
    }
  }
`
