/**
 * @name Loading
 * @description 레이어팝업(외부영역 클릭시 닫힌다.)
 */
import {Oval} from 'react-loader-spinner'
import {COLOR} from 'styles'
import styled from 'styled-components'

export default function Loading() {
  // const
  const size = 50
  const _color = COLOR.BLUE0
  const _secondaryColor = COLOR.BLUE_GRAY4
  //*--------------------------------------------------*
  return (
    <Content>
      <Oval width={size} height={size} color={_color} secondaryColor={_secondaryColor} wrapperStyle={{}} visible={true} ariaLabel="oval-loading" strokeWidth={2} strokeWidthSecondary={2} />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div``
/********************************************************
[사용법]

*********************************************************/
