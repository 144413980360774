/**
 * @name ElementButton
 * @description 버튼
 */
import React, {CSSProperties} from 'react'
import styled from 'styled-components'

type Props = {
  element: React.ReactNode
  className?: string //----------------------- 클래스네임
  buttonStyle?: CSSProperties //-------------- 스타일시트
  style?: CSSProperties //-------------------- 스타일시트
  onClick?: (event: React.MouseEvent<HTMLElement>) => void //------------------------ onClick 이벤트
}
export default function ElementButton({element, className, buttonStyle = {}, style = {}, onClick}: Props) {
  // hooks
  const _style = Object.assign({}, !!style && style)
  //*--------------------------------------------------*
  return (
    <Element
      style={buttonStyle}
      className={className}
      onClick={(event: any) => {
        onClick && onClick(event)
      }}>
      <div style={_style}>{element}</div>
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.button``

/********************************************************
[사용법]
<ElementButton
  buttonStyle={{display: 'block', border: '1px solid red'}}
  style={{backgroundColor: 'red'}}
  source={icon_alert}
  onClick={() => {
    alert('test')
  }}
/>
*********************************************************/
