/**
 * @name H1
 * @description H1
 */

import styled from 'styled-components'
import {TextProps} from 'types'

export default function H1({children, label, ...props}: TextProps) {
  return (
    <Element className="essential-text" {...props}>
      {!!label && label}
      {children}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.48px;
`
/********************************************************
[사용법]
  <H1 label="H1 텍스트를 입력합니다." />
  <H1 style={{fontWeight: 'normal', fontSize: 46, color: 'gold'}}>H1 텍스트를 입력합니다.</H1>
*********************************************************/
