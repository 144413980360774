/**
 * @name Grid
 * @description
 */
import React from 'react'
import styled from 'styled-components'

type Props = {
  columns: number
  gap?: number
  children?: React.ReactNode //-------------------Children
  style?: React.CSSProperties //----------------- StyleSheet
  [key: string]: any
}
export default function Grid({children, columns, gap, style, ...props}: Props) {
  return <Element style={{...style, gridTemplateColumns: `repeat(${columns}, 1fr)`, gap}}>{children}</Element>
}
//*--------------------------------------------------*
const Element = styled.div<any>`
  display: grid;
`
/********************************************************
[사용법]

  <Grid columns={4} gap={10}>
    <RoundPanel style={{background: randomColor()}}>1</RoundPanel>
    <RoundPanel style={{background: randomColor()}}>1</RoundPanel>
    <RoundPanel style={{background: randomColor()}}>1</RoundPanel>
    <RoundPanel style={{background: randomColor()}}>1</RoundPanel>
  </Grid>
*********************************************************/
