/**
 * @name Panel
 * @description
 */
import React, {useEffect, useState} from 'react'
import {Events, Flex, Image, ImageButton, LoadingAnimation, Text, useAxios, useContext} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {CSS} from 'types'
import {exclamation_gray, exclamation_on} from 'assets/svg'
import {_event} from 'pages/dashboard'
import {acelo_logo} from 'assets/images'
import {MENU} from 'config'

type Props = {
  item?: any //--------------------------------------- Data정보
  style?: CSS //-------------------------------------- 스타일시트
  children?: React.ReactNode //----------------------- Children
  className?: string //------------------------------- 클래스네임
  padding?: string //--------------------------------- 여백
  border?: string //---------------------------------- 테두리
  borderRadius?: string //---------------------------- 곡률
  [key: string]: any
}
export default function Panel({item, className, style, border, borderRadius, padding}: Props) {
  // hooks
  const [isSucess, setIsSucess] = useState<null | boolean>(null)
  const [isHovered, setIsHovered] = useState(false) //---- hover 여부 상태 추가
  const {get} = useAxios()
  const [info, setInfo] = useState<any>(null)
  const WidgetComponent = item?.widget
  const {navigate} = useContext()
  //*--------------------------------------------------*
  // 초기화
  async function onInit() {
    const res = await get(item?.api)
    if (res?.success) {
      setInfo(res?.data)
      setIsSucess(true)
    } else {
      setIsSucess(false)
    }
  }
  // 타이틀설정
  const setTitle = (title: string) => {
    if (title === 'ACELO Solution') {
      return (
        <Flex alignitems="center">
          <Image src={acelo_logo} width={84} height={20} />
          <Text mode={'Title_LB'} label={'Solution'} color={COLOR.gray10} style={{marginLeft: 5}} />
        </Flex>
      )
    } else if (title === '예측 정확도') {
      return (
        <Flex alignitems="center">
          <Image src={acelo_logo} width={84} height={20} />
          <Text mode={'Title_LB'} label={title} color={COLOR.gray10} style={{marginLeft: 5}} />
        </Flex>
      )
    } else {
      return !!item && <Text mode={'Title_LB'} label={item?.title} color={COLOR.gray10} />
    }
  }
  // 고조파왜형률설정
  const distortionRate = () => {
    const status = info?.device?.status
    const name = info?.device?.name
    // let status = 'UNKNOWN'
    if (status === 'ON') return <div className="status on">{name} | 운전중</div>
    else if (status === 'OFF') return <div className="status off">{name} | 정지중</div>
    else if (status === 'UNKNOWN') return <div className="status unknown">{name} | 준비중</div>
    return null
  }
  //*--------------------------------------------------*
  useEffect(() => {
    onInit()
    // addListener
    Events.addListener(_event.DASHBOARD_REFRESH, onInit)
    return () => {
      // removeListener
      Events.removeListener(_event.DASHBOARD_REFRESH, onInit)
    }
  }, [])
  //*--------------------------------------------------*

  return (
    <Content height={item?.height} style={style} border={border} padding={padding} borderRadius={borderRadius} className={className}>
      {/* 헤더영역 */}
      <header>
        {/* 타이틀및 아이콘 */}
        <Flex alignitems="center">
          {setTitle(item?.title)}
          {/* 고조파 왜형율 */}
          {item?.type === 'distortion_rate' && distortionRate()}
          <ImageButton
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{marginLeft: 4}}
            src={exclamation_gray}
            hover_src={exclamation_on}
            width="22px"
            height="22px"
          />
        </Flex>
        {/* 자세히보기 */}
        <button
          className="more"
          onClick={() => {
            item?.title === '태양광 발전량/누적 발전량' ? navigate(MENU.SOLAR) : navigate(MENU.USE)
          }}>
          <Text mode={'Btn_M'} label={item?.buttonName} color={COLOR.gray60} />
        </button>
      </header>
      {/* 본문영역 */}
      <main>
        {/* 로딩중일때 */}
        {isSucess === null && (
          <div className="loading">
            <LoadingAnimation />
          </div>
        )}
        {/* 로딩완료 */}
        {isSucess && React.cloneElement(WidgetComponent, {info: info})}
        {/* 로딩실패 */}
        {isSucess !== null && !isSucess && (
          <Flex flexdirection="column" alignitems="center" justifycontent="center">
            <Image src={exclamation_gray} width={40} height={40} style={{marginBottom: 10}} />
            <Text mode="Body_LM" label="현재 수집되는 데이터가 없습니다" color={COLOR.gray50} />
          </Flex>
        )}
      </main>
      {/* 팝업 Aside */}
      {isHovered && (
        <aside className="description">
          <Text style={{whiteSpace: 'pre-wrap'}} mode={'Caption_LB'} label={item?.description} color={COLOR.white} />
        </aside>
      )}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.section<Props>`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: ${({height}) => (typeof height === 'string' ? height : height + 'px' || 'auto')};
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: 8px;
  /* border-bottom: 1px solid ${COLOR.gray80}; */
  background: ${COLOR.white};
  break-inside: avoid;
  /*--------------*/
  > header {
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
    height: 71px;
    border-bottom: 1px solid ${COLOR.gray80};
    button.more {
      &:hover span {
        color: ${COLOR.gray50} !important;
        /* text-decoration: underline; */
      }
    }
    //status
    .status {
      display: block;
      margin: 0 4px;
      padding: 2px 4px;
      border-radius: 4px;
      background-color: ${COLOR.gray60};
      color: ${COLOR.white};
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 166.667% */
      &.on {
        background-color: ${COLOR.green30};
      }
      &.off {
        background-color: ${COLOR.red30};
      }
    }
  }
  /*--------------*/
  > main {
    display: flex;
    width: 100%;
    /* padding: 10px 16px; */
    flex: 1;
    flex-direction: column;
    justify-content: center;
    .loading {
      display: flex;
      height: ${({height}) => height - 91 + 'px' || 'auto'};
      align-items: center;
      justify-content: center;
    }
  }
  /*--------------*/
  .description {
    position: absolute;
    top: 71px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 36px;
    background-color: rgba(55, 55, 55, 0.75);
    /* background-color: ${COLOR.gray30}; */
  }
`
/********************************************************
[사용법]

*********************************************************/
