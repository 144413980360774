/**
 * @name CountUp
 * @description
 */
import React, {useEffect, useRef} from 'react'
import {animate} from 'framer-motion'
import {addComma} from 'lib/util'

type Props = {
  from: number
  to: number
  point?: number
  duration?: number
  style?: React.CSSProperties //----------------- StyleSheet
  [key: string]: any
}
export default function CountUp({from, to, duration, style, point}: Props) {
  // hooks
  const nodeRef = useRef() as any
  const _style = Object.assign({}, {display: 'inline-block', textAlign: 'right'}, !!style && style) as any
  //*--------------------------------------------------*

  useEffect(() => {
    const node = nodeRef.current as any
    const controls = animate(from, to, {
      duration: duration || 0.5,
      onUpdate(value) {
        // node.textContent = Math.floor(value).toLocaleString()
        // if (point === 0) node.textContent = addComma(Math.floor(value))
        if (point === 0) node.textContent = addComma(value, 0)
        else if (point === 1) node.textContent = addComma(value, 1)
        else node.textContent = addComma(value.toFixed(point || 2))
      },
    })

    return () => controls.stop()
  }, [from, to, duration])

  return <span style={_style} ref={nodeRef} />
}
