/**
 * @name Routes
 */
import {Route, Routes, BrowserRouter} from 'react-router-dom'
import {MenuRoutes} from 'config'
import {uuid} from 'lib'
import {MenuRoutesType} from 'types'

export default function Router() {
  // initalize
  return (
    <BrowserRouter basename="/">
      <Routes>
        {MenuRoutes.map((item: MenuRoutesType) => {
          return <Route key={uuid()} path={item.path} element={item.element} />
        })}
      </Routes>
    </BrowserRouter>
  )
}
/*===================================================================[계정관리] */
/*
[메뉴추가]

/src/config/menu.ts
  - MENU에 메뉴추가

/src/config/menu-routes.ts
  - MenuRoutes에 path 설정및 element 연결
*/
