/**
 * @name 뱃지
 * @description
 */
import styled from 'styled-components'
import {CSS} from 'types'
import {COLOR} from 'styles'

type Props = {
  children?: React.ReactNode //------------------ children
  bg?: string //--------------------------------- 배경색상
  color?: string //------------------------------ 텍스트색상
  label?: string //------------------------------ label
  style?: React.CSSProperties //----------------- StyleSheet
  mode?: string //------------------------------- 테마모드
  [key: string]: any
}
export default function Badge({children, bg, color, mode, label, style}: Props) {
  // hooks
  let _theme
  if (mode === 'Primary') _theme = {color: COLOR.WHITE, backgroundColor: COLOR.BLUE3}
  if (mode === 'Primary1') _theme = {color: COLOR.BLUE0, backgroundColor: COLOR.BLUE8}
  if (mode === 'Secondary') _theme = {color: COLOR.WHITE, backgroundColor: COLOR.GRAY3}
  if (mode === 'Secondary1') _theme = {color: COLOR.GRAY3, backgroundColor: `rgba(178, 178, 178, 0.15)`}
  if (mode === 'Success') _theme = {color: COLOR.WHITE, backgroundColor: COLOR.GREEN2}
  if (mode === 'Success1') _theme = {color: COLOR.GREEN2, backgroundColor: `rgba(64, 201, 151, 0.15)`}
  if (mode === 'Danger') _theme = {color: COLOR.WHITE, backgroundColor: COLOR.RED2}
  if (mode === 'Danger1') _theme = {color: COLOR.RED2, backgroundColor: `rgba(255, 91, 92, 0.15)`}
  if (mode === 'Warning') _theme = {color: COLOR.WHITE, backgroundColor: COLOR.YELLOW2}
  if (mode === 'Warning1') _theme = {color: COLOR.YELLOW2, backgroundColor: `rgba(246, 174, 63, 0.15)`}
  if (mode === 'Info') _theme = {color: COLOR.WHITE, backgroundColor: COLOR.BLUE5}
  if (mode === 'Info1') _theme = {color: COLOR.BLUE5, backgroundColor: `rgba(130, 165, 237, 0.15)`}
  if (mode === 'Dark') _theme = {color: COLOR.WHITE, backgroundColor: COLOR.BLACK3}
  if (mode === 'Dark1') _theme = {color: COLOR.BLACK3, backgroundColor: `rgba(55, 55, 55, 0.15)`}

  const _bg = bg && ({backgroundColor: bg} as CSS)
  const _color = color && ({color: color} as CSS)
  const _style = Object.assign({}, _theme, _color, _bg, !!style && style)
  //*--------------------------------------------------*
  return (
    <Content className="badge" style={_style}>
      {!!label && label}
      {children}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.span`
  position: relative;
  display: inline-block;
  text-align: center;
  height: 24px;
  padding: 3px 13px;
  border-radius: 4px;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.18px;
`
/********************************************************
사용법

<Badge label="Badge" mode="Primary" />
<Badge label="Badge" mode="Secondary" />
<Badge label="Badge" mode="Success" />
<Badge label="Badge" mode="Danger" />
<Badge label="Badge" mode="Warning" />
<Badge label="Badge" mode="Info" />
<Badge label="Badge" mode="Dark" />
<Badge label="Badge" mode="Primary1" />

<Badge label="Badge" mode="Secondary1" />
<Badge label="Badge" mode="Success1" />
<Badge label="Badge" mode="Danger1" />
<Badge label="Badge" mode="Warning1" />
<Badge label="Badge" mode="Info1" />
<Badge label="Badge" mode="Dark1" />

*********************************************************/
