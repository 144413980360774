/**
 * @name 대시보드
 * @description
 */
import {App} from 'template'
// contents
import {Widget} from './contents'
import {Header, Footer} from './common'

export default function Presenter() {
  return (
    <App padding={'0'} overflow="hidden">
      <div>
        {/* 헤더영역 */}
        <Header />
        {/* 위젯모음 */}
        <Widget />
        {/* 푸터영역 */}
        <Footer />
      </div>
    </App>
  )
}
