/**
 * @name 차트컴포넌트
 * @description
 */
import {useEffect, useRef, useState} from 'react'
import {Text} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
import * as echarts from 'echarts'
import {useEventListener} from 'usehooks-ts'
// contents
import {_event} from 'pages/use'

type Props = {
  title: string
  data: any
  yAxisName?: string
  [key: string]: any
}
export default function Contents({title, data, yAxisName}: Props) {
  // hooks
  const chartRef = useRef(null)
  const [chartElement, setChartElement] = useState<echarts.ECharts>()
  const option = {
    tooltip: {
      trigger: 'axis',
    },
    // 색상추가
    color: [COLOR.blue30, COLOR.green30, COLOR.yellow30, COLOR.red30],
    legend: {
      bottom: 0,
      data: [],
    },
    grid: {
      top: '10%',
      left: '30px',
      right: '50px',
      bottom: '20px',
      containLabel: true,
    },
  }
  const [options, setOptions] = useState(Object.assign({}, option, {})) as any
  //*--------------------------------------------------*
  const handleSize = () => {
    !!chartElement && chartElement?.resize()
  }
  useEventListener('resize', handleSize)
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current)
      setChartElement(chart)
      chart.setOption(options)
      // Event listener callback
      const handleClick = (params: any) => {
        console.log(params)
      }
      // Bind the event listener
      chart.on('click', handleClick)
    }
    return () => {}
  }, [options, chartRef])

  useEffect(() => {
    const _series = data?.y.map((item: any, index: number) =>
      Object.assign({}, item, {
        // smooth: true,
        smooth: true,
        areaStyle: {
          color: [COLOR.blue30, COLOR.green30, COLOR.yellow30, COLOR.red30][index],
          opacity: 0.1,
          // shadowColor: 'rgba(0, 0, 0, 0.1)',
          // shadowBlur: 10,
        },
      }),
    ) as any

    setOptions(
      Object.assign({}, options, {
        label: {
          color: '#333',
          fontSize: 5,
          fontFamily: 'Arial',
          borderWidth: 3,
          formatter: yAxisName,
        },
        xAxis: {
          type: 'category',
          name: '',
          boundaryGap: false,
          data: data?.x.map(function (item: any) {
            return item + '시'
          }),
          axisTick: {
            show: true,
            alignWithLabel: true, //! 눈금선과 라벨의 정렬
            lineStyle: {
              type: 'dashed',
              width: 1,
            },
          },
          axisLabel: {
            align: 'left',
            margin: 10,
          },
          splitLine: {
            show: true, // Display grid lines
            lineStyle: {
              type: 'dashed', // Use 'dashed' for dotted lines
            },
          },
        },
        yAxis: {
          splitNumber: 4,
          type: 'value',
          name: yAxisName || 'kVA',
          nameLocation: 'end',
          nameTextStyle: {
            color: '#373737',
            padding: [0, 40, 10, 0],
          },
          axisLine: {
            lineStyle: {
              color: COLOR.gray30,
            },
          },
          splitLine: {
            show: true, // Display grid lines
            lineStyle: {
              type: 'dashed', // Use 'dashed' for dotted lines
            },
          },
        },
        series: _series,
      }),
    )
    return () => {}
  }, [data])
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 타이틀영역 */}
      <Text mode="Title_LB" color={COLOR.gray30} label={title} />
      {/* 차트영역 */}
      <div
        ref={chartRef}
        style={{
          width: '96%',
          height: '426px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
    </Content>
  )
}

//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 506;
  /* 차트영역 */
  .Title_LB {
    display: flex;
    align-items: center;
    height: 80px;
  }
`
