/**
 * @name 모듈(Module)
 * @description
 */
import React, {useEffect, useState} from 'react'
import {del, edit, round_yellow} from 'assets/svg'
import {Events, Flex, Icon, Image, ImageButton, Text, uuid} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {_event} from 'pages/equipment_setting'
import {status_error, status_on} from 'assets/images'
// contents

type Props = {
  item: any
  index: number
  total: number
}
export default function Contents({item, total, index}: Props) {
  // hooks
  const [visible, setVisible] = useState(false)
  //*--------------------------------------------------*
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <Content key={uuid()} className="module-item">
      <td className="cell-line" />
      {/* <td className={'cell-start'}></td> */}
      <td className={total - 1 === index ? 'cell-end' : 'cell-start'}></td>
      <td>
        <button
          className="toggle"
          onClick={() => {
            setVisible(!visible)
          }}>
          <Icon src={round_yellow} width="14px" height="14px" style={{marginLeft: 10, marginRight: 5}} />
          <Text mode="Body_MM" color={COLOR.gray40}>
            모듈
          </Text>
        </button>
      </td>
      {/* 장비명 */}
      <td className="">
        <Text mode="Body_MM_underline" color={COLOR.gray40} label={item?.name || '-'} />
      </td>
      {/* 장비상태 */}
      <td>
        {item?.status === 'OK' && <Image src={status_on} style={{width: 48, height: 32, verticalAlign: 'middle'}} />}
        {item?.status === 'ERROR' && <Image src={status_error} style={{width: 37, height: 32, verticalAlign: 'middle'}} />}
        {item?.status === 'UNKNOWN' && <span>-</span>}
      </td>
      {/* 모델명 */}
      <td>
        <Text mode="Body_MM" color={COLOR.gray40} label={item?.factoryDeviceId || '-'} />
      </td>
      {/* 제품번호 (S/N) */}
      <td>
        <Text mode="Body_MM" color={COLOR.gray40} label={item?.sn || '-'} />
      </td>
      {/* 부착위치 */}
      <td>
        <Text mode="Body_MM" color={COLOR.gray40} label={item?.instLocation || '-'} />
      </td>
      {/* 최대용량 */}
      <td>
        <Text mode="Body_MM" color={COLOR.gray40} label={item?.maximumCapacity || '-'} />
      </td>
      {/* 정격용량 */}
      <td>
        <Text mode="Body_MM" color={COLOR.gray40} label={item?.ratedCapacity || '-'} />
      </td>
      {/* IP */}
      {/* <td>
        <Text mode="Body_MM" color={COLOR.gray40} label={item?.ipAddress || '-'} />
      </td> */}
      {/* Port */}
      {/* <td>
        <Text mode="Body_MM" color={COLOR.gray40} label={item?.port || '-'} />
      </td> */}
      {/* MAC */}
      {/* <td>
        <Text mode="Body_MM" color={COLOR.gray40} label={item?.macAddress || '-'} />
      </td> */}
      {/* 수정&삭제 */}
      {/* <td>
        <Flex alignitems="center" justifycontent="center">
          <ImageButton
            src={del}
            onClick={() => {
              Events.emit(_event.DEVICES_DELETE, item)
            }}
          />
        </Flex>
      </td> */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.tr``
