/**
 * @name Content
 * @description
 */
import {Flex} from 'lib'
import styled from 'styled-components'

export default function Contents() {
  // hooks

  //*--------------------------------------------------*
  return (
    <Content>
      <Flex>
        <p>code2</p>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  margin: 20px 0;
  padding: 20px;
  border: 1px dashed #111;
  /**-------본문--*/
  .wrap {
  }
`
