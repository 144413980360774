/**
 * @name Presenter
 * @description
 */
import {useEffect} from 'react'
import {App} from 'template'
import {Panel} from 'components'
import {Store} from '.'
import {Text, log, useContext} from 'lib'
// contents
import {Header, Loading, Top} from './common'
import {Diagram, DiagramTr, ChartItem} from './contents'

type Props = {
  isChart?: boolean //--------------------------------- 차트데이터
  isInfo?: boolean //---------------------------------- 데이타
}
export default function Presenter({isChart, isInfo}: Props) {
  // hooks
  const {chart} = Store()
  const {factoryType} = useContext()

  //*--------------------------------------------------*
  useEffect(() => {}, [])
  return (
    <App>
      <div className="container-height">
        <Panel padding="24px" header={<Header />}>
          {/* 계통 구성도 */}
          <Text mode="Title_LB" label={'계통 구성도'} />
          {/* 용해로 */}
          {factoryType() === 'furnace' && (isInfo ? <Diagram /> : <Loading />)}
          {/* 태양광 */}
          {factoryType() === 'solar' && (isInfo ? <DiagramTr /> : <Loading />)}
          {/* 차트 */}
          {isChart ? (
            <div>
              <Top />
              {/* 용해로온도 furnaceChart*/}
              {factoryType() === 'furnace' && <ChartItem title={'용해로 온도'} data={chart?.furnaceChart} yAxisName="℃" />}
              {/* 전류 고조파 왜형률 distortionRateChart*/}
              <ChartItem title={'전류 고조파 왜형률'} data={chart?.distortionRateChart} yAxisName="%" />
              {/* 수요 현황 currentVoltageChart */}
              <ChartItem title={'수요 현황'} data={chart?.currentVoltageChart} yAxisName="kVA" />
              {/* 전류 현황 electricCurrentChart*/}
              <ChartItem title={'전류 현황'} data={chart?.electricCurrentChart} yAxisName="A" />
              {/* ACELO Solution aceloSolutionChart */}
              <ChartItem title={'ACELO Solution'} data={chart?.aceloSolutionChart} yAxisName="kVA" />
              {/* 전압 예측 정확도 voltagePredictionChart */}
              <ChartItem title={'전압 예측 정확도'} data={chart?.voltagePredictionChart} yAxisName="%" />
            </div>
          ) : (
            <Loading />
          )}
        </Panel>
      </div>
    </App>
  )
}
