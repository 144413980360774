/**
 * @name Auth,계정관리
 * @description
 */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
// 모드변경
export const MODE = {}
// 유저정보,팝업타입
export const POPUP = {}
// export
export const EVENT = {
  LOGIN_SUBMIT: 'LOGIN_SUBMIT',
  LOGOUT_INIT: 'LOGOUT_INIT',
}
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  isSubmitError: boolean
  isLogin: boolean | null
  [key: string]: any
}
//*--------------------------------------------------*
/** @initialState */
export const sliceName = 'auth' //변경필요
const initialState: StateType = {
  isLogin: false,
  isSubmitError: false,
}
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // 초기화
    setInit(state: StateType) {},
    // setData
    setLogin(state: StateType, action: PayloadAction<any>) {
      state.isLogin = action.payload
    },
    // setSubmitError
    setSubmitError(state: StateType, action: PayloadAction<any>) {
      state.isSubmitError = action.payload
    },
    // setMode
    setMode(state: StateType, action: PayloadAction<any>) {
      state.mode = action.payload
    },
  },
})
//*--------------------------------------------------*

export default slice.reducer
export const nameSpace = sliceName
export const actions = slice.actions
