/**
 * @name TextInput
 * @description 입력창
 */
import React from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'

type Props = {
  className?: string //--------------------------- 클래스네임
  children?: React.ReactNode //--------------------Children
  label?: string //------------------------------- Text
  style?: React.CSSProperties //------------------ StyleSheet
  placeholder?: string //------------------------- placeholder
  disabled?: boolean //--------------------------- disabled
  onSubmit?: (str: string) => void //------------- onSubmit
  setValue?: (str: string) => void //------------- setValue
  onChange?: (obj: any) => void //---------------- onChange Event
  onValue?: (obj: any) => void //---------------- onChange Event
  onBlur?: (obj: any) => void //------------------ onBlur Event
  [key: string]: any
}
export default function TextInput({disabled, children, className, onValue, onChange, setValue, onBlur, onSubmit, label, ...props}: Props) {
  return (
    <Element
      {...props}
      disabled={disabled}
      className={`${className ?? ''}`}
      onBlur={(event: any) => {
        !!onBlur && onBlur(event)
      }}
      onChange={(event: any) => {
        !!setValue && setValue(event.target.value)
        !!onChange && onChange(event)
        !!onValue && onValue(event.target.value)
      }}
      onKeyUp={(event: any) => {
        if (disabled) return false
        if (event.key === 'Enter') {
          // ENTER키적용
          !!onSubmit && onSubmit(event.currentTarget.value)
        }
      }}
    />
  )
}
//*--------------------------------------------------*
const Element = styled.textarea`
  /* input클래스적용시 기본스타일작동 */
  display: flex;
  min-width: 200px;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid ${COLOR.GRAY4};
  outline: 0;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.1px;
  /* 마우스호버 */
  &:hover {
    border-color: ${COLOR.GRAY1};
  }
  /* 포커스인 */
  &:focus {
    border-color: ${COLOR.BLUE3};
    outline: 0;
    outline-color: ${COLOR.BLUE3};
  }
  /* 비활성화 */
  &:disabled {
    border: 0;
    color: ${COLOR.GRAY3};
    background-color: ${COLOR.GRAY6};
  }
  /* 유효성검사 */
  &.invalid {
    border-color: ${COLOR.RED1} !important;
  }
  /* 플레이스홀더 */
  &::placeholder {
    color: ${COLOR.GRAY2};
  }
`
/********************************************************
[사용법]

<input
  disabled
  type="text"
  name="patternValue"
  value={inputVal}
  onKeyDown={(event:any) => {
    const {key} = event.nativeEvent
    console.log(key)
  }}
  onFocus={({target}) => {
    console.log(target.value)
  }}
  onBlur={({target}) => {
    console.log(target.value)
  }}
  onChange={(event:any)=> {
    const {value} = event.target
    // const number = /^[0-9]*$/ //------------------------ 숫자만입력
    const engNum = /^[a-zA-Z0-9]*$/ //------------------ 영문,숫자만입력
    // const email = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+$/ //------ 이메일
    // const phone = /^\d{2,3}-\d{3,4}-\d{4}$/ //---------- 전화번호
    console.log(engNum.test(value))
    return engNum.test(value) && setInputVal(value)
  }}
/>
*********************************************************/
