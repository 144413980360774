/**
 * @name DropDown
 * @description
 */
import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {List, Text, useClickOutside, uuid} from 'lib'
import {CSS, ListRenderItem} from 'types'
import {FaChevronDown, FaChevronUp} from 'assets/icon'
import {COLOR} from 'styles'

type ItemProps = {
  label?: string | any
  value?: any
  [key: string]: any
}
type Props = {
  info: ItemProps[] //----------------------------------------------- 정보
  label?: string //------------------------------------------------- 라벨
  disabled?: boolean //---------------------------------------------- 비활성화
  onChange?: (item: ItemProps) => void //---------------------------- onChange변화값
  style?: CSS //----------------------------------------------------- inputStyleSheet
  layerStyle?: CSS //------------------------------------------------ 레이어팝업 StyleSheet
  visible?: boolean //----------------------------------------------- 레이어팝업 show/hide 외부에서컨트롤
  renterItem?: (item: ListRenderItem) => React.ReactNode //----------- 렌더링 아이템
  // [key: string]: any
}
export default function DropDown({info, label, disabled, visible = false, onChange, renterItem, style, layerStyle}: Props) {
  // hooks
  const eleRef = useRef(null)
  const [selected, setSelected] = useState(0)
  const [isBool, setIsBool] = useState(false)
  // outside click
  useClickOutside(eleRef, () => {
    setIsBool(false)
  })
  //*--------------------------------------------------*
  useEffect(() => {
    if (visible === undefined) return
    setIsBool(visible)
  }, [visible])
  //*--------------------------------------------------*
  const renderItem = ({item, index}: ListRenderItem) => {
    return (
      <button
        key={uuid()}
        className="cursor item"
        onClick={() => {
          !!onChange && onChange(item)
          setSelected(index)
          setIsBool(false)
        }}>
        <Text mode="Body_MM">{!!label ? item[label] : item.label}</Text>
        {/* <label className="body4">{!!label ? item[label] : item.label}</label> */}
      </button>
    )
  }
  //*--------------------------------------------------*
  return (
    <Element ref={eleRef}>
      <button
        disabled={disabled}
        className="selected"
        style={style ?? {}}
        onClick={() => {
          setIsBool(!isBool)
        }}>
        <Text mode="Body_MM">{!!label ? info[selected][label] : info[selected].label}</Text>
        {/* <label className="body4">{!!label ? info[selected][label] : info[selected].label}</label> */}
        <div className="icon">{isBool ? <FaChevronUp size={12} color={COLOR.GRAY2} /> : <FaChevronDown size={12} color={COLOR.GRAY2} />}</div>
      </button>
      {/* 레이어팝업 */}
      {isBool && (
        <article className="popup" style={layerStyle ?? {}}>
          <List info={info} renderItem={renterItem ?? renderItem} />
        </article>
      )}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  /* min-width: 140px; */
  /* width: 100%; */
  align-items: center;
  /* <!--버튼--> */
  button.selected {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid ${COLOR.GRAY4};
    border-radius: 7px;
    background-color: ${COLOR.WHITE};
    &:disabled {
      border-color: ${COLOR.GRAY4};
      background-color: ${COLOR.GRAY6};
    }
    label {
      color: ${COLOR.gray30};
    }
  }
  .icon {
  }
  /* <!--레이어팝업--> */
  .popup {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 1;
    width: 100%;
    /* padding: 10px; */
    box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
    border-radius: 6px;
    background-color: ${COLOR.WHITE};
  }
  /* <!--아이템--> */
  .item {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    &:hover {
      background-color: #f5f5f5;
    }
    label {
      color: ${COLOR.gray30};
    }
  }
`
/********************************************************
[사용법]

  const ui_info = [
    {label: '라벨1', value: 'num1'},
    {label: '라벨2', value: 'num2'},
    {label: '라벨3', value: 'num3'},
  ]
        <DropDown
          style={{marginBottom: 30}}
          info={ui_info}
          onChange={data => {
            console.log(data)
          }}
          // layerStyle={{ top: 50 }}
        />
*********************************************************/
