/**
 * @name 위젯
 * @description
 */
import {furnace_type_1, furnace_type_2, furnace_type_3, furnace_type_4} from 'assets/svg'
import {CountUp, Icon, Text, randomNumber} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents

type Props = {
  info: any
}
export default function Contents({info}: Props) {
  // hooks
  const data = Math.floor(Number(info?.data))

  const setCheck = () => {
    if (data >= 75) return {furnace_type: furnace_type_1, y: 20, color: COLOR.white} //넘침
    else if (data >= 50) return {furnace_type: furnace_type_2, y: 15, color: COLOR.white} //여유
    else if (data >= 25) return {furnace_type: furnace_type_3, y: 35, color: COLOR.yellow30} //충전
    else return {furnace_type: furnace_type_4, y: 30, color: COLOR.red30} //부족
  }

  //*--------------------------------------------------*
  return (
    <Content>
      {/* {setContents()} */}
      <Icon src={setCheck().furnace_type} width="156px" height="140px" />
      <div className="txt" style={{bottom: setCheck().y}}>
        <Text mode="Headline_LB" color={setCheck().color} style={{marginRight: 4}}>
          <CountUp point={0} from={Number(data) - randomNumber(10, 20)} to={Number(data)} />
          <span>%</span>
        </Text>
      </div>
      {/* //*--------------------------------------------------* */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  /*--------------*/
  .txt {
    position: absolute;
    bottom: 10px;
    color: #fff;
  }
`
