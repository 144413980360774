/**
 * @name 대시보드
 * @description
 */

import {useAppSelector} from 'model'
import {actions, MODE, EVENT, POPUP} from 'model/modules/dashboard'
export {default} from './container'
//*--------------------------------------------------*
/**
 * @name config
 * @description Redux Action, Mode, Event, Popup
 */
export const Store = () => useAppSelector(state => state.dashboard)
export const _action = actions
export const _mode = MODE
export const _event = EVENT
export const _popup = POPUP

/********************************************************

*********************************************************/
