/**
 * @name 위젯
 * @description
 */
import {useEffect, useRef, useState} from 'react'
import * as echarts from 'echarts'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {useEventListener} from 'usehooks-ts'
// contents

export default function Contents({chart}: any) {
  // hooks
  const chartRef = useRef(null)
  const [chartElement, setChartElement] = useState<echarts.ECharts>()

  const option = {
    tooltip: {
      trigger: 'axis',
    },
    // 색상추가
    color: [COLOR.blue30, COLOR.green30],
    grid: {
      top: '25%',
      left: '0%',
      right: '20%',
      bottom: '0%',
      containLabel: true,
    },
  }
  const [options, setOptions] = useState(Object.assign({}, option, {})) as any
  const handleSize = () => {
    !!chartElement && chartElement?.resize()
  }
  useEventListener('resize', handleSize)
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current)
      setChartElement(chart)
      chart.setOption(options)
      const handleClick = (params: any) => {}
      chart.on('click', handleClick)
    }
    return () => {}
  }, [options, chartRef])
  useEffect(() => {
    setOptions(
      Object.assign({}, options, {
        xAxis: {
          type: 'category',
          name: '시간',
          splitNumber: 2,
          nameLocation: 'end',
          boundaryGap: true,
          data: chart?.x,
          axisTick: {
            show: true,
            alignWithLabel: true, //! 눈금선과 라벨의 정렬
            lineStyle: {
              type: 'dashed',
              width: 1,
            },
          },
        },
        yAxis: {
          splitNumber: 2,
          // scale: true,
          type: 'value',
          name: 'V',
          nameLocation: 'end',
          nameTextStyle: {
            color: '#373737',
            padding: [0, 25, 0, 0],
          },
          axisLine: {
            lineStyle: {
              color: '#373737',
            },
          },
        },
        series: chart.y,
      }),
    )
    return () => {}
  }, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <div
        ref={chartRef}
        style={{
          width: '96%',
          height: '120px',
          marginBottom: 30,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 100%;
  /*--------------*/
`
