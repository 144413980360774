/**
 * @name CheckBox
 * @description
 */
import styled from 'styled-components'
import {COLOR} from 'styles'
import {motion} from 'framer-motion'

type Props = {
  children?: React.ReactNode //-------------------Children
  value?: boolean //----------------------------- 초기값설정
  style?: React.CSSProperties //----------------- StyleSheet
  onChange?: (value: boolean) => void
  [key: string]: any
}
export default function CheckBox({children, onChange, value, style}: Props) {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content
      style={style}
      className={`row ${value ? 'active' : ''}`}
      onClick={() => {
        !!onChange && onChange(!value)
      }}>
      {/* 라운드테두리 */}
      <div className="bg" />
      {/* 활성화표시 */}
      {value && (
        <motion.div
          initial={{opacity: 0, scale: 0}}
          animate={{opacity: 1, scale: 1}}
          transition={{
            default: {
              duration: 0.1,
              ease: 'easeOut',
            },
            scale: {
              type: 'spring',
              damping: 5,
              stiffness: 100,
              restDelta: 0.001,
            },
          }}
          className="cursor"
        />
      )}
      {!!children && <label className="button4">{children}</label>}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.button`
  position: relative;
  height: 16px;
  .bg {
    width: 16px;
    height: 16px;
    border: 1px solid ${COLOR.GRAY1};
    border-radius: 16px;
  }
  .cursor {
    position: absolute;
    left: 4px;
    top: 4px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: ${COLOR.BLUE4};
  }
  label {
    cursor: pointer;
    margin-left: 6px;
  }
`
/********************************************************
        <CheckBox>체크박스 라벨</CheckBox>

const checkList = [0, 1, 2, 3]
const [select, setSelect] = useState(0)

{checkList.map((list, index) => {
  return (
    <CheckBox
      style={{margin: 10}}
      onChange={() => {
        setSelect(index)
      }}
      value={select === index ? true : false}>
      체크박스{index}
    </CheckBox>
  )
})}
{debug(select)}
*********************************************************/
