/**
 * @name useInterval
 */

import {useEffect, useRef} from 'react'

export default function useInterval(callback: () => void, delay: number | null) {
  // hooks
  const ref = useRef() as any
  //*--------------------------------------------------*
  // Remember the latest function.
  useEffect(() => {
    ref.current = callback
  }, [callback])
  //*--------------------------------------------------*
  // Set up the interval.
  useEffect(() => {
    function tick() {
      ref.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
/********************************************************
[사용법]
import { useInterval} from 'lib'

const [count, setCount] = useState(0)
const [delay, setDelay] = useState(1000)
const [isRunning, setIsRunning] = useState(true)

useInterval(
  () => {
    setCount(count + 1)
  },
  isRunning ? delay : null,
)

<h1>{count}</h1>
<input type="checkbox" checked={isRunning} onChange={e => setIsRunning(e.target.checked)} /> Running
<br />
<input value={delay} onChange={e => setDelay(Number(e.target.value))} />

*********************************************************/
