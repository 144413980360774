/**
 * @name Footer
 * @description
 */
import {Text} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'

export default function Contents() {
  return (
    <Content>
      <Text color={COLOR.gray70} mode="Caption_MR">
        © 2023 ㈜크로커스 All rights reserved
      </Text>
      {/* <Row>
          <TextButton
            label="이용약관"
            style={{marginRight: 20, color: COLOR.GRAY2}}
            className="body6"
            onClick={() => {
              navigate(MENU.POLICY_PRIVACY, {state: {path: MENU.POLICY_PRIVACY, active: 0}})
            }}
          />
          <TextButton
            label="개인정보 처리방침"
            style={{color: COLOR.GRAY2}}
            className="body6"
            onClick={() => {
              navigate(MENU.POLICY_PRIVACY, {state: {path: MENU.POLICY_PRIVACY, active: 1}})
            }}
          />
        </Row> */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  // styles
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 48px;
  padding-left: 24px;
  padding-right: 24px;

  /* margin-left: 24px; */
  /* margin-bottom: 30px; */
`
