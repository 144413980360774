/**
 * @name Top
 */
import styled from 'styled-components'
import {COLOR} from 'styles'
import {Dayjs, Flex, Icon, Text, useContext, useRoutes} from 'lib'
import {MENU} from 'config'
import {FaUser} from 'assets/icon'
import {logoImage} from 'pages/common'
import {useEffect, useState} from 'react'
// contents

export default function Top() {
  // hooks
  const [time, setTime] = useState(Dayjs().format('YY.MM.DD HH:mm.ss'))
  const {navigate} = useRoutes()
  const {user, availableMenu, userId, factoryName} = useContext()
  // 프로필세팅메뉴
  const filter = (!!availableMenu && availableMenu?.filter((item: any) => item.menu === 'PROFILE_SETTING')[0]) || null
  //*--------------------------------------------------*
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Dayjs().format('YY.MM.DD HH:mm.ss'))
    }, 1000)
    return () => clearInterval(interval)
  }, [])
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex justifycontent="center" alignitems="center">
        {process.env.REACT_APP_MODE === 'STAGING' && (
          <div className="dev">
            <Text mode="Body_MM" color={COLOR.gray30} style={{marginRight: 20}}>
              [개발 및 스테이징]
            </Text>
          </div>
        )}
        {/* <Text mode="Body_MM" color={COLOR.gray30}>
          평택시 청북읍 / 24°
        </Text>
        <Text mode="Body_MM" color={COLOR.gray30} style={{marginLeft: 10, marginRight: 10}}>
          맑음
        </Text> */}
        <Text mode="Body_MM" color={COLOR.gray50} style={{width: 120}}>
          {time}
          {/* 2023.4.19 14:39 */}
        </Text>
        {/* 회사명 */}
        <button className="btn">
          <img src={logoImage(userId)} alt={userId} style={{marginRight: 10, maxHeight: 31}} />
          {/* <Icon src={logoImage(userId)} width={'44px'} height={'44px'} /> */}
          <label>{factoryName}</label>
        </button>
        {/* 알림 */}
        {/* <button
          className="btn"
          onClick={() => {
            navigate(MENU.NOTIFICATION)
          }}>
          <Icon src={top_notification} width={'44px'} height={'44px'} />
        </button> */}
        {/* 프로필 */}
        <button
          className="round"
          onClick={() => {
            navigate(MENU.PROFILE)
            // filter?.available && navigate(MENU.PROFILE)
          }}>
          <FaUser color={COLOR.GRAY1} />
        </button>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  /*-- 사이트맵 --*/
  button {
    margin-left: 20px;
    &.btn {
      display: flex;
      /* width: 200px; */
      align-items: center;
      overflow: hidden;
    }
    &.round {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: ${COLOR.GRAY4};
    }
  }
`
