/**
 * @name App
 * @description
 */
import React, {useEffect} from 'react'
import styled from 'styled-components'
import {layout} from 'styles'
import {SESSION_STORAGE, isEmptyObject, useContext} from 'lib'
import {CSS} from 'types'
import {useAppDispatch, useAppSelector} from 'model'
import {signIn} from 'pages/common'
import {MENU} from 'config'
// components
import Footer from './footer'
import Top from './top'
import Gnb from './gnb/index'

type Props = {
  loading?: boolean | null // 로딩중
  padding?: number | string // 패딩
  style?: CSS // 스타일
  children?: React.ReactNode | JSX.Element[] | undefined
  overflow?: 'hidden' | 'auto'
  [key: string]: any
}
export default function App({children, padding, style, overflow}: Props) {
  // hooks
  const {navigate} = useContext()
  const {user} = useContext()
  const dispatch = useAppDispatch()
  const {isExpand} = useAppSelector(state => state.gnb)
  //*--------------------------------------------------*
  // refreshLogin
  async function refreshLogin() {
    const _email = await sessionStorage.getItem(SESSION_STORAGE.USER_ID)
    if (_email === null) {
      navigate(MENU.SIGN_OUT)
      return
    }
    const _password = await sessionStorage.getItem(SESSION_STORAGE.PASSWORD)
    if (_password === null) {
      navigate(MENU.SIGN_OUT)
      return
    }
    const res = await signIn({data: {userId: _email, password: _password}, dispatch: dispatch})
    if (res?.status === 'fail') {
      navigate(MENU.SIGN_OUT)
      return
    } else {
    }

    // 로그인갱신
  }
  //*--------------------------------------------------*
  useEffect(() => {
    if (isEmptyObject(user)) refreshLogin()
  }, [])
  //*--------------------------------------------------*
  return (
    <Content style={style} overflow={overflow} padding={padding} className={layout.contents}>
      {/* gnb */}
      {isExpand && (
        <aside className={layout.gnb}>
          <Gnb />
        </aside>
      )}
      {/* container */}
      <article className={layout.container}>
        {/* top */}
        <div className={layout.top}>
          <Top />
        </div>
        {/* main */}
        <main className={`children ${layout.children}`}>{children}</main>
        {/* footer */}
        <footer>
          <Footer />
        </footer>
      </article>
    </Content>
  )
}
const Content = styled.main<Props>`
  /* 컨텐츠 */
  display: flex;
  flex-direction: row;
  /* article */
  article > main.children {
    box-sizing: border-box;
    padding: ${({padding}) => padding || '20px'};
    /* height: var(--CONTAINER_HEIGHT); */ /* 패널형태 */
  }
`
/********************************************************
[사용법]
<App padding={'100px 20px'}>
...
</App>
*********************************************************/
