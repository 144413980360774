/**
 * @name 설비(Facility)
 * @description
 */
import React, {useEffect} from 'react'
import {Table, uuid} from 'lib'
import {FacilityProps, ListRenderItem} from 'types'
// contents
import Item from './item'

type Props = {
  item: {
    children: FacilityProps[]
    factoryDeviceType: string
    [key: string]: any
  }
}
export default function Contents({item}: Props) {
  useEffect(() => {}, [])
  //*--------------------------------------------------*
  return (
    <Table
      info={item?.children}
      renderHeader={() => {
        return (
          <tr key={uuid()}>
            <th style={{width: 100}}>구분</th>
            <th>설비명</th>
            <th>설비 상태</th>
            <th>설비 ID</th>
            <th>설비 위치</th>
            <th style={{width: 151}}>최대용량</th>
            <th style={{width: 151}}>정격용량</th>
            {/* <th style={{width: 96}}>삭제</th>
            <th style={{width: 96}}>장비추가</th> */}
          </tr>
        )
      }}
      renderItem={({item}: ListRenderItem) => <Item item={item} />}
    />
  )
}
