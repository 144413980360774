/**
 * @name Container
 */
import {useEffect, useState} from 'react'
import {Dayjs, Events, useAxios} from 'lib'
import {useAppDispatch} from 'model'
// contents
import {Store, _action, _event} from '.'
import Presenter from './presenter'
import {API} from 'config'
import {gnbAction} from 'model/modules'

export default function Container() {
  // hooks
  const dispatch = useAppDispatch()
  const {get} = useAxios()
  const {date} = Store()
  const [isChart, setIsChart] = useState(false)
  const [isData, setIsData] = useState(false)
  //*--------------------------------------------------*
  // 초기화
  async function onInit() {
    const dayjs = Dayjs().format('YY.MM.DD HH:MM') + '분 갱신'
    dispatch(_action.setReloadTime(dayjs))
    dispatch(gnbAction.setNavi({depth1: 2, depth2: -1}))
    onChart(date?.type)
    onData(date?.type)
  }
  // 날짜변경
  async function onDate(data: any) {
    const dayjs = Dayjs().format('YY.MM.DD HH:MM') + '분 갱신'
    dispatch(_action.setReloadTime(dayjs))
    dispatch(_action.setDate(data))
    onChart(data?.type)
    onData(data?.type)
  }
  // 차트변경
  async function onChart(type: string) {
    const res = await get(`${API}/data/solar-powers/chart?period=${type}`)
    if (res?.success) {
      dispatch(_action.setChart(res.data))
      setIsChart(true)
    } else {
    }
  }
  // 데이타변경
  async function onData(type: string) {
    const res = await get(`${API}/data/solar-powers/data?period=${type}`)
    if (res?.success) {
      dispatch(_action.setData(res.data))
      setIsData(true)
    } else {
    }
  }
  //*--------------------------------------------------*
  useEffect(() => {
    onInit()
    // addListener
    Events.addListener(_event.SELECT_DATE, onDate)
    return () => {
      // removeListener
      Events.removeListener(_event.SELECT_DATE, onDate)
    }
  }, [])

  return <Presenter isChart={isChart} isData={isData} />
}
