/**
 * @name 로그인(이메일,비밀번호)
 * @description
 */
import {Button, Events, H5, Icon, Image} from 'lib'
import styled from 'styled-components'
import {COLOR, ui} from 'styles'
import {TextInput, TextInputPassword} from 'components/form'
import {useEffect, useState} from 'react'
// contents
import {Store, _action, _event} from '..'
import {auth_submit_error, eye_off, eye_on} from 'assets/svg'
import {useAppDispatch} from 'model'

export default function Contents() {
  // hooks
  const dispatch = useAppDispatch()
  const {isSubmitError} = Store()
  const [state, setState] = useState({
    userId: '',
    password: '',
    // userId: 'crocus',
    // password: '1q2w3e4',
  })
  const [isShow, setShow] = useState(false)
  useEffect(() => {
    if (state?.userId === '' && state?.password === '') dispatch(_action.setSubmitError(false))
  }, [state])
  //*--------------------------------------------------*
  return (
    <Content>
      <div className="wrap">
        <H5 style={{marginBottom: 4}} color={COLOR.GRAY1}>
          아이디
        </H5>
        <TextInput
          style={{marginBottom: 16, color: COLOR.GRAY2}}
          placeholder="아이디를 입력해 주세요."
          defaultValue={state.userId}
          onValue={str => setState({...state, userId: str})}
          onSubmit={str => setState({...state, userId: str})}
        />
        <H5 style={{marginBottom: 4}} color={COLOR.GRAY1}>
          비밀번호
        </H5>
        <div className="password">
          <TextInputPassword
            toggle={isShow}
            style={{marginBottom: 16, color: COLOR.GRAY2}}
            defaultValue={state.password}
            onValue={str => setState({...state, password: str})}
            onSubmit={str => {
              setState({...state, password: str})
              Events.emit(_event.LOGIN_SUBMIT, {
                userId: state.userId,
                password: str,
              })
            }}
            placeholder="패스워드를 입력해 주세요"
            className={ui.input}
            type={'password'}
          />
          <button
            className="toggle"
            onClick={() => {
              setShow(val => !val)
            }}>
            <Icon src={isShow ? eye_on : eye_off} />
          </button>
        </div>
        {/* auth_submit_error */}
        {isSubmitError && <Image src={auth_submit_error} style={{marginTop: 10, marginBottom: 24}} />}
        <Button
          className={`submit ${(state?.userId === '' || state?.password === '') && 'disabled'}`}
          full
          label="시작하기"
          style={{height: 40, marginBottom: 16}}
          onClick={() => {
            if (state?.userId === '' || state?.password === '') {
              return
            }
            Events.emit(_event.LOGIN_SUBMIT, state)
          }}>
          {/* <span className="button3">시작하기</span> */}
        </Button>
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 384px;
  padding: 48px 32px;
  .password {
    position: relative;
    .toggle {
      position: absolute;
      top: 8px;
      right: 10px;
    }
  }
  .submit {
    background-color: '#193FBC' !important;
    &.disabled {
      color: ${COLOR.blue70} !important;
      box-shadow: none !important;
      background-color: ${COLOR.blue90} !important;
      transition: all 100.2s cubic-bezier(0.8, 0.07, 0.31, 1);
    }
  }
`
