/**
 * @name GNB
 * @description 1depth 메뉴
 */
import {useEffect} from 'react'
import {List, useContext} from 'lib'
import styled from 'styled-components'
import {useAppDispatch, useAppSelector} from 'model'
import {COLOR} from 'styles'
import {GNB_MENU} from 'config'
import {AvailableItem, MenuItem} from 'types'
import {gnbAction} from 'model/modules'
// contents
import Logo from './top'
import UtilMenu from './util'
import Depth1 from './depth1-menu'

export default function Gnb() {
  // hooks
  const {availableMenu} = useContext()
  const dispatch = useAppDispatch()
  const {menu} = useAppSelector(state => state.gnb)
  //*--------------------------------------------------*
  // GNB메뉴 활성화 검색
  function setAvailability(gnbMenu: MenuItem[], availableList: AvailableItem[]): MenuItem[] {
    const availabilityDict: {[key: string]: boolean} = {}
    availableList.forEach(item => {
      availabilityDict[item.menu] = item.available
      if (item.children) {
        item.children.forEach(child => {
          availabilityDict[child?.menu] = child.available
        })
      }
    })
    const updatedGnbMenu = JSON.parse(JSON.stringify(gnbMenu))
    updatedGnbMenu.forEach((item: any) => {
      if (item.menu) {
        item.available = availabilityDict[item.menu] || false
      }
      if (item.children) {
        item.children.forEach((child: any) => {
          if (child.menu) {
            child.available = availabilityDict[child.menu] || false
          }
        })
      }
    })

    return updatedGnbMenu
  }
  //*--------------------------------------------------*
  useEffect(() => {
    if (availableMenu.length > 0) {
      const updatedGnbMenu = setAvailability(GNB_MENU, availableMenu)
      dispatch(gnbAction.setMenu(updatedGnbMenu))
    }
  }, [availableMenu])
  //*--------------------------------------------------*
  return (
    <Content>
      {/* 사이트맵 */}
      <div>
        <Logo />
        {!!menu && <List info={menu} renderItem={({item, index}: {item: MenuItem; index: number}) => <Depth1 item={item} index={index} />} />}
      </div>
      {/* 유틸메뉴,시스템 설정,로그아웃 */}
      <footer>
        <UtilMenu />
      </footer>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  /* max-height: 900px; */
  background: ${COLOR.WHITE};
  box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.3);
  /*-- 하단유틸메뉴 --*/
  footer {
    margin-left: 16px;
    margin-bottom: 40px;
  }
`
