/**
 * @name GNB
 */
import {COLOR, FONT} from 'styles'
import {gnbAction} from 'model/modules'
import {MenuItem} from 'types'
import {Image, Row, useRoutes} from 'lib'
import styled from 'styled-components'
import {useAppDispatch, useAppSelector} from 'model'
// contents
import Depth2 from './depth2-menu'
import {arrow_down, arrow_next} from 'assets/svg'

type Props = {
  item: MenuItem
  index: number
}
export default function GnbDepth1Menu({item, index}: Props) {
  // hooks
  const {navi} = useAppSelector(state => state.gnb)
  const {navigate} = useRoutes()
  const dispatch = useAppDispatch()
  const isActive = navi?.depth1 === index ? true : false
  const isChildren = !!item?.children && item?.children.length > 0 ? true : false
  //*--------------------------------------------------*
  return (
    item?.available && (
      <Content icon={item?.icon} iconhover={item?.iconhover}>
        <button
          className={`item ${isActive && 'active'}`}
          onClick={() => {
            dispatch(gnbAction.setNavi({depth1: index, depth2: -1}))
            // !isChildren && navigate(item?.path)
            navigate(item?.path)
          }}>
          <Row>
            <figure />
            <label>{item?.name}</label>
          </Row>
          {isChildren && (isActive ? <Image src={arrow_down} width={24} height={24} /> : <Image src={arrow_next} width={24} height={24} />)}
        </button>
        {/*-- 2depth메뉴 --*/}
        {/* {isChildren && isActive && <Depth2 item={item} depth1={index} index={index} />} */}
        {isChildren && <Depth2 item={item} depth1={index} index={index} />}
      </Content>
    )
  )
}
//*--------------------------------------------------*
const Content = styled.section<any>`
  /* 1depth,2depth메뉴 */
  margin-left: 18px;
  margin-right: 18px;
  /*-- nav (1depth메뉴) --*/
  button.item {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
    padding-right: 15px;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    color: ${COLOR.BLUE_GRAY2};
    background-color: ${COLOR.white};
    label {
      display: inline-block;
      margin-left: 8px;
      color: ${COLOR.gray40};
      font-family: ${FONT.MAIN};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
    figure {
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-image: ${({icon}) => `url(${icon})`};
    }
    /*-- hover --*/
    &:hover {
      background-color: ${COLOR.blue90};
      figure {
        background-image: ${({iconhover}) => `url(${iconhover})`};
      }
      label {
        color: ${COLOR.blue30};
      }
    }
    /* 활성화 */
    &.active {
      background-color: ${COLOR.blue90};
      figure {
        background-image: ${({iconhover}) => `url(${iconhover})`};
      }
      label {
        color: ${COLOR.blue30};
      }
    }
  }
`
