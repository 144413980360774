/**
 * @name Images
 */

/**---------------------------------- @name Common */
export const acelo_logo = require('./common/acelo_logo.png')
export const logo = require('./common/acelo.png')
export const auth_logo = require('./common/auth_logo.png')
export const crocus = require('./common/crocus.png')
export const acelo = require('./common/acelo.png')
//gnb
export const gnb_util_setup = require('./common/gnb_util_setup.png')
export const gnb_util_logout = require('./common/gnb_util_logout.png')
export const top_company = require('./common/top_company.png')
export const top_notification = require('./common/top_notification.png')
//로고
export const DSME = require('./common/DSME.png')
export const KCPR = require('./common/KCPR.png')
export const KHCT = require('./common/KHCT.png')
export const KOEM = require('./common/KOEM.png')
export const KRPO = require('./common/KRPO.png')
export const RMKR = require('./common/RMKR.png')
export const SEAM = require('./common/SEAM.png')
export const SSNQ = require('./common/SSNQ.png')

/**---------------------------------- @name Icon */
export const map_marker1 = require('./icon/map_marker1.png')
export const map_marker2 = require('./icon/map_marker.png')
export const small_location = require('./icon/small_location.png')
export const small_phone = require('./icon/small_phone.png')
export const green_checked = require('./icon/green_checked.png')
export const green_checked_on = require('./icon/green_checked_on.png')
export const exclamation = require('./icon/exclamation.png')
export const refresh_btn = require('./icon/refresh_btn.png')
export const status_on = require('./icon/status_on.png')
export const status_error = require('./icon/status_error.png')

/**---------------------------------- @name Pages */
export const notification_setup_visual = require('./pages/notification_setup_visual.png')
export const notification_pagination = require('./pages/notification_pagination.png')
export const use_img = require('./pages/use_img.png')
export const auth_visual = require('./pages/auth_visual.png')
export const map_logo = require('./pages/map_logo.png')
export const map_marker = require('./pages/map_marker.png')
export const map_marker_hover = require('./pages/map_marker_hover.png')
export const map_marker_active = require('./pages/map_marker_active.png')
export const sp_link = require('./pages/sp_link.png')
export const sp_logout = require('./pages/sp_logout.png')
export const dashboard_empty = require('./pages/dashboard_empty.png')
