/**
 * @name 헤더
 * @description
 */
import {useState} from 'react'
import {API} from 'config'
import {Button, Input, useAxios, useContext} from 'lib'
import styled from 'styled-components'
// contents

export default function Contents() {
  // hooks
  const {userId, user} = useContext()
  const [name, setName] = useState(user?.name)
  const [email, setEmail] = useState(user?.email)
  const [phone, setPhone] = useState(user?.phone || '')
  const {put} = useAxios()

  //*--------------------------------------------------*
  async function onSubmit() {
    const res = await put(`${API}/users/me`, {
      userId: userId,
      email: email,
      phone: phone,
      name: name,
    })
    if (res?.success) {
      console.log(res)
      window.location.href = '/'
      alert('저장되었습니다.')
    }
  }
  //*--------------------------------------------------*
  return (
    <Content>
      <p className="title">사용자 이름</p>
      <Input
        className={'input'}
        value={name}
        // defaultValue={name}
        onUpdate={({value}) => {
          setName(value)
        }}
        // defaultValue={user?.name}
      />
      <p className="title">이메일</p>
      <Input
        className={'input'}
        value={email}
        // defaultValue={email}
        onUpdate={({value}) => {
          setEmail(value)
        }}
      />
      <p className="title">사용자 휴대 번호</p>
      <Input
        className={'input'}
        value={phone}
        onChange={event => {
          const result = event.target.value.replace(/\D/g, '')
          console.log(result)
          setPhone(result)
          // console.log(result)
        }}
      />
      {/* {debug(user)} */}
      <Button
        style={{marginTop: 50}}
        label="저장하기"
        full
        onClick={async () => {
          await onSubmit()
        }}
      />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: block;
  width: 100%;
  background: 'blue';
  /* title */
  .title {
    height: 22px;
  }
  input {
    margin-bottom: 16px;
  }
`
