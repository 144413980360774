/**
 * @name 로그인
 * @description
 */
import React from 'react'
import styled from 'styled-components'
import {Flex} from 'lib'
import Lottie from 'lottie-react'
import {auth_visual} from 'assets/lottie'

// contents
import {Footer, Header} from './common'
import Contents from './contents'

export default function Presenter() {
  return (
    <Content>
      <Flex alignitems="center" justifycontent="center">
        {/* 비쥬얼영역 */}
        <Flex className="visual" alignitems="center" justifycontent="center">
          <Lottie animationData={auth_visual} loop={true} />
        </Flex>
        {/* 본문 */}
        <Flex className="main" flexdirection="column" alignitems="center" justifycontent="center">
          <Header />
          <Contents />
          <Footer />
        </Flex>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 32px;
  /*---비쥬얼-------------*/
  .visual {
    width: 50%;
    height: calc(100vh - 64px);
    background-color: #f8f7fa;
  }
  /*---메인-------------*/
  .main {
    width: 100%;
    flex: 1;
    height: calc(100vh - 64px);
  }
  /* <!--media query --> */
  @media all and (min-width: 0) and (max-width: 1000px) {
    .visual {
      display: none;
    }
  }
`
