/**
 * @name Container
 */
import {useEffect, useState} from 'react'
import {Dayjs, Events, useAxios, useUtil} from 'lib'
import {useAppDispatch} from 'model'
import {API} from 'config'
import {_action, _event} from '.'
import {gnbAction} from 'model/modules'

// contents
import Presenter from './presenter'

export default function Container() {
  // hooks
  const {log} = useUtil()
  const dispatch = useAppDispatch()
  const {get} = useAxios()
  const [isInfo, setIsInfo] = useState(false)
  const [isChart, setIsChart] = useState(false)
  //*--------------------------------------------------*
  // 초기화
  async function onInit() {
    onReload()
    dispatch(gnbAction.setNavi({depth1: 1, depth2: -1}))
  }
  // 날짜변경
  async function onReload() {
    const dayjs = Dayjs().format('YY.MM.DD HH:MM') + '분 갱신'
    dispatch(_action.setReloadTime(dayjs))
    onInfo()
    onChart()
  }

  // 에너지사용량 정보조회
  async function onInfo() {
    const res = await get(`${API}/data/energy-usage/info`)
    if (res?.success) {
      log(res)

      dispatch(_action.setInfo(res?.data))
      setIsInfo(true)
    } else {
    }
  }
  // 에너지사용량 차트조회
  async function onChart(type?: 'day' | 'month' | 'year') {
    const res = await get(`${API}/data/energy-usage/chart?period=${type || 'day'}`)
    if (res?.success) {
      dispatch(_action.setChart(res.data))
      setIsChart(true)
    } else {
    }
  }

  //*--------------------------------------------------*
  useEffect(() => {
    onInit()
    // addListener
    Events.addListener(_event.SELECT_DATE_TYPE, onReload)
    return () => {
      // removeListener
      Events.removeListener(_event.SELECT_DATE_TYPE, onReload)
    }
  }, [])

  return <Presenter isInfo={isInfo} isChart={isChart} />
}
