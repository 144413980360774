/**
 * @name Container
 */
import {useEffect} from 'react'
import Presenter from './presenter'
import {_action, _event} from '.'
import {API} from 'config'

export default function Container() {
  // hooks

  //*--------------------------------------------------*
  async function onInit() {}

  //*--------------------------------------------------*
  useEffect(() => {
    onInit()
    // addListener
    return () => {
      // removeListener
    }
  }, [])

  return <Presenter />
}
